import React, { useEffect, useState } from "react";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import AdminLayout from "../../components/AdminLayout/admin_layout";
import { axios_get, GET_RECIPE_LIKED } from "../../utils/service";
import InfiniteScroll from "react-infinite-scroller";
import Rating from "react-rating";
import { get } from "lodash";
export default function Favorites() {
  const [posts, setPosts] = useState([]);
  const [postCount, setPostCount] = useState([]);
  const [next, setNext] = useState("");
  useEffect(() => {
    getLikedRecipes();
  }, []);
  const getLikedRecipes = () => {
    setNext("");
    axios_get(next ? next : GET_RECIPE_LIKED, true).then((res) => {
      console.log("likded recipe", res);
      setPosts((prev) => [...prev, ...get(res, "data.data", [])]);
      setPostCount(get(res, "data.count", 0));
      setNext(get(res, "data.next"));
    });
  };
  return (
    <div>
      <Header />
      <InfiniteScroll
        pageStart={0}
        loadMore={getLikedRecipes}
        hasMore={next ? true : false}
        loader={
          <div className="loader" key={0}>
            Loading ...
          </div>
        }
      >
        <AdminLayout>
          <div className="favorites">
            <div className="result_content">
              <h1>Favorites ({postCount} recipes)</h1>
              <div className="recipe_cards">
                {posts.map((post) => (
                  <a
                    key={post.id}
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   navigate("/recipe/" + post.id);
                    // }}
                    href={"/recipe/" + post.id}
                    className="recipe_card"
                  >
                    <div
                      className="recipe_image"
                      style={{
                        backgroundImage: `url('${post.cover_image?.file}')`,
                      }}
                    >
                      <span
                        className={
                          post.is_liked
                            ? "material-icons active"
                            : "material-icons"
                        }
                      >
                        {post.is_liked ? "favorite" : "favorite_border"}
                      </span>
                    </div>
                    <div className="recipe_description">
                      <div className="recipe_cat">
                        <span>{post.category.category}</span>
                        {/* <div className="star_rating">
                        <span className="material-icons">star</span>{" "}
                        {post.rating}
                      </div> */}
                        <div className="star_rating">
                          <Rating
                            className="rating_system"
                            emptySymbol={
                              <span className="material-icons">
                                star_border
                              </span>
                            }
                            fullSymbol={
                              <span className="material-icons">star</span>
                            }
                            initialRating={post.rating || 0}
                            readonly
                          />
                        </div>
                      </div>
                      <b>{post.title}</b>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </AdminLayout>
      </InfiniteScroll>
      <Footer />
    </div>
  );
}
