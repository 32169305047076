import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import HomeContent from "../../components/HomeContent/home_content";
import React from "react";

export default function Home(){
    return (
    <React.Fragment>
        <Header />
        <HomeContent />
        <Footer />
    </React.Fragment>
    )


}