import React, { useEffect, useState } from "react";
import { Checkbox, Collapse } from "antd";
import { get } from "lodash";
import "./checkout.scss";
import {
  axios_get,
  ADDRESS,
  ORDER,
  axios_post,
  calculate_delivery_date,
  POST_CODE,
} from "../../utils/service";
import Cookies from "js-cookie";
const { Panel } = Collapse;

import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { STRIPE_PUBLIC_KEY } from "../../utils/service";
import CheckoutForm from "../../components/PaymentSripe/CheckoutForm";
import Form from "../EditAddress/form";
import CartRow from "../../components/Cart/cartRow";
import { useSelector } from "react-redux";
import { navigate } from "@reach/router";
import moment from "moment";
import CartList from "../../components/Cart/cartList";
const isLoggedIn = Cookies.get("user_id") ? true : false;
const appearance = {
  theme: "stripe",
};
const ELEMENTS_OPTIONS = {
  appearance,
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
    },
  ],
};

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

export default function Checkout() {
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [addAddress, setAddAddress] = useState(false);
  const [paymentOption, setPaymentOption] = useState("Online");
  const [defaultPanel, setDefaultPanel] = useState(isLoggedIn ? "2" : "1");
  const [CashOnDelivery, SetCashOnDelivery] = useState(false);
  const [delivery_date, SetDelivery_date] = useState(calculate_delivery_date());
  const [use_reward, setUse_reward] = useState(false);
  const [reward_point, SetReward_point] = useState(false);
  const [order_success_fail_message, Setorder_success_fail_message] =
    useState("");
  // const [earnPoint, ]
  let current_user = useSelector((state) =>
    get(state, "user.current_user", {})
  );
  let cart = useSelector((state) => get(state, "cart.cart", {}));
  let cateringCart = useSelector((state) =>
    get(state, "cart.cateringCart", {})
  );

  let cart_delivery_fee = get(cart, "cart.delivery_fee", 0);
  cart_delivery_fee = cart_delivery_fee ? cart_delivery_fee : "Free";

  const [delivery_fee, SetDelivery_fee] = useState(cart_delivery_fee);

  const delivery_dateUpdate = (address_obj) => {
    SetDelivery_date(
      calculate_delivery_date(get(address_obj, "post_code.delivery_in_days", 2))
    );
    const cash_on_delivery = get(
      address_obj,
      "post_code.cash_on_delivery",
      false
    );
    if (!cash_on_delivery) {
      setPaymentOption("Online");
    }
    SetCashOnDelivery(cash_on_delivery);
  };

  useEffect(() => {
    getAddresses();
  }, []);

  const getDeliveryFee = (postcode) => {
    axios_get(POST_CODE + postcode + "/").then((res) => {
      let delivery_fee = get(res, "data.data.delivery_fee", 0);
      delivery_fee = delivery_fee ? delivery_fee : "Free";
      SetDelivery_fee(delivery_fee);
    });
  };

  // let delivery_fee = get(cart, 'cart.delivery_fee', 0);
  // delivery_fee = delivery_fee ? delivery_fee : 'Free'

  const getAddresses = () => {
    axios_get(ADDRESS, true).then((res) => {
      get(res, "data.data", []).map((item) => {
        if (item.is_default) {
          setSelectedAddress(item.id);
          delivery_dateUpdate(item);
          getDeliveryFee(item?.post_code?.post_code);
        }
      });
      setAddresses(get(res, "data.data", []) || []);
    });
  };
  const onAddressSubmit = (res) => {
    if (get(res, "data.data")) {
      let arr = [...addresses];
      arr.push(res.data.data);
      setAddresses(arr);
      setSelectedAddress(res.data.data.id);
      delivery_dateUpdate(res.data.data);
      getDeliveryFee(res.data.data?.post_code?.post_code);
      setAddAddress(false);
    }
  };
  const hitOrderSubmitApi = (token) => {
    let data = {
      payment_type: paymentOption,
      cart: cart.id,
      catering_cart: cateringCart.id,
      catering_amount: cateringCart.total_price,
      address: selectedAddress,
      amount: parseFloat(cart.total_price),
      use_reward,
      reward_point,
    };
    if (token) {
      data.token = token;
    }
    axios_post(ORDER, data, true).then((res) => {
      if (res?.data && res?.data?.data) {
        navigate("/order-detail/" + res?.data?.data?.id);
        Setorder_success_fail_message(
          "Your order is placed. Redirecting to orders detail ..."
        );
      } else {
        Setorder_success_fail_message(
          "Your order placed failed. Reset the payment and try again"
        );
      }
    });
  };
  const onPaymentResponse = (res) => {
    if (res.token) {
      hitOrderSubmitApi(res.token.id);
    }
  };
  return (
    <div className="checkout">
      <nav className="navbar">
        <div className="container">
          <div className="nav_inner_container">
            <a className="logo" href="/">
              <img src="/logo.png" alt="logo" />
            </a>
            <h3>Checkout</h3>
            <span className="material-icons security_logo">verified_user</span>
          </div>
        </div>
      </nav>
      <div className="container">
        <div className="checkout_body">
          <div className="checkout_layout">
            <div className="checkout_left">
              <Collapse
                activeKey={defaultPanel}
                defaultActiveKey={defaultPanel}
                accordion
              >
                {!isLoggedIn && (
                  <Panel
                    className={parseInt(defaultPanel) > 1 ? "done" : null}
                    showArrow={false}
                    header="Login"
                    key="1"
                  >
                    <div className="cart_login">
                      <a className="active" href="/login?guest=true">
                        Login
                      </a>
                      <span>OR</span>
                      <button
                        onClick={() => {
                          setDefaultPanel("2");
                        }}
                      >
                        Continue as guest
                      </button>
                    </div>
                  </Panel>
                )}

                <>
                  <Panel
                    className={parseInt(defaultPanel) > 2 ? "done" : null}
                    showArrow={false}
                    header="Delivery Address"
                    key="2"
                  >
                    <div className="address_selection">
                      {addresses && addresses.length > 0 ? (
                        addresses.map((item) => (
                          <span
                            onClick={() => (
                              setSelectedAddress(item.id),
                              delivery_dateUpdate(item),
                              getDeliveryFee(item?.post_code?.post_code)
                            )}
                            key={item.id}
                          >
                            <input
                              id={item.id}
                              type="radio"
                              name="address"
                              checked={item.id == selectedAddress}
                              hidden
                            />
                            <label htmlFor="add1" className="each_address">
                              <div className="address_head">
                                <span> {item.name}</span>
                                <span>{item.mobile_number}</span>
                              </div>
                              {`${item.address_line1}, ${
                                item.address_line2
                                  ? item.address_line2 + ","
                                  : ""
                              } ${item.city}, ${item.post_code.post_code}, ${
                                item.state
                              }, ${item.country}`}
                              <p>{`${item.delivery_instruction}`}</p>
                              {/* <div className="address_actions">
                            <button>EDIT</button>
                            <button>DELETE</button>
                          </div> */}
                            </label>
                          </span>
                        ))
                      ) : (
                        <p>no address available</p>
                      )}

                    </div>
                    <div className="cart_login">
                      {!isLoggedIn && (
                        <button onClick={() => setDefaultPanel("1")}>
                          Back
                        </button>
                      )}
                      <button onClick={() => setAddAddress((prev) => !prev)}>
                        {addAddress ? "Cancel" : "Add Address"}
                      </button>
                      <button
                        onClick={() => {
                          if (!selectedAddress) {
                            alert("Please select an address for delivery");
                            return;
                          }
                          setDefaultPanel("3");
                        }}
                        className="active"
                      >
                        Continue
                      </button>
                    </div>
                    {addAddress && <Form checkout onSubmit={onAddressSubmit} />}

                    {/* Show below form when clicked on add address */}
                    {/* <form className="add_delivery_address">
                    <div className="form_control">
                      <label>Name</label>
                      <input required />
                    </div>
                    <div className="form_control">
                      <label>Mobile Number</label>
                      <input required />
                    </div>
                    <div className="form_control">
                      <label>Post Code</label>
                      <input required />
                    </div>
                    <div className="form_control">
                      <label>City</label>
                      <input required />
                    </div>
                    <div className="form_control">
                      <label>State</label>
                      <input required />
                    </div>
                    <div className="form_control">
                      <label>Address</label>
                      <textarea required></textarea>
                    </div>
                    <div className="form_control">
                      <label>Address</label>
                      <textarea required></textarea>
                    </div>
                    <div className="form_control">
                      <label>Address Type</label>
                      <select>
                        <option>Home</option>
                        <option>Office</option>
                      </select>
                    </div>
                    <div className="form_control">
                      <label></label>
                      <div className="inline_block">
                        <button className="active">Save &amp; Continue</button>
                        <button className="link">
                          <span className="material-icons">arrow_back_ios</span>{" "}
                          Back to addresses
                        </button>
                      </div>
                    </div>
                  </form> */}
                  </Panel>
                  <Panel
                    className={parseInt(defaultPanel) > 3 ? "done" : null}
                    showArrow={false}
                    header="Review order"
                    key="3"
                  >
                    {get(cart, "cart_products", []).length < 1 &&
                    get(cateringCart, "catering_cart_products", []).length <
                      1 ? (
                      <>
                        <p>You don't have any products in your cart.</p>
                        <div className="cart_login">
                          <button
                            onClick={() => window.location.replace("/shop")}
                            className="active"
                          >
                            Go to Shop
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <CartList />
                        <div className="cart_login">
                          <button onClick={() => setDefaultPanel("2")}>
                            Back
                          </button>
                          <button
                            onClick={() => setDefaultPanel("4")}
                            className="active"
                          >
                            Continue
                          </button>
                        </div>
                      </>
                    )}
                  </Panel>
                  <Panel
                    showArrow={false}
                    header="Make payment"
                    key="4"
                    extra={
                      <button
                        className="transparent_btn"
                        onClick={() => setDefaultPanel("3")}
                      >
                        <span className="material-icons">chevron_left</span>Back
                      </button>
                    }
                  >
                    <div className="PaymentWrapper">
                    {isLoggedIn && (
                        <Checkbox
                          value={use_reward}
                          checked={use_reward}
                          onChange={() => setUse_reward((prev) => !prev)}
                        >
                          Use Wallet Credit ({current_user.total_point})
                        </Checkbox>
                      )}
                      <br/>
                      <p>You will earn {get(cart, 'earned_point.earning_point', 0)} points on this order.</p>
                      <br/>
                      <br/>
                      {CashOnDelivery ? (
                        <div className="cart_login toggle_payment_method">
                          <>
                            <button
                              onClick={() => setPaymentOption("Online")}
                              className={paymentOption == "Online" && "active"}
                            >
                              Pay online
                            </button>
                            <span>OR</span>
                            <button
                              onClick={() => setPaymentOption("Cash")}
                              className={paymentOption == "Cash" && "active"}
                            >
                              Cash on delivery
                            </button>
                          </>
                        </div>
                      ) : null}

                      {paymentOption == "Online" ? (
                        <div>
                          <Elements
                            stripe={stripePromise}
                            options={ELEMENTS_OPTIONS}
                          >
                            <CheckoutForm
                              onResponse={onPaymentResponse}
                              amount={Number(
                                Number(
                                  cateringCart?.total_price
                                    ? cateringCart?.total_price
                                    : 0
                                ) +
                                  Number(cart.total_price) +
                                  Number(
                                    delivery_fee === "Free" ? 0 : delivery_fee
                                  )
                              ).toFixed(2)}
                              message={order_success_fail_message}
                            />
                          </Elements>
                        </div>
                      ) : (
                        <form
                          className="PaymentForm"
                          onSubmit={() => hitOrderSubmitApi()}
                        >
                          <button className="SubmitButton" type="submit">
                            Confirm &amp; Place Order
                          </button>
                        </form>
                      )}
                    </div>
                  </Panel>
                </>
              </Collapse>
            </div>
            <div className="checkout_right">
              <h4>Cart Summary</h4>
              <table className="pricing_sub_table">
                <tbody>
                  <tr>
                    <td className="bold">Sub total</td>
                    <td>
                      $ {Number(Number(cateringCart?.total_price ? cateringCart?.total_price : 0) + Number(cart.total_price)).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td className="bold">Delivery charge</td>
                    <td>
                    {delivery_fee === "Free" || !delivery_fee ? 'Free' : `$ ${ Number(delivery_fee).toFixed(2) }`}
                    </td>
                  </tr>
                  {use_reward &&
                  (<tr className="wallet_row">
                    <td className="bold">Wallet Credit</td>
                    <td>
                      {get(cart, 'earned_point.spent_point_price', 0)}
                    </td>
                  </tr>)
                  }
                </tbody>
                <tfoot>
                  <tr>
                    <td className="bold">Total</td>
                    { use_reward ?
                    <td>
                      $ {Number(Number(cateringCart?.total_price ? cateringCart?.total_price : 0) + Number(cart.total_price) + Number(delivery_fee === "Free" ? 0 : delivery_fee) - get(cart, 'earned_point.spent_point_price', 0)).toFixed(2)}
                    </td>:
                        <td>
                          $ {Number(Number(cateringCart?.total_price ? cateringCart?.total_price : 0) + Number(cart.total_price) + Number(delivery_fee === "Free" ? 0 : delivery_fee)).toFixed(2)}
                      </td>
                    }
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
