import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import { axios_post, CONTACT_US } from "../../utils/service";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./contact.scss";
import { useLocation } from "@reach/router";
import { Spin } from "antd";

export default function ContactUs() {
  const contactDetails = useSelector((state) => state.appInfo.contact);
  const [email, SetEmail] = useState("");
  const [fullname, setFullName] = useState("");
  const [query_type, setQuery] = useState("CATERING");
  const [message, setMessage] = useState("");
  const [phone_number, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const OnSubmitSendLink = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      email,
      fullname,
      query_type,
      message,
      phone_number,
    };
    console.log("data", data);
    axios_post(CONTACT_US, data, false)
      .then((res) => {
        setLoading(false);
        if (res.data.status.code === 200 || res.data.status.code === 201) {
          SetEmail("");
          setFullName("");
          setQuery("");
          setMessage("");
          setPhone("");
        }
      })
      .catch((err) => setLoading(false));
  };
  const location = useLocation();
  const hideHeaderFooter = location.pathname.includes("mobile");

  return (
    <div>
      {!hideHeaderFooter && <Header />}

      <div className="minimum_height_cont">
        <div
          className="page_bg"
          style={{ backgroundImage: "url('/subscribe-bg.jpg')" }}
        >
          <h1>Get in touch with us</h1>
        </div>
        <div className="page_card large">
          <div className="half_card">
            <h2>
              We’re open for <span>catering services</span> help, feedback and
              suggestions
            </h2>
            <p className="contact_point">
              <img alt="mail icon" src="mail_black.svg" />{" "}
              {contactDetails.email}
            </p>
            <br />
            <p className="contact_point">
              <img alt="phone icon" src="phone_black.svg" />{" "}
              {"+ " + contactDetails.phone_number}
            </p>
          </div>
          <form onSubmit={OnSubmitSendLink}>
            <div className="form_group">
              <label htmlFor="">Full Name</label>
              <input
                value={fullname}
                required
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>
            <div className="form_group">
              <label htmlFor="">Email</label>
              <input
                value={email}
                type="email"
                required
                onChange={(e) => SetEmail(e.target.value)}
              />
            </div>
            <div className="form_group">
              <label htmlFor="">Phone Number</label>
              <input
                required
                value={phone_number}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="form_group">
              <select onChange={(e) => setQuery(e.target.value)}>
                <option>Catering</option>
                <option>Help/Feedback</option>
                <option>Shop</option>
              </select>
            </div>
            <div className="form_group" style={{ marginBottom: 0 }}>
              <label htmlFor="">Message</label>
              <textarea
                rows="3"
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>

            {loading ? (
              <div style={{ textAlign: "center", marginTop: "30px" }}>
                <Spin />
              </div>
            ) : (
              <button type="submit">Submit</button>
            )}
          </form>
        </div>
      </div>
      {!hideHeaderFooter && <Footer />}
    </div>
  );
}
