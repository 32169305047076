import { Router, Location } from "@reach/router";
import React, { useEffect } from "react";
import PageNotFound from "./page/PageNotFound/page_not_found";
import LoginPage from "./page/LoginPage/login_page";
import { CSSTransition } from "react-transition-group";
import DashboardPage from "./page/DashboardPage/dashboard_page";
import SignUpPage from "./page/SignUpPage/signup_page";
import Home from "./page/Home/Home";
import ContactUs from "./page/ContactUs/contact_us";
import AddEditRecipe from "./page/AddEditRecipe/add_edit_recipe";
import ViewRecipe from "./page/ViewRecipe/view_recipe";
import ForgotPassword from "./page/ForgotPassword/forgot_password";
import ChangePassword from "./page/ChangePassword/change_password";
import UserSettings from "./page/UserSettings/user_settings";
import ForgotPasswordVerify from "./page/ForgotPassword/forgot_password_verify";
import PrivacyPolicy from "./page/PrivacyAndPolicy/privacy_and_policy";
import TermsAndCondition from "./page/TermsAndCondition/terms_and_condition";
import OrderHistory from "./page/OrderHistory/order_history";
import OrderDetail from "./page/OrderDetail/order_detail";
import Favorite from "./page/Favorites/favorites";
import Wallet from "./page/Wallet/wallet";
import Addresses from "./page/Addresses/addresses";
import EditAddress from "./page/EditAddress/edit_address";
import MyRecipes from "./page/MyRecipes/my_recipes";
import PublicProfile from "./page/PublicProfile/public_profile";
import Checkout from "./page/Checkout/checkout";
import Product from "./page/Product/product";
import Social from "./page/Social/social";
import Banner from "./page/Banner/banner";
import { get } from "lodash";
import {
  axios_get,
  CONTACT_DETAILS,
  RECIPE_CATEGORY_LIST,
  RECIPE_TYPE_LIST,
  RECIPE_FOOD_PREFERENCE_LIST,
  RECIPE_ORIGIN_LIST,
  PRODUCT_CATEGORY,
} from "./utils/service";
import {
  updateContactInfo,
  updateRecipeCategories,
  updateRecipeTypes,
  updateRecipeFoodPreferences,
  updateOriginCountryList,
  updateProductCategoryList,
} from "./store/appInfo/appInfo";
import { useDispatch } from "react-redux";
import SearchResult from "./page/SearchResult/search_result";
import AboutUs from "./page/AboutUs/about_us";
import Shop from "./page/Shop/shop";
import ShopSearchResult from "./page/ShopSearchResult/search_result";
import Catering from "./page/Catering/catering";
import EmailConfirm from "./page/EmailConfirm/EmailConfirm";
import EmailChangeConfirm from "./page/EmailChangeConfirm/EmailChangeConfirm";

const FadeTransitionRouter = (props) => (
  <Location>
    {({ location }) => (
      <div>
        <CSSTransition key={location.key} classNames="fade" timeout={500}>
          <Router location={location}>{props.children}</Router>
        </CSSTransition>
      </div>
    )}
  </Location>
);

const AppRouter = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    getRecipeCategory();
    getOriginCountryList();
    getRecipeTypes();
    getFoodPreferences();
    getProductCategory();
    axios_get(CONTACT_DETAILS).then((res) => {
      let data = {};
      if (res.data && res.data.data) {
        data = res.data.data;
      }
      dispatch(updateContactInfo(data));
    });
  }, []);
  const getRecipeCategory = () => {
    axios_get(RECIPE_CATEGORY_LIST).then((res) => {
      let data = [];
      if (res.data && res.data.data) {
        data = res.data.data;
      }
      dispatch(updateRecipeCategories(data));
    });
  };
  const getProductCategory = () => {
    axios_get(PRODUCT_CATEGORY).then((res) => {
      dispatch(updateProductCategoryList(get(res, "data.data", [])));
    });
  };
  const getRecipeTypes = () => {
    axios_get(RECIPE_TYPE_LIST).then((res) => {
      let data = [];
      if (res.data && res.data.data) {
        data = res.data.data;
      }
      dispatch(updateRecipeTypes(data));
    });
  };
  const getFoodPreferences = () => {
    axios_get(RECIPE_FOOD_PREFERENCE_LIST).then((res) => {
      let data = [];
      if (res.data && res.data.data) {
        data = res.data.data;
      }
      dispatch(updateRecipeFoodPreferences(data));
    });
  };

  const getOriginCountryList = () => {
    axios_get(RECIPE_ORIGIN_LIST).then((res) => {
      let data = [];
      if (res.data && res.data.data) {
        data = res.data.data;
      }
      dispatch(updateOriginCountryList(data));
    });
  };
  return (
    <FadeTransitionRouter>
      <PageNotFound default />
      <Home path="/" />
      <LoginPage path="/login" />
      <SignUpPage path="/signup" />
      <ContactUs path="/contact-us" />
      <ContactUs path="/contact-us-mobile" />

      <ChangePassword path="/change-password" />
      <ForgotPassword path="/forgot-password" />
      <ViewRecipe path="/recipe/:slug" />
      <ForgotPasswordVerify path="/forgot-password-verify/:token" />
      <PrivacyPolicy path="/privacy-policy" />
      <PrivacyPolicy path="/privacy-policy-mobile" />
      <SearchResult path="/search" />
      <TermsAndCondition path="/terms-and-condition" />
      <TermsAndCondition path="/terms-and-condition-mobile" />
      <DashboardPage path="/dashboard" />
      <AddEditRecipe path="/add-edit-recipe" />
      <AddEditRecipe path="/add-edit-recipe/:recipeId" />
      <UserSettings path="/user-settings" />
      <Favorite path="/favorites" />
      <MyRecipes path="/my-recipes" />
      <AboutUs path="/about-us" />
      <AboutUs path="/about-us-mobile" />
      <PublicProfile path="/user/:username" />
      <Shop path="/shop" />
      <ShopSearchResult path="/shop/search" />
      <Banner path="/shop/banner/:id" />
      <Product path="/product/:id" />
      <Wallet path="/wallet" />
      <Addresses path="/addresses" />
      <EditAddress path="/edit-address/:id" />
      <EditAddress path="/add-address" />
      <Checkout path="/checkout" />
      <Catering path="/catering" />
      <Social path="/social" />
      <EmailConfirm path="/confirm-email/:token/:email" />
      <OrderHistory path="/order-history" />
      <OrderDetail path="/order-detail/:id" />
      <EmailChangeConfirm path="/confirm-email-change/:token/:email/:new_email" />
    </FadeTransitionRouter>
  );
};

export default AppRouter;
