import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/header';
import Footer from '../../components/Footer/footer';
import AdminLayout from '../../components/AdminLayout/admin_layout';
import { Pagination } from 'antd';
import { axios_get, ORDER } from '../../utils/service';
import { get } from 'lodash';
import moment from 'moment';
import './orderHistory.scss';

export default function OrderHistory() {
  const [orders, setOrders] = useState([]);
  const [order, setOrder] = useState({});
  useEffect(() => {
    getOrders();
  }, []);
  const getOrders = (page) => {
    let url = ORDER;
    if (page) {
      url = ORDER + '?page=' + page;
    }
    axios_get(url).then((res) => {
      setOrders(get(res, 'data.data', []));
      setOrder(get(res, 'data', {}));
    });
  };
  return (
    <div>
      <Header />
      <AdminLayout>
        <div className="order_history-cont">
          <h1>Order History</h1>
          {orders.map((item) => (
            <div key={item.id} className="order_history">
              <div className="order_header">
                <div>
                  <b>
                    Order ID: {item.orderId} (
                    {item.product_count + item.catering_product_count} Items)
                  </b>
                  <span>
                    Placed on:{' '}
                    {moment(item.created_on).local().format('DD MMM YYYY')}
                  </span>
                </div>
                <a href={'/order-detail/' + item.id}>Details</a>
              </div>
              <div className="order_body">
                <div className="product_displays">
                  {item.images.map((img) => (
                    <div
                      className="product_item_list"
                      style={{
                        backgroundImage: `url('${img.image}')`,
                      }}
                    ></div>
                  ))}

                  {item.images.length > 4 && (
                    <div className="product_item_list">+7 more</div>
                  )}
                </div>
                <div className="order_options">
                  <div>
                    <b>
                      $
                      {(
                        parseFloat(item.price) +
                        parseFloat(item.delivery_fee) +
                        parseFloat(item.catering_price)
                      ).toFixed(2)}
                    </b>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Pagination
          showSizeChanger={false}
          defaultCurrent={1}
          total={get(order, 'count', 0)}
          onChange={(val) => getOrders(val)}
        />
      </AdminLayout>
      <Footer />
    </div>
  );
}
