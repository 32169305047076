import React, { useRef } from "react";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import {
  RECIPE_CATEGORY_LIST,
  RECIPE_FOOD_PREFERENCE_LIST,
  RECIPE_INGREDIENT_BRAND_LIST,
  RECIPE_INGREDIENT_NAME_LIST,
  RECIPE_INGREDIENT_UNIT_LIST,
  RECIPE_ORIGIN_LIST,
  ADD_RECIPE,
  RECIPE_TYPE_LIST,
  axios_get,
  axios_post,
  POST_RECIPE_FILE,
  DELETE_RECIPE_FILE,
  axios_delete,
  axios_put,
  GET_RECIPE,
  COOKING_LEVEL,
} from "../../utils/service";
import Cropper from "../../components/ImageCropper/image_cropper";
import AdminLayout from "../../components/AdminLayout/admin_layout";
import { get } from "lodash";
import { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
// import "./quill.scss"; // ES6
import "./recipe.scss";
// import  { Quill } from "react-quill"; // ES6
// import ImageUploader from "quill-image-uploader";
import { Editor } from "@tinymce/tinymce-react";

import Select from "react-select";
import { navigate } from "@reach/router";
// Quill.register("modules/imageUploader", ImageUploader);

export default function AddEditRecipe(props) {
  const editorRef = useRef(null);

  const [categoryList, setCategoryList] = useState([]);
  const [foodPreferenceList, setFoodPreferenceList] = useState([]);
  const [ingredientBrandList, setIngredientBrandList] = useState([]);
  const [ingredientName, setIngredientName] = useState("");
  const [ingredientBrand, setIngredientBrand] = useState("");
  const [ingredientQuantity, setIngredientQuantity] = useState("");
  const [ingredientUnit, setIngredientUnit] = useState("");
  const [ingredientNameList, setIngredientNameList] = useState([]);
  const [ingredientUnitList, setIngredientUnitList] = useState([]);
  const [recipeOriginList, setRecipeOriginList] = useState([]);
  const [recipeTypeList, setRecipeTypeList] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [preparation, setPreparation] = useState("");
  const [instructions, setInstructions] = useState("");
  const [type, setType] = useState("");
  const [category, setCategory] = useState("");
  const [time, setTime] = useState("");
  const [origin, setOrigin] = useState("");
  const [mainImages, setMainImages] = useState([]);
  const [descriptionImages, setDescriptionImages] = useState([]);
  const [preparationImages, setPreparationImages] = useState([]);
  const [instructionImages, setInstructionImages] = useState([]);
  const [cookingLevels, setCookingLevels] = useState([]);
  const [cookingLevel, setCookingLevel] = useState("");
  const [totalServings, setTotalServings] = useState("");
  const [costPerServing, setCostPerServing] = useState("");
  const [coverImage, setCoverImage] = useState({});
  const [imgObj, setImgObj] = useState("");

  const getRecipeDetails = () => {
    if (props.recipeId) {
      axios_get(GET_RECIPE + props.recipeId + "/", true).then((res) => {
        if (res?.data?.data) {
          let d = res.data.data;
          setTitle(d.title);
          setCategory(d?.category?.id);
          setDescription(d.description);
          setInstructions(d.instruction);
          setPreparation(d.preparation);
          if (d?.ingredients?.length) {
            setIngredients(
              d.ingredients.map((item) => ({
                brand: item?.brand?.id,
                name: item?.name?.id,
                quantity: item.quantity,
                id: item.id,
                unit: item?.unit?.id,
              }))
            );
          }
          setTime(d.time);
          setType(d?.type?.id);
          setOrigin(d?.origin?.id);
          setMainImages(d?.images);
          setTotalServings(d?.serving);
          setCostPerServing(d?.cost_per_serving);
          setCoverImage(d?.cover_image);
          setCookingLevel(d?.cooking_level?.id);
          let hash = {};
          d.food_preference.map(
            (item) => (hash[item.id] = item.food_preference)
          );
          setFoodPreferenceList((prev) => {
            return prev.map((item) => {
              if (hash[item.id]) {
                return { ...item, checked: true };
              } else {
                return { ...item, checked: false };
              }
            });
          });
        }
      });
    }
  };
  const getDescriptionImages = () => {
    return descriptionImages
      .filter((item) => {
        return description.includes(item.file);
      })
      .map((item) => {
        return item.id;
      });
  };
  const getPreparationImages = () => {
    return preparationImages
      .filter((item) => {
        return preparation.includes(item.file);
      })
      .map((item) => {
        return item.id;
      });
  };
  const getInstructionImages = () => {
    return instructionImages
      .filter((item) => {
        return instructions.includes(item.file);
      })
      .map((item) => {
        return item.id;
      });
  };
  const deleteMainImage = (id) => {
    axios_delete(DELETE_RECIPE_FILE + "/" + id).then((res) => {
      if (res?.status === 204) {
        let arr = [...mainImages].filter((item) => item.id !== id);
        setMainImages(arr);
      }
    });
  };
  const submitRecipe = (isDraft, update) => {
    let data = {
      title,
      description,
      instruction: instructions,
      preparation,
      time,
      origin,
      status: isDraft ? "DRAFT" : "PENDING",
      type,
      category,
      food_preference: foodPreferenceList
        .filter((item) => item.checked)
        .map((item) => item.id),
      ingredients,
      description_images: getDescriptionImages(),
      preparation_images: getPreparationImages(),
      instruction_images: getInstructionImages(),
      images: mainImages.map((item) => item.id),
      cover_image: get(coverImage, "id"),
      cost_per_serving: costPerServing,
      serving: totalServings,
      cooking_level: cookingLevel,
    };
    console.log("data", data);
    if (props.recipeId) {
      axios_put(GET_RECIPE + props.recipeId + "/", data, true).then((res) => {
        console.log("udpate", res);
      });
      return;
    }
    axios_post(ADD_RECIPE, data, true)
      .then((res) => {
        console.log("create res", res);
        if (res?.data.status.code === 201) {
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
          setDescriptionImages([]);
          setPreparationImages([]);
          setInstructionImages([]);
          setMainImages([]);
          setCategory("");
          setFoodPreferenceList(
            foodPreferenceList.map((item) => (item.checked = false))
          );
          setIngredients([]);
          setType("");
          setTitle("");
          setDescription("");
          setInstructions("");
          setPreparation("");
          setTime("");
          setOrigin("");
          navigate("/my-recipes");
        }
      })
      .catch((err) => console.log("recipe err", err));
  };
  const getCategoryList = () => {
    axios_get(RECIPE_CATEGORY_LIST, true).then((res) => {
      if (res?.data.status.code === 200) {
        setCategoryList(res.data.data);
      }
    });
  };
  const getFoodPreferenceList = () => {
    axios_get(RECIPE_FOOD_PREFERENCE_LIST, true).then((res) => {
      if (res?.data.status.code === 200) {
        setFoodPreferenceList(
          res.data.data.map((item) => ({ ...item, checked: false }))
        );
      }
    });
  };
  const getIngredientBrandList = () => {
    axios_get(RECIPE_INGREDIENT_BRAND_LIST, true).then((res) => {
      if (res?.data.status.code === 200) {
        setIngredientBrandList(res.data.data);
      }
    });
  };
  const getIngredientNameList = () => {
    axios_get(RECIPE_INGREDIENT_NAME_LIST, true).then((res) => {
      if (res?.data.status.code === 200) {
        setIngredientNameList(res.data.data);
      }
    });
  };
  const getIngredientUnitList = () => {
    axios_get(RECIPE_INGREDIENT_UNIT_LIST, true).then((res) => {
      if (res?.data.status.code === 200) {
        setIngredientUnitList(res.data.data);
      }
    });
  };
  const getRecipeOriginList = () => {
    axios_get(RECIPE_ORIGIN_LIST, true).then((res) => {
      if (res?.data.status.code === 200) {
        setRecipeOriginList(res.data.data);
      }
    });
  };
  const getRecipeTypeList = () => {
    axios_get(RECIPE_TYPE_LIST, true).then((res) => {
      if (res?.data.status.code === 200) {
        setRecipeTypeList(res.data.data);
      }
    });
  };
  const handleIngredientAdd = () => {
    let arr = [...ingredients];
    let ingredient = {
      name: ingredientName.value,
      brand: ingredientBrand.value,
      quantity: ingredientQuantity,
      unit: ingredientUnit.value,
      id: null,
    };
    arr.push(ingredient);
    setIngredients(arr);
  };
  const handleIngredientDelete = (index) => {
    console.log("dd");
    let arr = [...ingredients];
    arr = arr.filter((item, i) => i !== index);
    setIngredients(arr);
  };
  useEffect(() => {
    getRecipeOriginList();
    getRecipeTypeList();
    getIngredientUnitList();
    getCategoryList();
    getFoodPreferenceList();
    getIngredientBrandList();
    getIngredientNameList();
    getRecipeDetails();
    getCookingLevel();
  }, []);
  const handleIngredient = (i, type, option) => {
    let arr = [...ingredients];
    arr[i][type] = option.value;
    setIngredients(arr);
  };
  const handleMainImageUpload = (e) => {
    if (!e.target.files.length) {
      return;
    }
    let formData = new FormData();
    formData.append("file", e.target.files[0], e.target.files[0].name);
    formData.append("file_for", "MAIN");
    formData.append("file_type", "IMAGE");
    axios_post(POST_RECIPE_FILE, formData).then((res) => {
      console.log("lskf", res);
      if (res?.data?.data) {
        let arr = [...mainImages];
        arr.push(res.data.data);
        setMainImages(arr);
      }
    });
  };
  const getCookingLevel = () => {
    axios_get(COOKING_LEVEL).then((res) => {
      setCookingLevels(get(res, "data.data", []));
    });
  };
  const onCrop = (obj) => {
    console.log("blob", obj);
    let { blob } = obj;
    let formData = new FormData();
    formData.append("file", blob, blob.name);
    formData.append("file_for", "MAIN");
    formData.append("file_type", "IMAGE");
    formData.append("is_cover", true);
    axios_post(POST_RECIPE_FILE, formData).then((res) => {
      if (res?.data?.data) {
        setCoverImage(res.data.data);
      }
    });
  };
  return (
    <div>
      <Header />
      <AdminLayout>
        <form className="add_recipe_form">
          <div className="form_control">
            <label htmlFor="">Title</label>
            <input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="form_control">
            <label htmlFor="">Cover Image</label>
            <input
              type="file"
              onChange={(e) => {
                console.log("fffff", e.target.files);
                if (!e.target.files.length) {
                  return;
                }
                setImgObj(e);
              }}
            />
            <Cropper onCropConfirm={onCrop} img={imgObj} />
          </div>
          <img src={get(coverImage, "file", "")} />
          <label htmlFor="upload_recipe_image" className="image_drop_zone">
            <span>
              <span className="material-icons">upload</span> Upload Images
            </span>
            <input
              id="upload_recipe_image"
              type="file"
              onChange={handleMainImageUpload}
              hidden
            />
          </label>
          <div className="dropped_images_zone">
            {mainImages.map((item) => (
              <div
                className="dropped_image"
                style={{ backgroundImage: `url('${item.file}')` }}
              >
                <button type="button" onClick={() => deleteMainImage(item.id)}>
                  <span className="material-icons">cancel</span>
                </button>
              </div>
            ))}

            {/* <div
              className="dropped_image"
              style={{ backgroundImage: "url('/recipe_7.jpg')" }}
            >
              <button type="button">
                <span className="material-icons">cancel</span>
              </button>
            </div> */}
          </div>
          <div className="form_control">
            <label htmlFor="">Description</label>
            <div>
              {" "}
              <input
                id="my-file"
                type="file"
                name="my-file"
                style={{ display: "none" }}
              />
              <Editor
                value={description}
                onEditorChange={(val) => setDescription(val)}
                apiKey="ldh8fyrmgk476x863i5uzyiab57ukpaau04eyisucvz3608y"
                onInit={(evt, editor) => (editorRef.current = editor)}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist | " +
                    "removeformat",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  file_picker_types: "image",
                  file_browser_callback_types: "image",
                  file_picker_callback: function (callback, value, meta) {
                    if (meta.filetype === "image") {
                      var input = document.getElementById("my-file");
                      input.click();
                      input.onchange = function () {
                        let formData = new FormData();
                        formData.append(
                          "file",
                          input.files[0],
                          input.files[0].name
                        );
                        formData.append("file_for", "PREPARATION");
                        formData.append("file_type", "IMAGE");
                        axios_post(POST_RECIPE_FILE, formData).then((res) => {
                          let arr = [...preparationImages];
                          arr.push(res.data.data);
                          setPreparationImages(arr);
                          callback(res.data.data.file, {
                            alt: input.files[0].name,
                          });
                        });
                      };
                    }
                  },
                  paste_data_images: true,
                }}
              />
            </div>
          </div>
          <div className="form_control">
            <label htmlFor="">Ingredients</label>
            <div className="inline_inputs">
              <div>
                <label className="sub_label">Name</label>
                <CreatableSelect
                  onChange={(option) => {
                    let arr = [...ingredientNameList];

                    setIngredientName(option);
                  }}
                  value={ingredientName}
                  options={ingredientNameList.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                />
              </div>
              <div>
                <label className="sub_label">Brand</label>
                <CreatableSelect
                  onChange={(option) => {
                    let arr = [...ingredientBrandList];

                    setIngredientBrand(option);
                  }}
                  value={ingredientBrand}
                  options={ingredientBrandList.map((item) => ({
                    value: item.id,
                    label: item.brand,
                  }))}
                />
              </div>
              <div>
                <label className="sub_label">Quantity</label>
                <div className="inline_inputs">
                  <input
                    type="number"
                    placeholder="Quantity"
                    style={{ width: 90 }}
                    required
                    onChange={(e) => {
                      setIngredientQuantity(e.target.value);
                    }}
                    value={ingredientQuantity}
                  />
                  <CreatableSelect
                    onChange={(option) => {
                      let arr = [...ingredientUnitList];

                      setIngredientUnit(option);
                    }}
                    value={ingredientUnit}
                    options={ingredientUnitList.map((item) => ({
                      value: item.id,
                      label: item.unit,
                    }))}
                  />
                </div>
              </div>
              <button
                disabled={
                  ingredientUnit &&
                  ingredientUnit &&
                  ingredientQuantity &&
                  ingredientName
                    ? false
                    : true
                }
                type="button"
                onClick={handleIngredientAdd}
              >
                Add
              </button>
            </div>
          </div>
          <div className="form_control">
            <div className="ingredient_list">
              {ingredients.map((item, i) => (
                <div key={i} className="ingredient_item">
                  {ingredientNameList.filter((ing) => ing.id === item.name)[0]
                    ?.name || item.name}{" "}
                  {ingredientBrandList.filter((ing) => ing.id === item.brand)[0]
                    ?.brand || item.brand
                    ? `(${
                        ingredientBrandList.filter(
                          (ing) => ing.id === item.brand
                        )[0]?.brand || item.brand
                      })`
                    : ""}
                  - {Number(item.quantity)}{" "}
                  {ingredientUnitList.filter((ing) => ing.id === item.unit)[0]
                    ?.unit || item.unit}
                  <button onClick={() => handleIngredientDelete(i)}>
                    <span className="material-icons">close</span>
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div className="form_control">
            <label htmlFor="">Tools</label>
            <Editor
              value={preparation}
              onEditorChange={(val) => setPreparation(val)}
              apiKey="ldh8fyrmgk476x863i5uzyiab57ukpaau04eyisucvz3608y"
              onInit={(evt, editor) => (editorRef.current = editor)}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | image media | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist | " +
                  "removeformat",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                file_picker_types: "image",
                file_browser_callback_types: "image",
                file_picker_callback: function (callback, value, meta) {
                  if (meta.filetype === "image") {
                    var input = document.getElementById("my-file");
                    input.click();
                    input.onchange = function () {
                      let formData = new FormData();
                      formData.append(
                        "file",
                        input.files[0],
                        input.files[0].name
                      );
                      formData.append("file_for", "PREPARATION");
                      formData.append("file_type", "IMAGE");
                      axios_post(POST_RECIPE_FILE, formData).then((res) => {
                        let arr = [...preparationImages];
                        arr.push(res.data.data);
                        setPreparationImages(arr);
                        callback(res.data.data.file, {
                          alt: input.files[0].name,
                        });
                      });
                    };
                  }
                },
                paste_data_images: true,
              }}
            />
          </div>
          <div className="form_control">
            <label htmlFor="">Instructions</label>
            <Editor
              value={instructions}
              onEditorChange={(val) => setInstructions(val)}
              apiKey="ldh8fyrmgk476x863i5uzyiab57ukpaau04eyisucvz3608y"
              onInit={(evt, editor) => (editorRef.current = editor)}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image  charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | image media | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist | " +
                  "removeformat",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                file_picker_types: "image",
                file_browser_callback_types: "image",
                file_picker_callback: function (callback, value, meta) {
                  if (meta.filetype === "image") {
                    var input = document.getElementById("my-file");
                    input.click();
                    input.onchange = function () {
                      let formData = new FormData();
                      formData.append(
                        "file",
                        input.files[0],
                        input.files[0].name
                      );
                      formData.append("file_for", "INSTRUCTION");
                      formData.append("file_type", "IMAGE");
                      axios_post(POST_RECIPE_FILE, formData).then((res) => {
                        let arr = [...instructionImages];
                        arr.push(res.data.data);
                        setInstructionImages(arr);
                        callback(res.data.data.file, {
                          alt: input.files[0].name,
                        });
                      });
                    };
                  }
                },
                paste_data_images: true,
              }}
            />
          </div>
          <div className="form_control">
            <label>Food Preferences</label>
            <div className="food_preference">
              {foodPreferenceList.map((item, i) => (
                <div className="custom_checkbox">
                  <input
                    id={item.id}
                    name={item.id}
                    checked={item.checked}
                    onChange={(e) => {
                      let arr = [...foodPreferenceList];
                      arr = arr.map((el) => {
                        if (el.id === item.id) {
                          el.checked = e.target.checked;
                        }
                        return el;
                      });
                      setFoodPreferenceList(arr);
                    }}
                    type="checkbox"
                    hidden
                  />
                  <label htmlFor={item.id}>{item.food_preference}</label>
                </div>
              ))}
            </div>
          </div>
          <div className="form_control">
            <label htmlFor="">Recipe Details</label>
            <div className="inline_inputs">
              <div>
                <label className="sub_label">Cooking Level</label>
                <Select
                  onChange={(option) => setCookingLevel(option.value)}
                  value={cookingLevels
                    .filter((item) => item.id === cookingLevel)
                    .map((item) => ({ value: item.id, label: item.level }))}
                  options={cookingLevels.map((item) => ({
                    value: item.id,
                    label: item.level,
                  }))}
                />
              </div>
              <div>
                <label className="sub_label">Total Servings</label>
                <input
                  type="number"
                  value={totalServings}
                  onChange={(e) => setTotalServings(e.target.value)}
                  placeholder="Total servings"
                  required
                />
              </div>
              <div>
                <label className="sub_label">Cost Per Serving</label>
                <input
                  type="number"
                  value={costPerServing}
                  onChange={(e) => setCostPerServing(e.target.value)}
                  placeholder="Cost"
                  required
                />
              </div>
            </div>
            <div className="inline_inputs">
              <div>
                <label className="sub_label">Type</label>
                <Select
                  onChange={(option) => setType(option.value)}
                  value={recipeTypeList
                    .filter((item) => item.id === type)
                    .map((item) => ({ value: item.id, label: item.type }))}
                  options={recipeTypeList.map((item) => ({
                    value: item.id,
                    label: item.type,
                  }))}
                />
              </div>
              <div>
                <label className="sub_label">Category</label>
                <Select
                  onChange={(option) => setCategory(option.value)}
                  value={categoryList
                    .filter((item) => item.id === category)
                    .map((item) => ({ value: item.id, label: item.category }))}
                  options={categoryList.map((item) => ({
                    value: item.id,
                    label: item.category,
                  }))}
                />
              </div>
              <div className="shrink">
                <label className="sub_label">Preparation Time</label>
                <input
                  type="number"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  placeholder="Time in minutes"
                  required
                />
              </div>
              <div>
                <label className="sub_label">Origin</label>
                <Select
                  onChange={(option) => setOrigin(option.value)}
                  value={recipeOriginList
                    .filter((item) => item.id === origin)
                    .map((item) => ({ value: item.id, label: item.origin }))}
                  options={recipeOriginList.map((item) => ({
                    value: item.id,
                    label: item.origin,
                  }))}
                />
              </div>
            </div>
          </div>
          <div className="twin_buttons">
            <button
              className="secondary_btn"
              onClick={(e) => {
                e.preventDefault();
                submitRecipe(true);
              }}
            >
              Save Draft
            </button>
            <button
              className="primary_btn"
              onClick={(e) => {
                e.preventDefault();
                submitRecipe();
              }}
            >
              Submit
            </button>
          </div>
        </form>
      </AdminLayout>
      <Footer />
    </div>
  );
}
