import React from "react";
import { Modal } from "antd";
import { useSelector } from "react-redux";
import { get } from "lodash";
import CartRow from "./cartRow";
import { calculate_delivery_date } from "../../utils/service";
import moment from "moment";
import CateringSetCard from "../CateringSetCard/cateringSetCard";
const CartList = ({ isModalVisible, handleOk, handleCancel }) => {
  let cart = useSelector((state) => get(state, "cart.cart", {}));
  let cateringCart = useSelector((state) =>
    get(state, "cart.cateringCart", {})
  );

  let delivery_fee = get(cart, "delivery_fee", 0);
  delivery_fee = delivery_fee ? delivery_fee : "Free";

  const delivery_date = calculate_delivery_date(
    get(cart, "address.post_code.delivery_in_days", 2)
  );

  return (
    <div>
      <div className="cart_heading clearfix pincode-check">
        <div className="col-xs-8">Item Details</div>
        <div className="col-xs-2">Unit Price</div>
        <div className="col-xs-4">Quantity</div>
        <div className="col-xs-6">Delivery Details</div>
        <div className="col-xs-4">Sub Total</div>
      </div>
      {get(cart, "cart_products", []).map((item) => (
        <CartRow key={item.id} item={item} delivery_day={delivery_date} />
      ))}
      { get(cateringCart, "catering_cart_products", []).length >0 && (
          <h1>Catering Cart</h1>
      ) }

      {get(cateringCart, "catering_cart_products", []).map((item) =>
        item.catering_set ? (
          <CateringSetCard
            hideAddToCart
            set={item.catering_set}
            showRemove
            total={item.item_total}
            date={get(item, "catering.date", null)}
            showCartControls
          />
        ) : (
          <CartRow
            catering
            key={item.id}
            item={item}
            delivery_day={moment(item.catering.date).format("Do MMM, ddd")}
          />
        )
      )}
    </div>
  );
};

export default CartList;
