import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import AdminLayout from "../../components/AdminLayout/admin_layout";
import { SRLWrapper } from "simple-react-lightbox";
import "./profile.scss";
import {
  axios_put,
  UPDATE_USER_URL,
  axios_get,
  GET_USER_URL,
} from "../../utils/service";
import { useState, useEffect } from "react";
import { Tabs, Modal } from "antd";
import Cookies from "js-cookie";
import Cropper from "../../components/ImageCropper/image_cropper";
const { TabPane } = Tabs;
export default function UserSettings() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [about, setAbout] = useState("");
  const [profile_pic, setProfilePic] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [instagram, setInstagram] = useState("");
  const [youtube, setYoutube] = useState("");
  const [imgObj, setImgObj] = useState("");
  useEffect(() => {
    axios_get(GET_USER_URL, true).then((res) => {
      console.log("ggg", res);
      if (res.data) {
        let {
          about,
          email,
          first_name,
          last_name,
          profile_pic,
          facebook,
          twitter,
          instagram,
          youtube,
          username,
        } = res.data.data;
        setFirstName(first_name);
        setLastName(last_name);
        setEmail(email);
        setAbout(about);
        setProfilePic(profile_pic);
        setFacebook(facebook);
        setTwitter(twitter);
        setInstagram(instagram);
        setYoutube(youtube);
        setUsername(username);
        Cookies.set("profile_pic", profile_pic);
      }
    });
  }, []);

  const OnSubmitSendLink = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("email", email);
    formData.append("about", about);
    formData.append("facebook", facebook);
    formData.append("twitter", twitter);
    formData.append("youtube", youtube);
    formData.append("instagram", instagram);
    formData.append("username", username);
    if (selectedImage) {
      formData.append("profile_pic", selectedImage, selectedImage.name);
    }
    const res = axios_put(UPDATE_USER_URL, formData, true, true).then(
      (response) => {
        if (response.data) {
          setUsername(response.data.data.username);
        }
      }
    );
  };
  const onCrop = (obj) => {
    setSelectedImage(obj.blob);
    setProfilePic(obj.croppedImageUrl);
  };

  return (
    <div>
      <Header />
      <AdminLayout>
        <div className="profile_management">
          <div className="profile_setting_head">
            <div
              className="edit_profile"
              style={{ backgroundImage: `url("${profile_pic}")` }}
            >
              <input
                id="profile_picture"
                onChange={(e) => {
                  setImgObj(e);
                  // setSelectedImage(e.target.files[0]);
                  // if (e.target.files[0]) {
                  //   setProfilePic(URL.createObjectURL(e.target.files[0]));
                  // }
                }}
                type="file"
                accept="image/png, image/gif, image/jpeg"
                hidden
              />

              <label htmlFor="profile_picture">
                <span className="material-icons">edit</span>
              </label>
              <Cropper aspect="square" onCropConfirm={onCrop} img={imgObj} />
            </div>
          </div>
          <div className="profile_body">
            <form className="profile_form" onSubmit={OnSubmitSendLink}>
              <h2>Basic Info</h2>
              <div className="form_control">
                <label>Username</label>
                <input
                  value={username}
                  required
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="form_control half">
                <div className="col">
                  <label>First Name</label>
                  <input
                    value={first_name}
                    required
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="col">
                  <label>Last Name</label>
                  <input
                    value={last_name}
                    required
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="form_control">
                <label>Email</label>
                <input
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form_control">
                <label>About</label>
                <textarea
                  value={about}
                  required
                  onChange={(e) => setAbout(e.target.value)}
                />
              </div>
              <h2>Social Media</h2>
              <div className="form_control half">
                <div className="col">
                  <label>Facebook</label>
                  <input
                    value={facebook}
                    onChange={(e) => setFacebook(e.target.value)}
                  />
                </div>
                <div className="col">
                  <label>Twitter</label>
                  <input
                    value={twitter}
                    onChange={(e) => setTwitter(e.target.value)}
                  />
                </div>
              </div>
              <div className="form_control half">
                <div className="col">
                  <label>YouTube</label>
                  <input
                    value={youtube}
                    onChange={(e) => setYoutube(e.target.value)}
                  />
                </div>
                <div className="col">
                  <label>Instagram</label>
                  <input
                    value={instagram}
                    onChange={(e) => setInstagram(e.target.value)}
                  />
                </div>
              </div>
              <div className="profile_twin_buttons">
                <a
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  href={"/user/" + username}
                >
                  View profile
                </a>
                <a
                  href="/change-password"
                >
                  Change password
                </a>
                <button style={{ marginTop: 0 }} type="submit">
                  Update
                </button>
              </div>
            </form>
            {/* <Tabs defaultActiveKey="1">
                <TabPane tab="Basic Information" key="1">
                </TabPane>
                <TabPane tab="Gallery" key="2">
                  <div className="gallery">
                    <button onClick={showModal}><span className="material-icons">file_upload</span>Upload Media</button>
                    <SRLWrapper>
                      <div className="library_grid">
                        <img alt="" src="https://images.pexels.com/photos/4553027/pexels-photo-4553027.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"/>
                        <img alt="" src="https://images.pexels.com/photos/8434641/pexels-photo-8434641.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"/>
                        <img alt="" src="https://images.pexels.com/photos/4553027/pexels-photo-4553027.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"/>
                        <img alt="" src="https://images.pexels.com/photos/8434641/pexels-photo-8434641.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"/>
                        <img alt="" src="https://images.pexels.com/photos/4553027/pexels-photo-4553027.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"/>
                        <img alt="" src="https://images.pexels.com/photos/4553027/pexels-photo-4553027.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"/>
                        <img alt="" src="https://images.pexels.com/photos/8434641/pexels-photo-8434641.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"/>
                        <img alt="" src="https://images.pexels.com/photos/8434641/pexels-photo-8434641.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"/>
                        <img alt="" src="https://images.pexels.com/photos/4553027/pexels-photo-4553027.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"/>
                        <img alt="" src="https://images.pexels.com/photos/8434641/pexels-photo-8434641.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"/>
                        <img alt="" src="https://images.pexels.com/photos/4553027/pexels-photo-4553027.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"/>
                        <img alt="" src="https://images.pexels.com/photos/4553027/pexels-photo-4553027.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"/>
                        <img alt="" src="https://images.pexels.com/photos/8434641/pexels-photo-8434641.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"/>
                        <img alt="" src="https://images.pexels.com/photos/4553027/pexels-photo-4553027.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"/>
                      </div>
                    </SRLWrapper>
                  </div>
                </TabPane>
              </Tabs> */}
          </div>
        </div>
        <Modal
          title="Upload media"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={[]}
          // centered={true}
        >
          <div className="upload_image">
            <button>
              <span className="material-icons">collections</span>
            </button>
            <button>
              <span className="material-icons">videocam</span>
            </button>
          </div>
        </Modal>
      </AdminLayout>
      <Footer />
    </div>
  );
}
