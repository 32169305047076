import React from "react";
import Cookies from "js-cookie";
import { navigate } from "@reach/router";

export default function DashboardPage(props) {
  const onClickLogout = (e) => {
    e.preventDefault();
    Cookies.remove("user_id");
    Cookies.remove("token");
    navigate("/login");
  };
  const setActive = (url) => {
    if (window.location.href.includes(url)) {
      return "active";
    } else {
      return "";
    }
  };
  return (
    <div className="min_height">
      <div className="container">
        <div className="dashboard">
          {Cookies.get('user_id') && (
              <div className="admin_sidebar">
                  <ul>
              <li className={setActive("dashboard")} style={{ marginTop: 0 }}>
                <a href="/dashboard">
                  <span className="list_icon">
                    <svg
                      viewBox="0 0 43 43"
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                    >
                      <path
                        d="M22.111 12.556h14.333v4.778H22.111zm0 9.556h14.333v4.778H22.111zm0 9.556h14.333v4.778H22.111zm-9.555-19.112h4.778v4.778h-4.778zm0 9.556h4.778v4.778h-4.778zm0 9.556h4.778v4.778h-4.778zM43.85 3H5.15A2.141 2.141 0 0 0 3 5.15v38.7A2.309 2.309 0 0 0 5.15 46h38.7A2.534 2.534 0 0 0 46 43.85V5.15A2.309 2.309 0 0 0 43.85 3zm-2.628 38.222H7.778V7.778h33.444z"
                        transform="translate(-3 -3)"
                      />
                    </svg>
                  </span>
                  My Feed
                </a>
              </li>
              <li className={setActive("wallet")} style={{ marginTop: 0 }}>
                <a href="/wallet">
                  <span className="list_icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 44.683 35.322">
                    <g id="wallet" transform="translate(-1.5 -4.5)">
                      <path id="Path_118" data-name="Path 118" d="M42.563,39.322H5.12A3.12,3.12,0,0,1,2,36.2V8.12A3.12,3.12,0,0,1,5.12,5H42.563a3.12,3.12,0,0,1,3.12,3.12V36.2A3.12,3.12,0,0,1,42.563,39.322ZM5.12,8.12V36.2H42.563V8.12Z" transform="translate(0)" fill="#5c5c5c" stroke="#5c5c5c" stroke-width="1"/>
                      <path id="Path_119" data-name="Path 119" d="M36.281,26.6H23.8a7.8,7.8,0,0,1,0-15.6H36.281a1.56,1.56,0,0,1,1.56,1.56V25.041A1.56,1.56,0,0,1,36.281,26.6ZM23.8,14.12a4.68,4.68,0,0,0,0,9.361H34.721V14.12Z" transform="translate(7.841 3.361)" fill="#5c5c5c" stroke="#5c5c5c" stroke-width="1"/>
                      <path id="Path_120" data-name="Path 120" d="M23.12,18.12H21.56a1.56,1.56,0,0,1,0-3.12h1.56a1.56,1.56,0,0,1,0,3.12Z" transform="translate(10.082 5.601)" fill="#5c5c5c" stroke="#5c5c5c" stroke-width="1"/>
                    </g>
                  </svg>
                  </span>
                  Bukk Credits
                </a>
              </li>
              <li className={setActive("order-history")} style={{ marginTop: 0 }}>
                <a href="/order-history">
                  <span className="list_icon">
                  <svg style={{width:28,marginLeft:-1}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 45">
                    <g transform="translate(-138.137 -93.938)">
                      <path d="M30.583,3A22.189,22.189,0,0,0,8.4,25.187H1l9.59,9.59.173.345,9.959-9.935h-7.4A17.339,17.339,0,1,1,18.4,37.365l-3.5,3.5A22.181,22.181,0,1,0,30.583,3ZM28.117,15.326V27.652l10.477,6.212,1.9-3.155-8.678-5.152V15.326Z" transform="translate(137.137 90.938)" fill="#5c5c5c"/>
                    </g>
                  </svg>
                  </span>
                  Order History
                </a>
              </li>
              <li className={setActive("addresses")} style={{ marginTop: 0 }}>
                <a href="/addresses">
                  <span className="list_icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22"viewBox="0 0 33.891 43.854">
                      <g id="location" transform="translate(-5 -2)">
                        <path id="Path_121" data-name="Path 121" d="M36.891,18.945c0,13.285-14.945,24.909-14.945,24.909S7,32.23,7,18.945a14.945,14.945,0,1,1,29.891,0Z" transform="translate(0 0)" fill="none" stroke="#818181" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
                        <circle id="Ellipse_17" data-name="Ellipse 17" cx="5.029" cy="5.029" r="5.029" transform="translate(17.059 14.059)" fill="none" stroke="#818181" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="4"/>
                      </g>
                    </svg>
                  </span>
                  Addresses
                </a>
              </li>
              <li className={setActive("my-recipes")}>
                <a href="/my-recipes">
                  <span className="list_icon">
                    <svg
                      viewBox="0 0 45.16 45.16"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                    >
                      <g data-name="Group 107">
                        <path
                          data-name="Path 34"
                          d="M41.876 29.1a16.753 16.753 0 0 1-9.935 9.913L29.1 40.138v2.506h-9.036v-2.506L17.2 39.009A16.753 16.753 0 0 1 7.261 29.1h34.615M47.16 2 6.516 6.493V24.58H2c0 8.332 5.577 15.49 13.548 18.628v3.952h18.064v-3.952C41.583 40.07 47.16 32.912 47.16 24.58H21.193v-9.032H47.16v-3.387H21.193V8.277L47.16 5.41V2zM15.548 12.161V8.909l2.258-.248v3.5zm-5.645 0V9.542l2.258-.248v2.868zm5.645 12.419v-9.032h2.258v9.032zm-5.645 0v-9.032h2.258v9.032z"
                          transform="translate(-2 -2)"
                        />
                      </g>
                    </svg>
                  </span>
                  My Recipes
                </a>
              </li>
              <li className={setActive("favorites")}>
                <a href="/favorites">
                  <span className="list_icon">
                    <svg
                      viewBox="0 0 46.081 43.777"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                    >
                      <path
                        data-name="Path 33"
                        d="m48.081 18.681-16.566-1.428L25.04 2l-6.474 15.276L2 18.681l12.58 10.9-3.78 16.196 14.24-8.594 14.239 8.594-3.756-16.2zM25.04 32.874 16.377 38.1l2.3-9.861-7.649-6.636 10.092-.876 3.917-9.285 3.94 9.308 10.092.876-7.649 6.636 2.3 9.861z"
                        transform="translate(-2 -2)"
                      />
                    </svg>
                  </span>
                  Favorites
                </a>
              </li>
              {/* <li className={setActive("user/"+Cookies.get('user_id'))}>
                <a href={"/user/"+ Cookies.get('user_id')}>
                  <span className="list_icon">
                    <svg
                      viewBox="0 0 44.16 44.16"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                    >
                      <path
                        data-name="Path 29"
                        d="M24.08 2a22.08 22.08 0 1 0 22.08 22.08A22.088 22.088 0 0 0 24.08 2zM13.195 37.946c.949-1.987 6.734-3.93 10.885-3.93s9.958 1.943 10.885 3.93a17.5 17.5 0 0 1-21.771 0zm24.928-3.2C34.965 30.9 27.3 29.6 24.08 29.6s-10.885 1.3-14.043 5.145a17.664 17.664 0 1 1 28.086 0zM24.08 10.832a7.728 7.728 0 1 0 7.728 7.728 7.708 7.708 0 0 0-7.728-7.728zm0 11.04a3.312 3.312 0 1 1 3.312-3.312 3.308 3.308 0 0 1-3.312 3.312z"
                        transform="translate(-2 -2)"
                      />
                    </svg>
                  </span>
                  My Summary
                </a>
              </li> */}
              <li className={setActive("user-settings")}>
                <a href="/user-settings">
                  <span className="list_icon">
                    <svg
                      viewBox="0 0 44.16 44.16"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                    >
                      <path
                        data-name="Path 29"
                        d="M24.08 2a22.08 22.08 0 1 0 22.08 22.08A22.088 22.088 0 0 0 24.08 2zM13.195 37.946c.949-1.987 6.734-3.93 10.885-3.93s9.958 1.943 10.885 3.93a17.5 17.5 0 0 1-21.771 0zm24.928-3.2C34.965 30.9 27.3 29.6 24.08 29.6s-10.885 1.3-14.043 5.145a17.664 17.664 0 1 1 28.086 0zM24.08 10.832a7.728 7.728 0 1 0 7.728 7.728 7.708 7.708 0 0 0-7.728-7.728zm0 11.04a3.312 3.312 0 1 1 3.312-3.312 3.308 3.308 0 0 1-3.312 3.312z"
                        transform="translate(-2 -2)"
                      />
                    </svg>
                  </span>
                  My Profile
                </a>
              </li>
              <li className={setActive("change-password")}>
                <a href="/change-password">
                  <span className="list_icon">
                    <svg
                      viewBox="0 0 44.16 44.16"
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                    >
                      <path
                        data-name="Path 29"
                        d="M24.08 2a22.08 22.08 0 1 0 22.08 22.08A22.088 22.088 0 0 0 24.08 2zM13.195 37.946c.949-1.987 6.734-3.93 10.885-3.93s9.958 1.943 10.885 3.93a17.5 17.5 0 0 1-21.771 0zm24.928-3.2C34.965 30.9 27.3 29.6 24.08 29.6s-10.885 1.3-14.043 5.145a17.664 17.664 0 1 1 28.086 0zM24.08 10.832a7.728 7.728 0 1 0 7.728 7.728 7.708 7.708 0 0 0-7.728-7.728zm0 11.04a3.312 3.312 0 1 1 3.312-3.312 3.308 3.308 0 0 1-3.312 3.312z"
                        transform="translate(-2 -2)"
                      />
                    </svg>
                  </span>
                  Change Password
                </a>
              </li>
              <li className="menu_logout">
                <div onClick={onClickLogout}>
                  <span className="list_icon">
                    <svg
                      viewBox="0 0 42.1 42.1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                    >
                      <g data-name="Group 114">
                        <path
                          data-name="Path 36"
                          d="m35.744 14.694-3.3 3.3 3.7 3.719H17.033v4.678h19.109l-3.7 3.7 3.3 3.321L45.1 24.05zM7.678 7.678H24.05V3H7.678A4.692 4.692 0 0 0 3 7.678v32.744A4.692 4.692 0 0 0 7.678 45.1H24.05v-4.678H7.678z"
                          transform="translate(-3 -3)"
                        />
                      </g>
                    </svg>
                  </span>
                  Logout
                </div>
              </li>
            </ul>
              </div>
          )}

          <div className="dashboard_content">{props.children}</div>
        </div>
      </div>
    </div>
  );
}
