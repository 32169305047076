import Header from '../../components/Header/header';
import React, { useState, useEffect } from 'react';
import Footer from '../../components/Footer/footer';
import { Tabs, Drawer } from 'antd';
import './catering.scss';
import { get } from 'lodash';
import {
  axios_get,
  CATERING,
  addToCateringCart,
  PRODUCT_VARIANT_API,
} from '../../utils/service';
import moment from 'moment';
import Cart from '../../components/Cart/cart';
import CateringRow from '../../components/CateringRow/cateringRow';
import CateringOrderSummary from '../../components/CateringOrderSummary/cateringOrderSummary';
import RepeatCatering from '../../components/RepeatCatering/repeatCatering';
import { useSelector } from 'react-redux';
const { TabPane } = Tabs;
let timer;
export default function Catering() {
  const cart = useSelector((state) => state.cart.cateringCart);
  const [searchDate, setSearchDate] = useState('');
  const [searchDateId, setSearchDateId] = useState('');
  const [activeTab, setActiveTab] = useState('1');
  const [visible, setVisible] = useState(false);
  const [cateringData, setCateringData] = useState([]);
  const [searchStr, setSearchStr] = useState('');
  const [month, setMonth] = useState(moment());
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [products, setProducts] = useState([]);
  const [week1Data, setWeek1Data] = useState([]);
  const [week2Data, setWeek2Data] = useState([]);
  const [week3Data, setWeek3Data] = useState([]);
  const [week4Data, setWeek4Data] = useState([]);
  const [week1dates, setweek1dates] = useState({
    start: moment(),
    end: moment(),
  });
  const [week2dates, setweek2dates] = useState({
    start: moment(),
    end: moment(),
  });
  const [week3dates, setweek3dates] = useState({
    start: moment(),
    end: moment(),
  });
  const [week4dates, setweek4dates] = useState({
    start: moment(),
    end: moment(),
  });
  const [didMount, setDidMount] = useState(true);
  const searchProducts = (str, date) => {
    let url =
      PRODUCT_VARIANT_API +
      '?available_in_catering=true&date=' +
      moment(date).format('YYYY-MM-DD');
    if (str) {
      url = url + '&search=' + str;
    }
    axios_get(url).then((res) => {
      console.log('search res', res);
      setProducts(get(res, 'data.data', []));
    });
  };
  const setDatesByWeek = () => {
    const monthFirstDate = moment().startOf('isoweek');
    const monthLastDate = moment(month).endOf('M');
    const week1 = {
      start: monthFirstDate,
      end: moment(monthFirstDate).add(6, 'd'),
    };
    const week2 = {
      start: moment(monthFirstDate).add(7, 'd'),
      end: moment(monthFirstDate).add(13, 'd'),
    };
    const week3 = {
      start: moment(monthFirstDate).add(14, 'd'),
      end: moment(monthFirstDate).add(20, 'd'),
    };
    const week4 = {
      start: moment(monthFirstDate).add(21, 'd'),
      end: moment(monthFirstDate).add(27, 'd'),
    };
    setweek1dates(week1);
    setweek2dates(week2);
    setweek3dates(week3);
    setweek4dates(week4);
  };
  useEffect(() => {
    setDidMount(false);
    if (!didMount) {
      getCateringDetails();
    }
  }, [week1dates, week2dates, week3dates, week4dates]);
  useEffect(() => {
    setDatesByWeek();
  }, [month]);
  const showDrawer = (date, id) => {
    setSearchDate(date);
    setSearchDateId(id);
    searchProducts('', date);
    setVisible(true);
  };
  const onClose = () => {
    setSearchStr('');
    setVisible(false);
  };

  const getCateringDetails = () => {
    let url =
      CATERING +
      '?month=' +
      month.format('M') +
      '&year=' +
      month.format('YYYY');
    axios_get(url).then((res) => {
      console.log('catering', res);
      let arr = get(res, 'data.data', []);
      let week1 = [];
      let week2 = [];
      let week3 = [];
      let week4 = [];
      arr.map((item) => {
        let d = item.date;
        if (
          moment(d).isBetween(week1dates.start, week1dates.end, undefined, [])
        ) {
          week1.push(item);
        }
        if (
          moment(d).isBetween(week2dates.start, week2dates.end, undefined, [])
        ) {
          week2.push(item);
        }
        if (
          moment(d).isBetween(week3dates.start, week3dates.end, undefined, [])
        ) {
          week3.push(item);
        }
        if (
          moment(d).isBetween(week4dates.start, week4dates.end, undefined, [])
        ) {
          week4.push(item);
        }
      });
      setCateringData(get(res, 'data.data', []));
      setWeek1Data(week1);
      setWeek2Data(week2);
      setWeek3Data(week3);
      setWeek4Data(week4);
    });
  };
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const _addToCateringCart = (setId, dayId) => {
    addToCateringCart(setId, dayId, getCateringDetails);
  };

  return (
    <React.Fragment>
      <Header />
      <div className="catering min_height">
        <div className="container">
          <div className="week_nav">
            <Tabs
              onTabClick={(val) => setActiveTab(val)}
              defaultActiveKey="1"
              activeKey={activeTab}
              tabBarExtraContent={
                <div className="weekend_cart">
                  {/* <div className="monthly_nav">
                    <button
                      onClick={() => {
                        let nextMonth = moment(month).subtract(1, "M");
                        if (
                          nextMonth >= moment() ||
                          nextMonth.month() >= moment().month()
                        ) {
                          setMonth(nextMonth);
                        }
                      }}
                    >
                      <span className="material-icons">chevron_left</span>
                    </button>
                    {moment(month).format("MMMM, YYYY")}
                    <button
                      onClick={() => {
                        setMonth(moment(month).add(1, "M"));
                      }}
                    >
                      <span className="material-icons">chevron_right</span>
                    </button>
                  </div> */}
                  <button onClick={showModal} className="cart_header">
                    <span className="material-icons">shopping_cart</span>
                    <span className="cart_count">
                      <span>
                        {get(cart, 'catering_cart_products.length', 0)}
                      </span>
                    </span>
                  </button>
                </div>
              }
            >
              <TabPane
                tab={
                  <h5>
                    Week 1{' '}
                    <small>{`${moment(week1dates.start).format(
                      'DD MMM'
                    )}-${moment(week1dates.end).format('DD MMM')}`}</small>
                  </h5>
                }
                key="1"
              >
                <div className="week_cont">
                  <div className="week_days">
                    {week1Data.map((day) => (
                      <CateringRow
                        addToCateringCart={_addToCateringCart}
                        key={day.id}
                        showDrawer={() => showDrawer(day.date, day.id)}
                        day={day}
                      />
                    ))}
                    <RepeatCatering
                      onPress={(tab) => setActiveTab(tab.toString())}
                      tab={1}
                      noData={week1Data.length ? false : true}
                    />
                  </div>
                  <CateringOrderSummary
                    week1dates={week1dates}
                    week2dates={week2dates}
                    week3dates={week3dates}
                    week4dates={week4dates}
                  />
                </div>
              </TabPane>
              <TabPane
                tab={
                  <h5>
                    Week 2{' '}
                    <small>{`${moment(week2dates.start).format(
                      'DD MMM'
                    )}-${moment(week2dates.end).format('DD MMM')}`}</small>
                  </h5>
                }
                key="2"
              >
                <div className="week_cont">
                  <div className="week_days">
                    {week2Data.map((day) => (
                      <CateringRow
                        addToCateringCart={_addToCateringCart}
                        key={day.id}
                        showDrawer={() => showDrawer(day.date, day.id)}
                        day={day}
                      />
                    ))}
                    <RepeatCatering
                      onPress={(tab) => setActiveTab(tab.toString())}
                      tab={2}
                      noData={week2Data.length ? false : true}
                    />
                  </div>
                  <CateringOrderSummary
                    week1dates={week1dates}
                    week2dates={week2dates}
                    week3dates={week3dates}
                    week4dates={week4dates}
                  />
                </div>
              </TabPane>
              <TabPane
                tab={
                  <h5>
                    Week 3{' '}
                    <small>{`${moment(week3dates.start).format(
                      'DD MMM'
                    )}-${moment(week3dates.end).format('DD MMM')}`}</small>
                  </h5>
                }
                key="3"
              >
                <div className="week_cont">
                  <div className="week_days">
                    {week3Data.map((day) => (
                      <CateringRow
                        addToCateringCart={_addToCateringCart}
                        key={day.id}
                        showDrawer={() => showDrawer(day.date, day.id)}
                        day={day}
                      />
                    ))}
                    <RepeatCatering
                      onPress={(tab) => setActiveTab(tab.toString())}
                      tab={3}
                      noData={week3Data.length ? false : true}
                    />
                  </div>
                  <CateringOrderSummary
                    week1dates={week1dates}
                    week2dates={week2dates}
                    week3dates={week3dates}
                    week4dates={week4dates}
                  />
                </div>
              </TabPane>
              <TabPane
                tab={
                  <h5>
                    Week 4{' '}
                    <small>{`${moment(week4dates.start).format(
                      'DD MMM'
                    )}-${moment(week4dates.end).format('DD MMM')}`}</small>
                  </h5>
                }
                key="4"
              >
                <div className="week_cont">
                  <div className="week_days">
                    {week4Data.map((day) => (
                      <CateringRow
                        addToCateringCart={_addToCateringCart}
                        key={day.id}
                        showDrawer={() => showDrawer(day.date, day.id)}
                        day={day}
                      />
                    ))}
                    <RepeatCatering
                      onPress={(tab) => setActiveTab(tab.toString())}
                      tab={4}
                      noData={week4Data.length ? false : true}
                    />
                  </div>
                  <CateringOrderSummary
                    week1dates={week1dates}
                    week2dates={week2dates}
                    week3dates={week3dates}
                    week4dates={week4dates}
                  />
                </div>
              </TabPane>
            </Tabs>
          </div>
          <Drawer
            width={400}
            title={
              'Add Items for - ' + moment(searchDate).format('dddd, DD MMMM')
            }
            placement="right"
            onClose={onClose}
            visible={visible}
          >
            <input
              className="search_menu_item"
              placeholder="Search menu"
              type="search"
              value={searchStr}
              onChange={(e) => {
                clearTimeout(timer);
                setSearchStr(e.target.value);
                timer = setTimeout(() => {
                  searchProducts(e.target.value, searchDate);
                }, 1000);
              }}
            />
            <div className="menu_items">
              {products.map((item) => (
                <div className="extra_menu_item">
                  <div
                    className="menu_img"
                    style={{
                      backgroundImage: 'url(' + item.cover_image.image + ')',
                    }}
                  ></div>
                  <div>
                    <h5>{get(item, 'product.name', '')}</h5>
                    <div className="price_menu">
                      <b>${item.catering_price || item.price}</b>
                      {item.is_added ? (
                        <p>Added to cart</p>
                      ) : (
                        <button
                          onClick={() =>
                            addToCateringCart(
                              undefined,
                              searchDateId,
                              () => {
                                searchProducts(searchStr, searchDate);
                              },
                              item.id
                            )
                          }
                          className="add_menu_itm_btn"
                        >
                          Add Item
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              {/* <div className="extra_menu_item">
                <div
                  className="menu_img"
                  style={{
                    backgroundImage:
                      "url('https://www.washingtonpost.com/wp-apps/imrs.php?src=https://arc-anglerfish-washpost-prod-washpost.s3.amazonaws.com/public/2KL6JYQYH4I6REYMIWBYVUGXPI.jpg')",
                  }}
                ></div>
                <div>
                  <h5>Manchurian Chinese style deep fried</h5>
                  <div className="price_menu">
                    <b>$35.55</b>
                    <div className="qty_menu">
                      <button className="add_qty less">
                        <span className="material-icons">remove</span>
                      </button>
                      <input type="number" min="1" />
                      <button className="add_qty">
                        <span className="material-icons">add</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </Drawer>
        </div>
        <Cart
          handleOk={handleOk}
          handleCancel={handleCancel}
          isModalVisible={isModalVisible}
        />
      </div>
      <Footer />
    </React.Fragment>
  );
}
