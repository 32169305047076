import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import "./shop.scss";
import "./grid.scss";
import "./slick.scss";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import {addToCart, axios_get, BANNER, product_orginal_name, PRODUCT_VARIANT_API} from "../../utils/service";
import { get } from "lodash";
import { navigate } from "@reach/router";
import image from "quill-image-uploader/src/blots/image";
import Cart from "../../components/Cart/cart";
import Rating from "react-rating";
export default function Shop() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [banners, setBanners] = useState([]);
  const [RelatedProducts, SetRelatedProducts] = useState([]);
  const [OtherProducts, SetOtherProducts] = useState([]);
  const [bannerLoading, setBannerLoading] = useState(true);
  const productCategoryList = useSelector(
    (state) => state.appInfo.productCategoryList
  );
  const cart = useSelector((state) => state.cart.cart);
  useEffect(() => {
    getBanners();
    getRelatedProduct();
    getOtherProduct();
  }, []);

  const getBanners = () => {
    setBannerLoading(true);
    axios_get(BANNER)
      .then((res) => {
        console.log("banners", res);
        setBanners(get(res, "data.data", []));
        setBannerLoading(false);
      })
      .catch((err) => setBannerLoading(false));
  };
  const getRelatedProduct = () => {
    axios_get(PRODUCT_VARIANT_API +'?brand=1').then((res) => {
      SetRelatedProducts(get(res, "data.data", []));
    });
  };

  const getOtherProduct = () => {
    axios_get(PRODUCT_VARIANT_API+'?non_bukk=true').then((res) => {
      SetOtherProducts(get(res, "data.data", []));
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const relatedSetting = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  };
  return (
    <div>
      <Header />
      <div className="min_height shop">
        <div className="container">
          <div className="search_cont shop_search">
            <div className="search">
              <input
                onFocus={() => navigate("/shop/search")}
                type="search"
                placeholder="Search..."
              />
              <button onClick={() => navigate("/shop/search")} type="button">
                <span className="material-icons">search</span>
              </button>
            </div>
            <button className="cart_header" onClick={showModal}>
              <span className="material-icons">shopping_cart</span>
              <span className="cart_count">
                <span>{get(cart, "cart_products.length", 0)}</span>
              </span>
            </button>
          </div>
          <div className="shop_offers_header">
            <div className="shop_sidebar">
              {productCategoryList && productCategoryList.length > 0 && (
                <React.Fragment>
                  <h3>Categories</h3>
                  <ul>
                    {productCategoryList.map((item) => (
                      <li key={item.id}>
                        <a href={"/shop/search?category=" + item.id}>
                          <div
                            className="category_img"
                            style={{
                              backgroundImage: item.image
                                ? `url(${item.image})`
                                : "url(https://n3.sdlcdn.com/imgs/d/h/3/Discount_Right_icon-f5105.png)",
                            }}
                          ></div>
                          {item.category}
                        </a>
                      </li>
                    ))}
                  </ul>
                </React.Fragment>
              )}

              {/*
                                <h3>Categories</h3>
                                <ul>
                                    <li>
                                        <a href="/">
                                            <div className="category_img" style={{backgroundImage:"url(https://n3.sdlcdn.com/imgs/d/h/3/Discount_Right_icon-f5105.png)"}}></div>
                                            All Offers
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <div className="category_img" style={{backgroundImage:"url(https://n2.sdlcdn.com/imgs/d/2/c/ComputerGaming-a02cf.png)"}}></div>
                                            Pasteries
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <div className="category_img" style={{backgroundImage:"url(https://n3.sdlcdn.com/imgs/d/h/3/Discount_Right_icon-f5105.png)"}}></div>
                                            Cake and Donuts
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/">
                                            <div className="category_img" style={{backgroundImage:"url(https://n2.sdlcdn.com/imgs/d/2/c/ComputerGaming-a02cf.png)"}}></div>
                                            Biscuits and rusks
                                        </a>
                                    </li>
                                </ul>
                                */}
              {/*
              <h4>Other categories</h4>
              <ul>
                <li>
                  <a href="/">All Offers</a>
                </li>
                <li>
                  <a href="/">Pasteries</a>
                </li>
                <li>
                  <a href="/">Cake and Donuts</a>
                </li>
              </ul>
              */}
            </div>
            <div className="shop_slider">
              {bannerLoading ? (
                <p>Loading...</p>
              ) : (
                <Slider {...settings}>
                  {/*
                                    <a className="each_slide"  href="/">
                                        <div className="slide_image_cont">
                                            <img src="/slider1.png"/>
                                        </div>
                                    </a>
                                    <a className="each_slide"  href="/">
                                        <div className="slide_image_cont">
                                            <img src="/slider2.png"/>
                                        </div>
                                    </a>
                                    <a className="each_slide"  href="/">
                                        <div className="slide_image_cont">
                                            <img src="/slider1.png"/>
                                        </div>
                                    </a>
                                    <a className="each_slide"  href="/">
                                        <div className="slide_image_cont">
                                            <img src="/slider2.png"/>
                                        </div>
                                    </a>
                                    */}
                  {banners.length ? (
                    banners.map((item) => (
                      <a
                        className="each_slide"
                        href={"/shop/banner/" + item.id}
                      >
                        <div className="slide_image_cont">
                          <img src={item.image} />
                        </div>
                      </a>
                    ))
                  ) : (
                    <a className="each_slide" href="/">
                      <div className="slide_image_cont">
                        <img src={"/slider1.png"} />
                      </div>
                    </a>
                  )}
                </Slider>
              )}
            </div>
          </div>
          <div className="related_products">
            <h2>Related Products</h2>
            <Slider {...relatedSetting}>
              {RelatedProducts.map((item) => (
                <div key={item.id}
                     onClick={() => navigate("/product/" + item.id)}
                     className="product_col card"
                >
                  <img src={item?.cover_image?.image} />
                  <div className="product_desc">
                    <h5>{item.product.name} {product_orginal_name(item.product.original_name)}</h5>
                    <span className="offer"></span>
                    <div className="star_rating">
                          <Rating
                            className="rating_system"
                            emptySymbol={
                              <span className="material-icons">star</span>
                            }
                            fullSymbol={
                              <span className="material-icons active">
                                star
                              </span>
                            }
                            initialRating={item.product.rating || 0}
                            readonly
                          />
                        </div>
                    <div className="price_desc">
                          {item.price !== item.price_after_discount ? (
                            <p className="price_strike">
                              <span>${item.price}</span>$
                              {item.price_after_discount}
                            </p>
                          ) : (
                            <p className="price_strike">${item.price}</p>
                          )}
                          { item.is_added_in_shop ? (<button disabled={true}>
                            Go to cart
                            <span className="material-icons">
                              add_shopping_cart
                            </span>
                          </button>):
                             (<button
                            onClick={(e) => {
                              e.stopPropagation();
                              addToCart(item.id);
                            }}
                          >
                            Add to cart
                            <span className="material-icons">
                              add_shopping_cart
                            </span>
                          </button>)
                         }
                      </div>
                  </div>
                </div>
              ))}
              {/*
                            <div className="product_col card">
                                <img src="https://n1.sdlcdn.com/imgs/i/z/e/large/Bentag-Vegetable-Fruit-Clever-Cutter-SDL841893040-1-ea5e3.jpg" />
                                <div className="product_desc">
                                    <h5>Maxbell USB Rechargeable Electronic Flameless Lighter</h5>
                                    <span className="offer"></span>
                                    <div className="star_rating">
                                        <span className="material-icons active">star</span>
                                        <span className="material-icons active">star</span>
                                        <span className="material-icons active">star</span>
                                        <span className="material-icons">star</span>
                                        <span className="material-icons">star</span>
                                    </div>
                                    <div className="price_desc">
                                        <p className="price_strike"><span>$455</span>$450</p>
                                        <button>Add to cart<span className="material-icons">add_shopping_cart</span></button>
                                    </div>
                                </div>
                            </div>
                            <div className="product_col card">
                                <img src="https://n1.sdlcdn.com/imgs/i/z/e/large/Bentag-Vegetable-Fruit-Clever-Cutter-SDL841893040-1-ea5e3.jpg" />
                                <div className="product_desc">
                                    <h5>Maxbell USB Rechargeable Electronic Flameless Lighter</h5>
                                    <span className="offer"></span>
                                    <div className="star_rating">
                                        <span className="material-icons active">star</span>
                                        <span className="material-icons active">star</span>
                                        <span className="material-icons active">star</span>
                                        <span className="material-icons">star</span>
                                        <span className="material-icons">star</span>
                                    </div>
                                    <div className="price_desc">
                                        <p className="price_strike"><span>$455</span>$450</p>
                                        <button>Add to cart<span className="material-icons">add_shopping_cart</span></button>
                                    </div>
                                </div>
                            </div>
                            <div className="product_col card">
                                <img src="https://n1.sdlcdn.com/imgs/i/z/e/large/Bentag-Vegetable-Fruit-Clever-Cutter-SDL841893040-1-ea5e3.jpg" />
                                <div className="product_desc">
                                    <h5>Maxbell USB Rechargeable Electronic Flameless Lighter</h5>
                                    <span className="offer"></span>
                                    <div className="star_rating">
                                        <span className="material-icons active">star</span>
                                        <span className="material-icons active">star</span>
                                        <span className="material-icons active">star</span>
                                        <span className="material-icons">star</span>
                                        <span className="material-icons">star</span>
                                    </div>
                                    <div className="price_desc">
                                        <p className="price_strike"><span>$455</span>$450</p>
                                        <button>Add to cart<span className="material-icons">add_shopping_cart</span></button>
                                    </div>
                                </div>
                            </div>
                            <div className="product_col card">
                                <img src="https://n1.sdlcdn.com/imgs/i/z/e/large/Bentag-Vegetable-Fruit-Clever-Cutter-SDL841893040-1-ea5e3.jpg" />
                                <div className="product_desc">
                                    <h5>Maxbell USB Rechargeable Electronic Flameless Lighter</h5>
                                    <span className="offer"></span>
                                    <div className="star_rating">
                                        <span className="material-icons active">star</span>
                                        <span className="material-icons active">star</span>
                                        <span className="material-icons active">star</span>
                                        <span className="material-icons">star</span>
                                        <span className="material-icons">star</span>
                                    </div>
                                    <div className="price_desc">
                                        <p className="price_strike"><span>$455</span>$450</p>
                                        <button>Add to cart<span className="material-icons">add_shopping_cart</span></button>
                                    </div>
                                </div>
                            </div>
                            <div className="product_col card">
                                <img src="https://n1.sdlcdn.com/imgs/i/z/e/large/Bentag-Vegetable-Fruit-Clever-Cutter-SDL841893040-1-ea5e3.jpg" />
                                <div className="product_desc">
                                    <h5>Maxbell USB Rechargeable Electronic Flameless Lighter</h5>
                                    <span className="offer"></span>
                                    <div className="star_rating">
                                        <span className="material-icons active">star</span>
                                        <span className="material-icons active">star</span>
                                        <span className="material-icons active">star</span>
                                        <span className="material-icons">star</span>
                                        <span className="material-icons">star</span>
                                    </div>
                                    <div className="price_desc">
                                        <p className="price_strike"><span>$455</span>$450</p>
                                        <button>Add to cart<span className="material-icons">add_shopping_cart</span></button>
                                    </div>
                                </div>
                            </div>
                            <div className="product_col card">
                                <img src="https://n1.sdlcdn.com/imgs/i/z/e/large/Bentag-Vegetable-Fruit-Clever-Cutter-SDL841893040-1-ea5e3.jpg" />
                                <div className="product_desc">
                                    <h5>Maxbell USB Rechargeable Electronic Flameless Lighter</h5>
                                    <span className="offer"></span>
                                    <div className="star_rating">
                                        <span className="material-icons active">star</span>
                                        <span className="material-icons active">star</span>
                                        <span className="material-icons active">star</span>
                                        <span className="material-icons">star</span>
                                        <span className="material-icons">star</span>
                                    </div>
                                    <div className="price_desc">
                                        <p className="price_strike"><span>$455</span>$450</p>
                                        <button>Add to cart<span className="material-icons">add_shopping_cart</span></button>
                                    </div>
                                </div>
                            </div>
                            <div className="product_col card">
                                <img src="https://n1.sdlcdn.com/imgs/i/z/e/large/Bentag-Vegetable-Fruit-Clever-Cutter-SDL841893040-1-ea5e3.jpg" />
                                <div className="product_desc">
                                    <h5>Maxbell USB Rechargeable Electronic Flameless Lighter</h5>
                                    <span className="offer"></span>
                                    <div className="star_rating">
                                        <span className="material-icons active">star</span>
                                        <span className="material-icons active">star</span>
                                        <span className="material-icons active">star</span>
                                        <span className="material-icons">star</span>
                                        <span className="material-icons">star</span>
                                    </div>
                                    <div className="price_desc">
                                        <p className="price_strike"><span>$455</span>$450</p>
                                        <button>Add to cart<span className="material-icons">add_shopping_cart</span></button>
                                    </div>
                                </div>
                            </div>
                            */}
            </Slider>
          </div>
          <h2>Browse Other Products</h2>
          <div className="product_grid">
            {OtherProducts.map((item) => (
                <div key={item.id}
                     onClick={() => navigate("/product/" + item.id)}
                     className="product_col card"
                >
                  <img src={item?.cover_image?.image} />
                  <div className="product_desc">
                    <h5>{item.product.name} {product_orginal_name(item.product.original_name)}</h5>
                    <span className="offer"></span>
                    <div className="star_rating">
                          <Rating
                            className="rating_system"
                            emptySymbol={
                              <span className="material-icons">star</span>
                            }
                            fullSymbol={
                              <span className="material-icons active">
                                star
                              </span>
                            }
                            initialRating={item.product.rating || 0}
                            readonly
                          />
                      </div>
                    <div className="price_desc">
                          {item.price !== item.price_after_discount ? (
                            <p className="price_strike">
                              <span>${item.price}</span>$
                              {item.price_after_discount}
                            </p>
                          ) : (
                            <p className="price_strike">${item.price}</p>
                          )}
                         { item.is_added_in_shop ? (<button disabled={true}>
                            Go to cart
                            <span className="material-icons">
                              add_shopping_cart
                            </span>
                          </button>):
                             (<button
                            onClick={(e) => {
                              e.stopPropagation();
                              addToCart(item.id);
                            }}
                          >
                            Add to cart
                            <span className="material-icons">
                              add_shopping_cart
                            </span>
                          </button>)
                         }
                        </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <button onClick={showModal} className="cart_display">
          <span className="material-icons">shopping_cart</span>
          <span className="cart_count">
            <span>{get(cart, "cart_products.length", 0)}</span>
          </span>
        </button>
        <Cart
          handleOk={handleOk}
          handleCancel={handleCancel}
          isModalVisible={isModalVisible}
        />
      </div>
      <Footer />
    </div>
  );
}
