import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { updateCart, updateCateringCart } from "../store/cart/cart";
import { updateCurrentUser } from "../store/user_store/user_store";
import { get } from "lodash";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { store } from "../store/store";
import { DeviceUUID } from "device-uuid";

toast.configure();

const API_BASE_URL = "https://api.bukk.com.au";
// const API_BASE_URL = "http://127.0.0.1:8000";

export const CLIENT_ID = "qbBGRzkQNX3Dt3yW7jtP1DFd48cI1nDG5Teax6kn";
export const CLIENT_SECRET =
  "QgVkVmZd6PRb8AjeIsddFaLJcHiE7gWryOT2onvhWmk2Vic0xcxYHfiOPh2fVHisS3qNAzTEx1J7AUHfLX11xnYoHZLQtcxG6FBtiCXPI4dUkfVib1yQMKBvWeGb75YE";
export const LOGIN_API_URL = API_BASE_URL + "/api/v1/login/";
export const PRIVACY_POLICY_URL = API_BASE_URL + "/api/v1/privacy-and-policy/";
export const TERMS_AND_CONDITIONS =
  API_BASE_URL + "/api/v1/term-and-condition/";
export const ABOUT_US = API_BASE_URL + "/api/v1/about-us/";
export const CHANGE_PASSWORD = API_BASE_URL + "/api/v1/change-password/";
export const SIGNUP_API_URL = API_BASE_URL + "/api/v1/user/";
export const SEND_EMAIL_API_URL = API_BASE_URL + "/api/v1/email-link/";
export const CONTACT_DETAILS = API_BASE_URL + "/api/v1/company-contact/";
export const CATERING = API_BASE_URL + "/api/v1/catering";
export const CONTACT_US = API_BASE_URL + "/api/v1/contact-us/";
export const RECIPE_INGREDIENT_BRAND_LIST =
  API_BASE_URL + "/api/v1/recipe/ingredient/brand/";
export const RECIPE_INGREDIENT_NAME_LIST =
  API_BASE_URL + "/api/v1/recipe/ingredient/name/";
export const RECIPE_INGREDIENT_UNIT_LIST =
  API_BASE_URL + "/api/v1/recipe/ingredient/unit/";
export const RECIPE_ORIGIN_LIST = API_BASE_URL + "/api/v1/recipe/origin/";
export const RECIPE_TYPE_LIST = API_BASE_URL + "/api/v1/recipe/type/";
export const RECIPE_CATEGORY_LIST = API_BASE_URL + "/api/v1/recipe-category/";
export const RECIPE_FOOD_PREFERENCE_LIST =
  API_BASE_URL + "/api/v1/recipe/food-preference/";
export const ADD_RECIPE = API_BASE_URL + "/api/v1/recipe/";
export const GET_RECIPE = API_BASE_URL + "/api/v1/recipe/";
export const UPDATE_USER_URL =
  API_BASE_URL + "/api/v1/user/" + Cookies.get("user_id");
export const GET_USER_URL =
  API_BASE_URL + "/api/v1/user/" + Cookies.get("user_id");
export const POST_RECIPE_FILE = API_BASE_URL + "/api/v1/recipe/file/";
export const DELETE_RECIPE_FILE = API_BASE_URL + `/api/v1/recipe/file`;
export const VERIFY_FORGOT_API_URL =
  API_BASE_URL + "/api/v1/forgot-password-verify/";
export const GET_RECIPE_FEED = API_BASE_URL + "/api/v1/recipe/feed/";
export const GET_RECIPE_LIKED = API_BASE_URL + "/api/v1/recipe/liked/";
export const GET_RECIPE_OWN = API_BASE_URL + "/api/v1/recipe/own/";
export const GET_RECIPE_DETAIL = API_BASE_URL + "/api/v1/recipe/";
export const LIKE_UNLIKE_RECIPE = API_BASE_URL + "/api/v1/recipe/like-unlike/";
export const RECIPE_COMMENT = API_BASE_URL + "/api/v1/recipe/comment/";
export const RATING = API_BASE_URL + "/api/v1/recipe/rating/";
export const GET_USER = API_BASE_URL + "/api/v1/user/";
export const GET_USER_USERNAME = API_BASE_URL + "/api/v1/user-detail/";
export const FOLLOWER_LIST = API_BASE_URL + "/api/v1/followers/";
export const FOLLOW_TOGGLE = API_BASE_URL + "/api/v1/follow-unfollow/";
export const FOLLOWING_LIST = API_BASE_URL + "/api/v1/followings/";
export const HOME = API_BASE_URL + "/api/v1/home/";
export const SUBSCRIBE = API_BASE_URL + "/api/v1/email-subscription/";
export const COOKING_LEVEL = API_BASE_URL + "/api/v1/recipe/cooking-level/";
export const EMAIL_CONFIRM = API_BASE_URL + "/api/v1/verify-account/";
export const EMAIL_CHANGE_CONFIRM =
  API_BASE_URL + "/api/v1/verify-email-change//";
export const BANNER = API_BASE_URL + "/api/v1/shop/banner/";
export const PRODUCT_CATEGORY = API_BASE_URL + "/api/v1/product/category/";
export const PRODUCT_VARIANT_API = API_BASE_URL + "/api/v1/product/variant/";
export const ADD_TO_CART = API_BASE_URL + "/api/v1/cart/item/";
export const CART = API_BASE_URL + "/api/v1/cart/";
export const RELATED_PRODUCT =
  API_BASE_URL + "/api/v1/product/related-products/";
export const ADDRESS = API_BASE_URL + "/api/v1/address/";
export const POST_CODE = API_BASE_URL + "/api/v1/post-code/";
export const ORDER = API_BASE_URL + "/api/v1/order/";
export const CATERING_CART_ITEM = API_BASE_URL + "/api/v1/catering-cart/item/";
export const CATERING_CART = API_BASE_URL + "/api/v1/catering-cart/";
export const CreditPoints = API_BASE_URL + "/api/v1/credit-points/";
export const CurrentUserDetailApi =
  API_BASE_URL + "/api/v1/current-user-detail/";
export const PRODUCT_REVIEW = API_BASE_URL + "/api/v1/product/review/";

export const STRIPE_PUBLIC_KEY =
  "pk_test_51JcgfNFXMJCPBF01YewUtJAJGDcjJxQIOGob1K2omz1JXtDl2Imk2GKbdytoOuEgmtRW45SfUzbl5J0Vxd2KxPEj00c2mvHGUR";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

var device_uuid = new DeviceUUID().get() + makeid(10);

if (!Cookies.get("device_uuid")) {
  Cookies.set("device_uuid", device_uuid);
}

export function axios_get(url, need_auth = false) {
  let headers = {};
  if (need_auth || Cookies.get("token")) {
    let token = Cookies.get("token");
    if (token) {
      headers = { Authorization: "Bearer " + token };
    }
  }
  if (Cookies.get("device_uuid")) {
    headers["Device-Uuid"] = Cookies.get("device_uuid");
  }

  return axios
    .get(url, { headers: headers })
    .then((response) => {
      // handle success
      // console.log("get res", response);
      if (!response) return {};
      return response;
    })
    .catch((error) => {
      // handle error
      if (!error || !error.response) return;
      const response_errors = error.response.data.status.detail;
      console.log(response_errors, "error");
      for (let err in response_errors) {
        for (let item of response_errors[err]) {
          toast.error(err + ":" + item);
        }
      } // handle error
    });
}

export function axios_post(url, data, need_auth = true) {
  let headers = {};
  if (need_auth) {
    let token = Cookies.get("token");
    if (token) {
      headers = { Authorization: "Bearer " + token };
    }
  }
  if (Cookies.get("device_uuid")) {
    headers["Device-Uuid"] = Cookies.get("device_uuid");
  }
  const res_data = axios
    .post(url, data, { headers: headers })
    .then((response) => {
      if (response && response.data && response.data.status.message) {
        toast.success(response.data.status.message);
      }
      return response;
    })
    .catch((error) => {
      if (!error || !error.response) return;
      const response_errors = error.response.data.status.detail;
      console.log(response_errors, "error");
      for (let err in response_errors) {
        for (let item of response_errors[err]) {
          toast.error(err + ":" + item);
        }
      } // handle error

      // console.log(error.data.status.detail)
      // const errors = detail.client_id[0]
      // console.log(error.response.data.status.detail.client_id[0], "demfjj");
      // toast.error(error.response.data.status.detail.client_id[0]);
    });
  return res_data;
}
export function axios_delete(url, need_auth = true) {
  let headers = {};
  if (need_auth) {
    let token = Cookies.get("token");
    if (token) {
      headers = { Authorization: "Bearer " + token };
    }
  }

  if (Cookies.get("device_uuid")) {
    headers["Device-Uuid"] = Cookies.get("device_uuid");
  }

  const res_data = axios
    .delete(url, { headers: headers })
    .then((response) => {
      if (response && response.data && response.data.status.message) {
        toast.success(response.data.status.message);
      }
      return response;
    })
    .catch((error) => {
      if (!error || !error.response) return;
      const response_errors = error.response.data.status.detail;
      console.log(response_errors, "error");
      for (let err in response_errors) {
        for (let item of response_errors[err]) {
          toast.error(err + ":" + item);
        }
      } // handle error

      // console.log(error.data.status.detail)
      // const errors = detail.client_id[0]
      // console.log(error.response.data.status.detail.client_id[0], "demfjj");
      // toast.error(error.response.data.status.detail.client_id[0]);
    });
  return res_data;
}
export function axios_put(url, data, need_auth = true, multipart = false) {
  let headers = {};
  if (need_auth) {
    let token = Cookies.get("token");
    if (token) {
      headers = { Authorization: "Bearer " + token };
    }
    if (multipart) {
      headers["Content-Type"] = "multipart/form-data";
    }
  }

  if (Cookies.get("device_uuid")) {
    headers["Device-Uuid"] = Cookies.get("device_uuid");
  }

  const res_data = axios
    .put(url, data, { headers: headers })
    .then((response) => {
      if (response && response.data && response.data.status.message) {
        toast.success(response.data.status.message);
      }
      return response;
    })
    .catch((error) => {
      if (!error || !error.response) return;
      const response_errors = error.response.data.status.detail;
      console.log(response_errors, "error");
      for (let err in response_errors) {
        for (let item of response_errors[err]) {
          toast.error(err + ":" + item);
        }
      } // handle error

      // console.log(error.data.status.detail)
      // const errors = detail.client_id[0]
      // console.log(error.response.data.status.detail.client_id[0], "demfjj");
      // toast.error(error.response.data.status.detail.client_id[0]);
    });
  return res_data;
}

export function custom_error_message(msg) {
  if (msg) {
    toast.error(msg);
  }
}

export function checkLogin(msg) {
  if (!Cookies.get("user_id")) {
    toast.error("Please login " + (msg || "to perform this action"));
    return true;
  }
}

export const addToCart = (productId) => {
  // if (checkLogin()) return;
  let data = {
    product_variant: productId,
  };
  axios_post(ADD_TO_CART, data).then((res) => {
    store.dispatch(updateCart(get(res, "data.data"), {}));
    return res;
  });
};

export const getCart = () => {
  // if (!Cookies.get("user_id")) return;
  // checkLogin();
  axios_get(CART, true).then((res) => {
    store.dispatch(updateCart(get(res, "data.data"), {}));
    return res;
  });
};

export const getCurrentUser = () => {
  if (!Cookies.get("user_id")) return;
  checkLogin();
  axios_get(CurrentUserDetailApi, true).then((res) => {
    store.dispatch(updateCurrentUser(get(res, "data.data"), {}));
    return res;
  });
};

getCurrentUser();

export const updateCartItem = (id, quantity) => {
  if (checkLogin()) return;
  let data = { quantity: parseInt(quantity) };
  axios_put(CART + "item/" + id + "/", data).then((res) => {
    store.dispatch(updateCart(get(res, "data.data", {})));
  });
};

export const deleteCartItem = (id) => {
  if (checkLogin()) return;
  axios_delete(CART + "item/" + id + "/").then((res) => {
    store.dispatch(updateCart(get(res, "data.data", {})));
  });
};

export const addToCateringCart = (
  catering_set,
  catering,
  cb,
  product_variant
) => {
  if (checkLogin()) return;
  let data = {
    catering,
  };
  if (catering_set) {
    data.catering_set = catering_set;
  }
  if (product_variant) {
    data.product_variant = product_variant;
  }
  return axios_post(CATERING_CART_ITEM, data).then((res) => {
    store.dispatch(updateCateringCart(get(res, "data.data"), {}));
    if (cb) cb();
    return res;
  });
};

export const getCateringCart = () => {
  if (!Cookies.get("user_id")) return;
  checkLogin();
  axios_get(CATERING_CART, true).then((res) => {
    store.dispatch(updateCateringCart(get(res, "data.data"), {}));
    return res;
  });
};

export const updateCateringCartItem = (id, quantity) => {
  if (checkLogin()) return;
  let data = { quantity: parseInt(quantity) };
  axios_put(CATERING_CART_ITEM + id + "/", data).then((res) => {
    store.dispatch(updateCateringCart(get(res, "data.data", {})));
  });
};

export const deleteCateringCartItem = (id) => {
  if (checkLogin()) return;
  axios_delete(CATERING_CART_ITEM + id + "/").then((res) => {
    store.dispatch(updateCateringCart(get(res, "data.data", {})));
  });
};

getCart();
getCateringCart();

export const calculate_delivery_date = (day = 2) => {
  const start_date = moment().add(day, "days").format("Do MMM, ddd");
  const end_date = moment()
    .add(day + 3, "days")
    .format("Do MMM, ddd");
  return start_date + " to " + end_date;
};

export const product_orginal_name = (original_name) => {
  if (original_name) {
    return `(${original_name})`;
  } else {
    return "";
  }
};
