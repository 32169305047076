import Footer from "../../components/Footer/footer";
import Header from "../../components/Header/header";

export default function PageNotFound() {
    return (
        <div>
        <Header />
        <div className="min_page_static vertical_align exception">
                <div>
                    <h1>404</h1>
                    <h2>Page Not Found</h2>
                    <p>Either resource does not exist or has been permanently moved by admin. <a href="/contact-us">Contact</a> admin for more info</p>
                </div>
        </div>
        <Footer />
        </div>
    )
}



