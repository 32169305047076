import React,{useState} from "react";
import {axios_post, CLIENT_ID, CLIENT_SECRET, SIGNUP_API_URL} from "../../utils/service";
import Cookies from "js-cookie";
import {navigate} from "@reach/router";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import "./signup.scss"

export default function SignUpPage(){
    const [UserData, SetData] = useState({first_name:"", last_name:"", email: "", password:"", confirm_password:"",  client_id: CLIENT_ID, client_secret: CLIENT_SECRET})

    const onChangeInput = (e)=>{
        const {name, value} = e.target;

        SetData(prevState =>({
            ...prevState,
            [name]:value
        }))
    }

    const signupSubmit = (e) => {
        e.preventDefault();
        const res = axios_post(SIGNUP_API_URL, UserData, false);
        res.then( (response) => {
            if (response.data){
                // Cookies.set('user_id', response.data.data.id);
                // Cookies.set('token', response.data.token.access_token);
                // Cookies.set('refresh_token', response.data.token.refresh_token);
                navigate('/login')
            }
        })
    }


    return <div>
         <Header />
         <div className="minimum_height_cont">
            <div className="page_bg" style={{backgroundImage:"url('/subscribe-bg.jpg')"}}>
                <h1>Sign Up</h1>
            </div>
            <div className="container">
                <div className="page_card large">
                    <div className="half_card signup_page">
                        <h2>Why should you <span>sign up</span></h2>
                        <ul className="features">
                            <li>
                                <img alt="subscribe icon" src="/subs.png"/>
                                <div>
                                    <h3>Subscribe</h3>
                                    <p>Subscribe and get recipe from your Favourite cuisine in your inbox</p>
                                </div>
                            </li>
                            <li>
                                <img alt="contribution list" src="/cut.png"/>
                                <div>
                                    <h3>View and Contribute</h3>
                                    <p>You can contribute and add your own recipes</p>
                                </div>
                            </li>
                            <li>
                                <img alt="food menu" src="/menu.png"/>
                                <div>
                                    <h3>Favorite list</h3>
                                    <p>Keep your favourite recipes at one place</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="half_card">
                        <form onSubmit={signupSubmit}>
                            <div className="form_group">
                                <label htmlFor="">First name</label>
                                <input type="text" name="first_name" onChange={onChangeInput} required/>
                            </div>
                            <div className="form_group">
                                <label htmlFor="">Last name</label>
                                <input type="text" name="last_name" onChange={onChangeInput} required/>
                            </div>
                            <div className="form_group">
                                <label htmlFor="">Email</label>
                                <input type="email" name="email" onChange={onChangeInput} required/>
                            </div>
                            <div className="form_group">
                                <label htmlFor="">Password</label>
                                <input type="password" name="password" onChange={onChangeInput} required/>
                            </div>
                            <div className="form_group">
                                <label htmlFor="">Confirm password</label>
                                <input type="password" name="confirm_password" onChange={onChangeInput} required/>
                            </div>
                            <div>
                                <span className="custom_checkbox">
                                    <input id="accept_terms" type="checkbox" />
                                    <label htmlFor="accept_terms">I accept <a href="/">Terms &amp; Conditions</a> &amp; <a href="/">Privacy Policy</a></label>
                                </span>
                                
                            </div>
                            <button type="submit">Submit</button>
                            <div className="signup_social">
                                or
                                <div>
                                    <button><img alt="facebook icon" src="/facebook.png"/></button>
                                    <button><img alt="twitter icon" src="/twittr.png"/></button>
                                    <button><img alt="google plus icon" src="/google.png"/></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
         </div>
        <Footer />

    </div>
}