import React, { useEffect } from "react";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import {axios_post, EMAIL_CONFIRM} from "../../utils/service";
import {navigate} from "@reach/router";


export default function EmailConfirm(props){

    useEffect(()=>{
        const data = {token: props.token, email: props.email}
        axios_post(EMAIL_CONFIRM, data, false).then((res) => {
            navigate('/login')
      });
    },[])


    return (
        <div>
            <Header />
                <div  style={{height: '500px'}}>
                    <h1 style={{textAlign: 'center', fontSize:'40px'}}>Email confirming.....</h1>
                </div>
            <Footer />
        </div>
    )


}