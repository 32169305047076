import React, { useEffect, useState } from "react";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import { axios_get, ABOUT_US } from "../../utils/service";
import AboutUsContent from "../../components/AboutUsContent/about_us_content";
import { useLocation } from "@reach/router";
import "./content.scss";

export default function AboutUs() {
  const location = useLocation();
  const hideHeaderFooter = location.pathname.includes("mobile");
  const [aboutData, setAboutData] = useState("");
  useEffect(() => {
    getAbout();
  }, []);

  const getAbout = () => {
    axios_get(ABOUT_US).then((res) => {
      setAboutData(res.data.data.about_us);
    });
  };
  return (
    <div>
      {!hideHeaderFooter && <Header />}
      <AboutUsContent aboutData={aboutData} />
      {!hideHeaderFooter && <Footer />}
    </div>
  );
}
