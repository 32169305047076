import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current_user: {}
};



export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateCurrentUser: (state, { payload }) => {
      state.current_user = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateCurrentUser } = userSlice.actions;
export default userSlice.reducer;