export default function PrivacyPolicyContent(data) {
  return (
    <div className="static_pages">
      <div className="container">
        <div className="static_page_content">
          <h1>Privacy policy</h1>
          <div className="editor_content" dangerouslySetInnerHTML={{ __html: data.privacyPolicyData }}></div>
        </div>
      </div>
    </div>
  );
}
