import React, { useState } from "react";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import SearchResultContent from "../../components/SearchResultContent/search_result_content";
import "./search_result.scss"

export default function SearchResult() {
  const [resultData] = useState("");
  return (
    <div>
      <Header />
      <SearchResultContent privacyPolicyData={resultData} />
      <Footer />
    </div>
  );
}
