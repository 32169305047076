import React, { useEffect, useState } from "react";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import AdminLayout from "../../components/AdminLayout/admin_layout";
import "./addresses.scss";
import { get } from "lodash";
import { axios_get, axios_delete, ADDRESS } from "../../utils/service";
import { navigate } from "@reach/router";

export default function Addresses() {
  const [addresses, setAddresses] = useState([]);
  useEffect(() => {
    getAddresses();
  }, []);
  const getAddresses = () => {
    axios_get(ADDRESS, true).then((res) => {
      console.log("address", res);
      setAddresses(get(res, "data.data", []) || []);
    });
  };
  const deleteAdress = (id) => {
    axios_delete(ADDRESS + id + "/").then((res) => {
      getAddresses();
    });
  };
  return (
    <div>
      <Header />
      <AdminLayout>
        <div className="addresses">
          <h1>Manage Addresses <button className="add_recipe_btn" onClick={() => navigate("/add-address")}><span className="material-icons">add</span>Add Address</button></h1>
          {addresses.map((item) => (
            <div key={item.id} className="address_list">
              {item.is_default && (
                <span className="default_billing">Default</span>
              )}

              <div className="address_head">
                <span>
                  {item.name} &nbsp; &nbsp; {item.mobile_number}
                </span>
              </div>
              <p>
                {`${item.address_line1}, ${item.address_line2 ? item.address_line2+',': ''} ${item.city}, ${item.post_code.post_code}, ${item.state}, ${item.country}`}
              </p>
              <p>
                {`${item.delivery_instruction}`}
              </p>
              <div className="address_foot">
                <a href={"/edit-address/" + item.id}>EDIT</a>
                <button onClick={() => deleteAdress(item.id)}>DELETE</button>
              </div>
            </div>
          ))}
        </div>
      </AdminLayout>
      <Footer />
    </div>
  );
}
