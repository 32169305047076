import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import {
  updateCartItem,
  deleteCartItem,
  updateCateringCartItem,
  deleteCateringCartItem,
} from '../../utils/service';
import { toast } from 'react-toastify';

let timer;

const CartRow = ({
  item,
  catering,
  delivery_day,
  hideRemove,
  hideMinOrder,
  onlyQuantity,
}) => {
  const [quantity, setQuantity] = useState(item.quantity);
  useEffect(() => {
    setQuantity(item.quantity);
  }, [item.quantity]);

  const handleQuantityUpdate = (value) => {
    setQuantity(
      value < get(item, 'product_variant.minimum_order', 1)
        ? get(item, 'product_variant.minimum_order')
        : value
    );
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (value < get(item, 'product_variant.minimum_order', 1)) {
        setQuantity(get(item, 'product_variant.minimum_order', 1));
        catering
          ? updateCateringCartItem(
              item.id,
              get(item, 'product_variant.minimum_order', 1)
            )
          : updateCartItem(
              item.id,
              get(item, 'product_variant.minimum_order', 1) || 1
            );
        toast.error(
          get(item, 'product_variant.product.name') +
            "'s minimum quantity must be " +
            get(item, 'product_variant.minimum_order')
        );
        return;
      }

      catering
        ? updateCateringCartItem(item.id, value)
        : updateCartItem(item.id, value);
    }, 1500);
  };

  return (
    <div className="product_cart_row clearfix" style={{ marginTop: '10px' }}>
      <div className="col-xs-8">
        <div className="p_img">
          <img
            src={get(
              item,
              'product_variant.cover_image.image',
              'https://n1.sdlcdn.com/imgs/j/o/r/130x152/Veirdo-100-Percent-Cotton-Green-SDL302182620-1-1ba35.jpeg'
            )}
          />
          <div>
            <a>{get(item, 'product_variant.product.name', '')}</a>
            <div>
              {!hideMinOrder && (
                <button>
                  min order - {get(item, 'product_variant.minimum_order')}
                </button>
              )}
              {!hideRemove && (
                <button
                  onClick={() =>
                    catering
                      ? deleteCateringCartItem(item.id)
                      : deleteCartItem(item.id)
                  }
                >
                  <span className="material-icons">close</span>REMOVE
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-xs-2">${get(item, 'product_variant.price')}</div>
      <div className="col-xs-4">
        {onlyQuantity ? (
          <span>Qty - {quantity}</span>
        ) : (
          <div className="qty_menu" style={{ marginLeft: '0px' }}>
            <button
              onClick={() => handleQuantityUpdate(quantity - 1)}
              className="add_qty less"
            >
              <span className="material-icons">remove</span>
            </button>
            <input type="number" min="1" value={quantity} />
            <button
              onClick={() => handleQuantityUpdate(quantity + 1)}
              className="add_qty"
            >
              <span className="material-icons">add</span>
            </button>
          </div>
        )}
      </div>
      <div className="col-xs-6">
        <div>Standard Delivery By</div> {delivery_day}
      </div>
      <div className="col-xs-4">${item.item_total}</div>
    </div>
  );
};

export default CartRow;
