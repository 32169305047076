import React, { useEffect, useState } from "react";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import {axios_get, ABOUT_US, BANNER, product_orginal_name, addToCart} from "../../utils/service";
import "./content.scss";
import { get } from "lodash";
import {navigate} from "@reach/router";
import Rating from "react-rating";

export default function Banner(props) {
  const [data, setData] = useState("");
  const [selected_products, SetSelected_products] = useState([]);

  useEffect(() => {
    getBannerDetails();
  }, []);

  const getBannerDetails = () => {
    axios_get(BANNER + props.id).then((res) => {
      console.log("banner", res);
      setData(get(res, "data.data", {}) || {});
      SetSelected_products(get(res, "data.data.selected_products", []) || []);
    });
  };
  return (
    <div>
      <Header />
      <div className="static_pages">
        <div className="container">
          <div className="static_page_content">
            <h1>{get(data, "name")}</h1>
            {get(data, "image", null) && <img src={get(data, "image")} />}

            <div
              className="editor_content"
              dangerouslySetInnerHTML={{
                __html: get(data, "description", "") || "",
              }}
            ></div>
          </div>
          <br/>
          { selected_products.length > 0 && (
              <h2>Products</h2>
          )}

          <br/>
          <div className="product_grid">
            {selected_products.map((item) => (
                <div key={item.id}
                     onClick={() => navigate("/product/" + item.id)}
                     className="product_col card"
                >
                  <img src={item?.cover_image?.image} />
                  <div className="product_desc">
                    <h5>{item.product.name} {product_orginal_name(item.product.original_name)}</h5>
                    <span className="offer"></span>
                    <div className="star_rating">
                          <Rating
                            className="rating_system"
                            emptySymbol={
                              <span className="material-icons">star</span>
                            }
                            fullSymbol={
                              <span className="material-icons active">
                                star
                              </span>
                            }
                            initialRating={item.product.rating || 0}
                            readonly
                          />
                      </div>
                    <div className="price_desc">
                          {item.price !== item.price_after_discount ? (
                            <p className="price_strike">
                              <span>${item.price}</span>$
                              {item.price_after_discount}
                            </p>
                          ) : (
                            <p className="price_strike">${item.price}</p>
                          )}
                         { item.is_added_in_shop ? (<button disabled={true}>
                            Go to cart
                            <span className="material-icons">
                              add_shopping_cart
                            </span>
                          </button>):
                             (<button
                            onClick={(e) => {
                              e.stopPropagation();
                              addToCart(item.id);
                            }}
                          >
                            Add to cart
                            <span className="material-icons">
                              add_shopping_cart
                            </span>
                          </button>)
                         }
                        </div>
                  </div>
                </div>
              ))}
          </div>


        </div>
      </div>
      <Footer />
    </div>
  );
}
