import React, { useEffect, useState } from "react";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import PrivacyPolicyContent from "../../components/PrivacyPolicyCotent/privacy_policy_content";
import { axios_get, PRIVACY_POLICY_URL } from "../../utils/service";
import { useLocation } from "@reach/router";

export default function PrivacyPolicy() {
  const location = useLocation();
  const hideHeaderFooter = location.pathname.includes("mobile");
  const [privacyData, setPrivacyData] = useState("");
  useEffect(() => {
    getPrivacyPolicyContent();
  }, []);
  const getPrivacyPolicyContent = () => {
    axios_get(PRIVACY_POLICY_URL).then((res) => {
      setPrivacyData(res.data.data.privacy_and_policy);
    });
  };
  return (
    <div>
      {!hideHeaderFooter && <Header />}
      <PrivacyPolicyContent privacyPolicyData={privacyData} />
      {!hideHeaderFooter && <Footer />}
    </div>
  );
}
