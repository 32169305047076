import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import AdminLayout from "../../components/AdminLayout/admin_layout";
import { axios_put, CHANGE_PASSWORD } from "../../utils/service";
import { useState } from "react";

export default function ChangePassword() {
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const validateFields = () => {
    if (!oldPassword || !password || !confirmPassword) {
      alert("Please fill fields");
      return false;
    } else {
      if (password === confirmPassword) {
        return true;
      } else {
        alert("Passwords dont match");
        return false;
      }
    }
  };
  const OnSubmitSendLink = (e) => {
    if (!validateFields()) return;
    e.preventDefault();
    const res = axios_put(
      CHANGE_PASSWORD,
      {
        old_password: oldPassword,
        new_password: password,
        confirm_password: confirmPassword,
      },
      true
    ).then((res) => {
      console.log("re", res);
      if (res.data.status.code === 200) {
        setOldPassword("");
        setPassword("");
        setConfirmPassword("");
      }
    });
  };

  return (
    <div>
      <Header />
      <AdminLayout>
        <div className="profile_management">
          <div className="profile_setting_head">
            <h1 style={{ marginBottom: "20px" }}>Change Password</h1>
            <div className="page_card">
              <form onSubmit={OnSubmitSendLink}>
                <div className="form_group">
                  <label htmlFor="">Old Password</label>
                  <input
                    value={oldPassword}
                    type="password"
                    required
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                </div>
                <div className="form_group">
                  <label htmlFor="">New Password</label>
                  <input
                    value={password}
                    type="password"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="form_group">
                  <label htmlFor="">Confirm Password</label>
                  <input
                    value={confirmPassword}
                    type="password"
                    required
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </AdminLayout>
      <Footer />
    </div>
  );
}
