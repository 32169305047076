import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import {axios_post, SEND_EMAIL_API_URL} from "../../utils/service";
import {useState} from "react";

export default function ForgotPassword(){
    const [email, SetEmail] = useState("");

    const OnSubmitSendLink  = (e)=> {
        e.preventDefault();
        const res = axios_post(SEND_EMAIL_API_URL,{email}, false);
    }

    return <div>
        <Header />
        <div className="minimum_height_cont">
            <div className="page_bg" style={{backgroundImage:"url('/subscribe-bg.jpg')"}}>
                <h1>Forgot Password</h1>
            </div>
            <div className="page_card">
                <form onSubmit={OnSubmitSendLink}>
                    <div className="form_group">
                        <label htmlFor="">Email</label>
                        <input type="email" required onChange={(e)=> SetEmail(e.target.value)}/>
                        <p style={{color:"#4b4b4b",textAlign:"left",paddingTop:"10px"}}>We’ll send password reset instructions on this email</p>
                    </div>
                    <button type="submit">Send link</button>
                </form>
            </div>
        </div>
        <Footer />
    </div>
}