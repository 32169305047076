import React from "react";
import { Modal } from "antd";
import { useSelector } from "react-redux";
import { get } from "lodash";
import CartRow from "./cartRow";
import { calculate_delivery_date } from "../../utils/service";
import moment from "moment";
import CateringSetCard from "../CateringSetCard/cateringSetCard";
import CartList from "./cartList";
const Cart = ({ isModalVisible, handleOk, handleCancel }) => {
  let cart = useSelector((state) => get(state, "cart.cart", {}));
  let cateringCart = useSelector((state) =>
    get(state, "cart.cateringCart", {})
  );

  let delivery_fee = get(cart, "delivery_fee", 0);
  delivery_fee = delivery_fee ? delivery_fee : "Free";

  const delivery_date = calculate_delivery_date(
    get(cart, "address.post_code.delivery_in_days", 2)
  );

  return (
    <>
      <Modal
        className="shopping_cart_modal"
        title="Shopping Cart"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <CartList />
      </Modal>
      {isModalVisible ? (
        <div className="cart_footer">
          <div className="container_cart">
            <div className="secure_info">
              <div>
                <h4>Delivery and payment options can be selected later</h4>
                <p>
                  <span className="material-icons">security</span> Safe and
                  Secure Payments
                </p>
                <p>
                  <span className="material-icons">credit_card</span> 100%
                  Payment Protection, Easy Returns Policy
                </p>
              </div>
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td>Sub Total</td>
                      <td className="value">
                        $
                        {Number(
                          Number(
                            cateringCart?.total_price
                              ? cateringCart?.total_price
                              : 0
                          ) + Number(cart.total_price)
                        ).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Delivery charge</td>
                      <td className="value">
                        <span>{delivery_fee === "Free" || !delivery_fee ? 'Free' : `$ ${ Number(delivery_fee).toFixed(2) }`}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {(get(cart, "cart_products", []).length > 0 ||
                get(cateringCart, "catering_cart_products", []).length > 0) && (
                <div>
                  <a href="/checkout">
                    <button>
                      Proceed to pay $
                      {Number(
                        Number(
                          cateringCart?.total_price
                            ? cateringCart?.total_price
                            : 0
                        ) +
                          Number(cart.total_price) +
                          Number(delivery_fee === "Free" ? 0 : delivery_fee)
                      ).toFixed(2)}
                    </button>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Cart;
