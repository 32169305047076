import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: 0,
  contact: {},
  recipeCategories: [],
  recipeTypes: [],
  recipeFoodPreferences: [],
  originCountryList: [],
  productCategoryList: [],
  // search_text: ""
};

export const appInfoSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
    updateContactInfo: (state, { payload }) => {
      state.contact = payload;
    },
    updateRecipeCategories: (state, { payload }) => {
      state.recipeCategories = payload;
    },
    updateRecipeTypes: (state, { payload }) => {
      state.recipeTypes = payload;
    },
    updateRecipeFoodPreferences: (state, { payload }) => {
      state.recipeFoodPreferences = payload;
    },
    updateOriginCountryList: (state, { payload }) => {
      state.originCountryList = payload;
    },
    updateProductCategoryList: (state, { payload }) => {
      state.productCategoryList = payload;
    },

    // UpdateSearchText : (state, {payload}) =>{
    //   state.search_text = payload;
    // }
  },
});

// Action creators are generated for each case reducer function
export const {
  increment,
  decrement,
  incrementByAmount,
  updateContactInfo,
  updateRecipeCategories,
  updateRecipeTypes,
  updateRecipeFoodPreferences,
  updateOriginCountryList,
  updateProductCategoryList,
  // UpdateSearchText
} = appInfoSlice.actions;
export default appInfoSlice.reducer;
