import React, {useState} from "react";
import {axios_post, CLIENT_ID, CLIENT_SECRET, LOGIN_API_URL} from "../../utils/service";
import Cookies from "js-cookie";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import {navigate} from "@reach/router";
import "./login.scss"
import {get} from "lodash/object";
export default function LoginPage(){

    const [loginData, SetData] = useState({email: "", password:"", client_id: CLIENT_ID, client_secret: CLIENT_SECRET, user_type:'customer'})

    const onChangeInput = (e)=>{
        const {name, value} = e.target;

        SetData(prevState =>({
            ...prevState,
            [name]:value
        }))
    }

    const loginSubmit = (e) => {
        e.preventDefault();
        const res = axios_post(LOGIN_API_URL,loginData, false);
        res.then( (response) => {
            if (response && get(response, 'data.data.user_type') ==='customer'){
                Cookies.set('user_id', response.data.data.id);
                Cookies.set('token', response.data.token.access_token);
                Cookies.set('refresh_token', response.data.token.refresh_token);
                navigate('/dashboard')
            }
            else {
                alert("only customer can login")
            }
        })
    }
    return <div>
        <Header />
        <div className="minimum_height_cont">
            <div className="page_bg" style={{backgroundImage:"url('/subscribe-bg.jpg')"}}>
                <h1>Login</h1>
            </div>
            <div className="page_card">
                <form onSubmit={loginSubmit}>
                    <div className="form_group">
                        <label htmlFor="">Email</label>
                        <input type="email" name="email" onChange={onChangeInput} required/>
                    </div>
                    <div className="form_group">
                        <label htmlFor="">Password</label>
                        <input type="password" name="password" onChange={onChangeInput} required/>
                    </div>
                    <div className="forgot_link">
                        <a href="/forgot-password">Forgot password ?</a>
                    </div>
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
        <Footer />
    </div>
}