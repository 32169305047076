import React, { useState, useEffect } from "react";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import AdminLayout from "../../components/AdminLayout/admin_layout";
import { Checkbox } from "antd";
import AsyncSelect from "react-select/async";
import { get } from "lodash";
import {
  axios_get,
  axios_post,
  POST_CODE,
  ADDRESS,
  axios_put,
} from "../../utils/service";
import "./edit_address.scss";
import { navigate } from "@reach/router";
import Form from "./form";
export default function EditAddress(props) {
  return (
    <div>
      <Header />
      <AdminLayout>
        <Form title={props.id ? "Edit Address" : "Add Address"} {...props} />
      </AdminLayout>
      <Footer />
    </div>
  );
}
