import React, { useState } from 'react';
import { get } from 'lodash';
import {
  updateCateringCartItem,
  deleteCateringCartItem,
} from '../../utils/service';
import moment from 'moment';
let timer = null;
const CateringSetCard = ({
  day,
  set,
  addToCateringCart,
  hideAddToCart,
  showRemove,
  date,
  total,
  showCartControls,
  orderDetails,
  feedback,
  onFeedbackClick,
}) => {
  const [quantity, setQuantity] = useState(get(set, 'is_added.quantity', 1));
  const handleQuantityUpdate = (value) => {
    setQuantity(value >= 1 ? value : 1);
    clearTimeout(timer);
    timer = setTimeout(() => {
      updateCateringCartItem(set.is_added.id, value >= 1 ? value : 1);
    }, 1000);
  };
  const renderQuantity = () => {
    return (
      <div className="qty_menu" style={{ marginLeft: '0px' }}>
        <button
          className="add_qty less"
          onClick={() => handleQuantityUpdate(quantity - 1)}
        >
          <span className="material-icons">remove</span>
        </button>
        <input type="number" min="1" value={quantity} />
        <button
          className="add_qty"
          onClick={() => handleQuantityUpdate(quantity + 1)}
        >
          <span className="material-icons">add</span>
        </button>
      </div>
    );
  };
  return (
    <div key={set.id}>
      {showCartControls ? (
        <div
          className="product_cart_row clearfix"
          style={{ marginBottom: '5px', marginTop: '25px' }}
        >
          <div className="col-xs-8">
            <div className="p_img">
              <div>
                <div>
                  <button
                    onClick={() => deleteCateringCartItem(set.is_added.id)}
                  >
                    <span className="material-icons">close</span>REMOVE
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-2">${get(set, 'price_set', '')}</div>
          <div className="col-xs-4">{renderQuantity()}</div>
          <div className="col-xs-6">
            Catering Date {moment(date).format('DD MMM, ddd')}
          </div>
          <div className="col-xs-4">${total}</div>
        </div>
      ) : orderDetails ? (
        <div
          className="product_cart_row clearfix"
          style={{ marginBottom: '5px', marginTop: '25px' }}
        >
          <div className="col-xs-5">
            unit price - ${get(set, 'price_set', '')}
          </div>
          <div className="col-xs-5">Qty - {quantity}</div>
          <div className="col-xs-5">
            Catering Date {moment(date).format('DD MMM, ddd')}
          </div>
          <div className="col-xs-5">$Subtotal - {total}</div>
        </div>
      ) : (
        <div className="week_head" style={{ marginTop: '10px' }}>
          <span>
            Catering set price: <strong>${get(set, 'price_set', '')}</strong>
          </span>
          {get(set, 'is_added.quantity', 0) ? (
            <span>{renderQuantity()}</span>
          ) : (
            <span>
              {!hideAddToCart && (
                <div className="cuisine_right">
                  <button
                    onClick={() =>
                      addToCateringCart(get(set, 'id', ''), day.id)
                    }
                    className="add_btn_crt less"
                  >
                    add to cart
                  </button>
                </div>
              )}
            </span>
          )}
        </div>
      )}

      <div className="meal_body">
        {get(set, 'product_catering', []).length ? (
          get(set, 'product_catering', []).map((product) => (
            <div key={product.id} className="meal">
              <div className="cuisine_left">
                <div
                  className="cuisine_img"
                  style={{
                    backgroundImage: `url(${get(
                      product,
                      'product_variants.cover_image.image'
                    )})`,
                  }}
                ></div>
                <div className="meal_info">
                  <h3>{get(product, 'product_variants.product.name', '')}</h3>
                  <div>
                    {/*<b>${get(product, "product_variants.price", "")}</b>*/}
                    <span>
                      Offer applied for catering set @$
                      {Number(
                        get(set, 'price_set', 0) /
                          (get(set, 'product_catering', []).length || 1)
                      ).toFixed(2)}
                      &nbsp;({get(product, 'quantity', '')} box per set).
                      Additional portion @ $
                      {Number(get(product, 'price', 0)) > 0
                        ? get(product, 'price', 0)
                        : get(product, 'product_variants.price', '')}
                    </span>
                    {feedback && (
                      <div className="order_options">
                        <div>
                          <button
                            onClick={() =>
                              onFeedbackClick(product.product_variants)
                            }
                          >
                            Leave feedback
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="cuisine_right">
                      {product.product_variants.is_added ? (
                        "Added to cart"
                      ) : (
                        <button
                          onClick={() =>
                            addToCateringCart(get(set, "id", ""), day.id)
                          }
                          className="add_btn_crt less"
                        >
                          add to cart
                        </button>
                      )}
                    </div> */}
            </div>
          ))
        ) : (
          <div className="meal_body">
            <div className="empty_day">
              <img src="/food.svg" />
              <p>
                No catering service available for this day <br />
                {!get(day, 'catering_close', false) && 'Add items from menu'}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CateringSetCard;
