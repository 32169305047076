import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { get } from "lodash";
import { navigate } from "@reach/router";
export default function CateringOrderSummary({
  week1dates,
  week2dates,
  week3dates,
  week4dates,
}) {
  const cart = useSelector((state) => state.cart.cateringCart);
  const [dateMap, setDateMap] = useState({});
  useEffect(() => {
    buildDateMap();
  }, [cart]);
  const buildDateMap = () => {
    let _dateMap = {};
    let prods = get(cart, "catering_cart_products", []);
    prods.map((item) => {
      if (_dateMap[item.catering.date]) {
        _dateMap[item.catering.date].push(item);
      } else {
        _dateMap[item.catering.date] = [item];
      }
    });
    let data = {};
    Object.keys(_dateMap)
      .sort()
      .forEach((item) => (data[item] = _dateMap[item]));
    setDateMap(data);
  };
  return (
    <div className="order_summary">
      <h3>Order Summary</h3>
      <div className="week_order_list">
        <div>
          <h4>Week 1</h4>
          {Object.keys(dateMap).filter((item) =>
            moment(item).isBetween(
              week1dates.start,
              week1dates.end,
              undefined,
              "[]"
            )
          ).length ? (
            Object.keys(dateMap)
              .filter((item) =>
                moment(item).isBetween(
                  week1dates.start,
                  week1dates.end,
                  undefined,
                  "[]"
                )
              )
              .map((item, i) => (
                <div key={i} className="line_item">
                  <div>{moment(item).format("dddd")}</div>
                  <div>
                    {dateMap[item].length} Items{" (Qty - "}
                    {
                      dateMap[item].reduce((a, b) => ({
                        quantity: a.quantity + b.quantity,
                      })).quantity
                    }
                    {")"}
                    <span>
                      $
                      {parseFloat(
                        dateMap[item].reduce((a, b) => ({
                          item_total:
                            parseFloat(a.item_total) + parseFloat(b.item_total),
                        })).item_total
                      ).toFixed(2)}
                    </span>
                  </div>
                </div>
              ))
          ) : (
            <p>no items for this week</p>
          )}
        </div>
        <div>
          <h4>Week 2</h4>
          {Object.keys(dateMap).filter((item) =>
            moment(item).isBetween(
              week2dates.start,
              week2dates.end,
              undefined,
              "[]"
            )
          ).length ? (
            Object.keys(dateMap)
              .filter((item) =>
                moment(item).isBetween(
                  week2dates.start,
                  week2dates.end,
                  undefined,
                  "[]"
                )
              )
              .map((item, i) => (
                <div key={i} className="line_item">
                  <div>{moment(item).format("dddd")}</div>
                  <div>
                    {dateMap[item].length} Items{" "}
                    <span>
                      $
                      {parseFloat(
                        dateMap[item].reduce((a, b) => ({
                          item_total:
                            parseFloat(a.item_total) + parseFloat(b.item_total),
                        })).item_total
                      ).toFixed(2)}
                    </span>
                  </div>
                </div>
              ))
          ) : (
            <p>no items for this week</p>
          )}
        </div>
        <div>
          <h4>Week 3</h4>
          {Object.keys(dateMap).filter((item) =>
            moment(item).isBetween(
              week3dates.start,
              week3dates.end,
              undefined,
              "[]"
            )
          ).length ? (
            Object.keys(dateMap)
              .filter((item) =>
                moment(item).isBetween(
                  week3dates.start,
                  week3dates.end,
                  undefined,
                  "[]"
                )
              )
              .map((item, i) => (
                <div key={i} className="line_item">
                  <div>{moment(item).format("dddd")}</div>
                  <div>
                    {dateMap[item].length} Items{" "}
                    <span>
                      $
                      {parseFloat(
                        dateMap[item].reduce((a, b) => ({
                          item_total:
                            parseFloat(a.item_total) + parseFloat(b.item_total),
                        })).item_total
                      ).toFixed(2)}
                    </span>
                  </div>
                </div>
              ))
          ) : (
            <p>no items for this week</p>
          )}
        </div>
        <div>
          <h4>Week 4</h4>
          {Object.keys(dateMap).filter((item) =>
            moment(item).isBetween(
              week4dates.start,
              week4dates.end,
              undefined,
              "[]"
            )
          ).length ? (
            Object.keys(dateMap)
              .filter((item) =>
                moment(item).isBetween(
                  week4dates.start,
                  week4dates.end,
                  undefined,
                  "[]"
                )
              )
              .map((item, i) => (
                <div key={i} className="line_item">
                  <div>{moment(item).format("dddd")}</div>
                  <div>
                    {dateMap[item].length} Items{" "}
                    <span>
                      $
                      {parseFloat(
                        dateMap[item].reduce((a, b) => ({
                          item_total:
                            parseFloat(a.item_total) + parseFloat(b.item_total),
                        })).item_total
                      ).toFixed(2)}
                    </span>
                  </div>
                </div>
              ))
          ) : (
            <p>no items for this week</p>
          )}
        </div>

        {cart.total_price && (
          <div className="summary_total">
            <p>
              Total <span>${cart.total_price}</span>
            </p>
            <button onClick={() => navigate("/checkout")}>Checkout</button>
          </div>
        )}
      </div>
    </div>
  );
}
