import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import AdminLayout from "../../components/AdminLayout/admin_layout";
import {
  axios_get,
  GET_RECIPE_FEED,
  axios_post,
  LIKE_UNLIKE_RECIPE,
  checkLogin,
} from "../../utils/service";
import React, { useEffect, useState } from "react";
import { navigate } from "@reach/router";
import Rating from "react-rating";
import { get } from "lodash";
import InfiniteScroll from "react-infinite-scroller";
import "./dashboard.scss";

export default function DashboardPage() {
  const [posts, setPosts] = useState([]);
  const [next, setNext] = useState("");
  useEffect(() => {
    getFeedRecipes();
  }, []);
  const getFeedRecipes = () => {
    setNext("");
    axios_get(next ? next : GET_RECIPE_FEED, true).then((res) => {
      console.log("feed recipe", res);
      setPosts((prev) => [...prev, ...get(res, "data.data", [])]);
      setNext(get(res, "data.next", ""));
    });
  };
  const toggleLikeRecipe = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    if (checkLogin("to like this recipe")) return;
    axios_post(LIKE_UNLIKE_RECIPE, { recipe: id }).then((res) => {
      let arr = posts.map((item) => {
        if (item.id === id) {
          item.is_liked = !item.is_liked;
        }
        return item;
      });
      console.log("lskdlsk", arr);
      setPosts(arr);
      console.log("res", res);
    });
  };
  return (
    <React.Fragment>
      <Header />
      <InfiniteScroll
        pageStart={0}
        loadMore={getFeedRecipes}
        hasMore={next ? true : false}
        loader={
          <div className="loader" key={0}>
            Loading ...
          </div>
        }
      >
        <AdminLayout>
          {posts.map((post) => (
            <div
              onClick={(e) => {
                e.preventDefault();

                navigate("/recipe/" + post.id);
              }}
              key={post.id}
              className="feed_list_item"
            >
              <div className="feed_header">
                <div className="post_info">
                  <div
                    className="author_img"
                    style={{
                      backgroundImage:
                        "url(" + post.created_by.profile_pic + ")",
                    }}
                  ></div>
                  <div className="author_info">
                    <p>
                      <strong>
                        {post.created_by.first_name +
                          " " +
                          post.created_by.last_name}
                      </strong>{" "}
                      {/* Posted recipe in <span>{post.category?.category}</span> */}
                    </p>
                    <time>Yesterday at 9:00 AM</time>
                  </div>
                </div>
                {/* <button
                className={
                  post.created_by.is_followed
                    ? "follow_btn active"
                    : "follow_btn"
                }
              >
                <span className="material-icons">
                  {post.created_by?.is_followed ? "done_all" : "rss_feed"}
                </span>
                {post.created_by?.is_followed ? "Unfollow" : "Follow"}
              </button> */}
              </div>
              <div className="feed_image">
                <img src={post.cover_image.file} alt="feed cover" />
              </div>
              <div className="feed_footer">
                <div className="post_ratings">
                  <span className="cat">{post.category.category}</span>
                  {/* <span className="star">
                  <span className="material-icons">star</span>
                  {post.rating}
                </span> */}
                  <span className="star">
                    <Rating
                      className="rating_system"
                      emptySymbol={
                        <span className="material-icons">star_border</span>
                      }
                      fullSymbol={<span className="material-icons">star</span>}
                      initialRating={post.rating || 0}
                      readonly
                    />
                  </span>
                </div>
                <h2>{post.title}</h2>
                <div
                  dangerouslySetInnerHTML={{ __html: post.description }}
                ></div>
                <div className="post_action_container">
                  <button
                    onClick={(e) => toggleLikeRecipe(e, post.id)}
                    className="post_action like_btn"
                  >
                    <span
                      className={
                        post.is_liked
                          ? "material-icons active"
                          : "material-icons"
                      }
                    >
                      {post.is_liked ? "favorite" : "favorite_border"}
                    </span>
                    Like
                  </button>
                  <button className="post_action comments_btn">
                    <span className="material-icons">
                      chat<span className="count">{post.comment_count}</span>
                    </span>
                    Comments
                  </button>
                </div>
              </div>
            </div>
          ))}
        </AdminLayout>
      </InfiniteScroll>
      <Footer />
    </React.Fragment>
  );
}
