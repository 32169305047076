import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { axios_get, GET_RECIPE } from "../../utils/service";
import qs from "query-string";
import Rating from "react-rating";
import InfiniteScroll from "react-infinite-scroller";
import { get } from "lodash";
// import {UpdateSearchText} from "../../store/appInfo/appInfo";

const marks = {
  15: "15 min",
  30: "30 min",
  45: "45 min",
  60: "1 hour",
  100: ">1 hour",
};
export default function SearchResultContent(props) {
  const recipeTypes = useSelector((state) => state.appInfo.recipeTypes);
  const [recipes, setRecipes] = useState([]);
  const [recipe_count, setRecipeCount] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [next, setNext] = useState("");

  const originCountryList = useSelector(
    (state) => state.appInfo.originCountryList
  );
  const foodPreferences = useSelector(
    (state) => state.appInfo.recipeFoodPreferences
  );

  const recipeCategories = useSelector(
    (state) => state.appInfo.recipeCategories
  );
  const queryString = require("query-string");
  const query_obj = queryString.parse(window.location.search);

  const [category_params, setCategoryParam] = useState(
    query_obj.category ? query_obj.category.split(",") : []
  );
  const [type_params, setTypeParam] = useState(
    query_obj.type ? query_obj.type.split(",") : []
  );
  const [food_params, setFoodParam] = useState(
    query_obj.food_preference ? query_obj.food_preference.split(",") : []
  );
  const [origin_params, setOriginParam] = useState(
    query_obj.origin ? query_obj.origin : ""
  );
  const [time_params, setTimeParam] = useState(
    query_obj.time ? query_obj.time : 0
  );
  const [ingredients_params, setIngredientParam] = useState([]);
  const [search_params, setSearchParam] = useState(
    query_obj.search ? query_obj.search : ""
  );
  const [search_text, SetSearchText] = useState(
    query_obj.search ? query_obj.search : ""
  );

  // const [type_params, setTypeParam] = useState([]);

  useEffect(() => {
    const params = make_params(search_params);
    getRecipes(params);
    // window.location.search = params;
    // window.history.replaceState({}, '', params);
    if (params) {
      window.history.replaceState({}, "", "?" + params);
    } else {
      var newURL = window.location.href.split("?")[0];
      window.history.replaceState({}, "", newURL);
    }
  }, [
    category_params,
    type_params,
    food_params,
    origin_params,
    time_params,
    ingredients_params,
    search_params,
  ]);

  const getRecipes = (params, isNext = false) => {
    setNext("");
    const query_params = params ? "?" + params : "";
    axios_get(isNext && next ? next : GET_RECIPE + query_params, false).then(
      (res) => {
        if (res?.data?.data) {
          setRecipes((prev) =>
            isNext
              ? [...prev, ...get(res, "data.data", [])]
              : get(res, "data.data")
          );
          setRecipeCount(res.data.count);
          setNext(get(res, "data.next", ""));
        }
      }
    );
  };

  const make_params = (search_text) => {
    let query = {};

    if (Array.isArray(category_params) && category_params.length) {
      query["category"] = category_params.join();
    }

    if (Array.isArray(type_params) && type_params.length) {
      query["type"] = type_params.join();
    }

    if (Array.isArray(food_params) && food_params.length) {
      query["food_preference"] = food_params.join();
    }

    if (Array.isArray(ingredients_params) && ingredients_params.length) {
      query["ingredients"] = ingredients_params.join();
    }
    if (search_text) {
      query["search"] = search_text;
    }

    if (origin_params) {
      query["origin"] = origin_params;
    }
    if (time_params) {
      query["time"] = time_params;
    }

    const searchString = qs.stringify(query);
    return searchString;
  };

  const get_checkbox_value = (id, type) => {
    if (type === "category") {
      return category_params.includes(id);
    }

    if (type === "type") {
      return type_params.includes(id);
    }

    if (type === "food") {
      return food_params.includes(id);
    }
  };

  const inputOnchange = (e, type) => {
    if (type === "category") {
      if (!category_params.includes(e.target.id) && e.target.checked) {
        setCategoryParam([...category_params, e.target.id]);
      } else if (!e.target.checked) {
        setCategoryParam(
          category_params.filter((item) => item !== e.target.id)
        );
      }
    }

    if (type === "type") {
      if (!type_params.includes(e.target.id) && e.target.checked) {
        setTypeParam([...type_params, e.target.id]);
      } else if (!e.target.checked) {
        setTypeParam(type_params.filter((item) => item !== e.target.id));
      }
    }

    if (type === "food") {
      if (!food_params.includes(e.target.id) && e.target.checked) {
        setFoodParam([...food_params, e.target.id]);
      } else if (!e.target.checked) {
        setFoodParam(food_params.filter((item) => item !== e.target.id));
      }
    }

    if (type === "ingredients") {
      if (!ingredients_params.includes(e.target.id) && e.target.checked) {
        setIngredientParam([...ingredients_params, e.target.id]);
      } else if (!e.target.checked) {
        setIngredientParam(
          ingredients_params.filter((item) => item !== e.target.id)
        );
      }
    }

    if (type === "time") {
      setTimeParam(e);
    }

    if (type === "origin") {
      setOriginParam(e.value);
    }
  };

  // const SearchingFunction = () => {
  //     dispatch(UpdateSearchText(search_text));
  //     if (window.location.pathname !== '/search'){
  //         navigate("/search?search="+search_text, { replace: true })
  //     }
  // }

  const onclickSearch = (e) => {
    e.preventDefault();
    setSearchParam(search_text);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      // SearchingFunction();
      setSearchParam(search_text);
    }
  };

  const onchangeSearch = (e) => {
    SetSearchText(e.target.value);
    if (e.target.value === "") {
      setSearchParam(e.target.value);
    }
  };

  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={() => getRecipes(null, true)}
      hasMore={next ? true : false}
      loader={
        <div className="loader" key={0}>
          Loading ...
        </div>
      }
    >
      <div style={{ backgroundColor: "#f4f4f4" }}>
        <div className="container">
          <div className="search_cont">
            <div className="search">
              <input
                type="search"
                placeholder="Search..."
                value={search_text}
                onKeyDown={handleKeyDown}
                onChange={onchangeSearch}
              />
              <button type="button">
                <span className="material-icons" onClick={onclickSearch}>
                  search
                </span>
              </button>
            </div>
          </div>
          <div className="search_results">
            <input id="mobile_filter" type="checkbox" hidden />
            <label className="mobile_show" htmlFor="mobile_filter"></label>
            <div className="filter">
              <h4>Filters</h4>

              <div className="filter_set">
                <h5>Category</h5>
                {recipeCategories.map((item) => (
                  <div key={item.id} className="custom_checkbox">
                    <input
                      id={item.id}
                      type="checkbox"
                      checked={get_checkbox_value(item.id, "category")}
                      onChange={(e) => inputOnchange(e, "category")}
                    />
                    <label htmlFor={item.id}>{item.category}</label>
                  </div>
                ))}
              </div>
              <div className="filter_set">
                <h5>Type</h5>
                {recipeTypes.map((item) => (
                  <div key={item.id} className="custom_checkbox">
                    <input
                      id={item.id}
                      type="checkbox"
                      checked={get_checkbox_value(item.id, "type")}
                      onChange={(e) => inputOnchange(e, "type")}
                    />
                    <label htmlFor={item.id}>{item.type}</label>
                  </div>
                ))}
              </div>
              <div className="filter_set">
                <h5>Food Preferences</h5>
                {foodPreferences.map((item) => (
                  <div key={item.id} className="custom_checkbox">
                    <input
                      id={item.id}
                      type="checkbox"
                      checked={get_checkbox_value(item.id, "food")}
                      onChange={(e) => inputOnchange(e, "food")}
                    />
                    <label htmlFor={item.id}>{item.food_preference}</label>
                  </div>
                ))}
              </div>
              <div className="filter_set">
                <h5>Country of Origin</h5>
                <Select
                  onChange={(e) => inputOnchange(e, "origin")}
                  value={origin_params}
                  options={originCountryList.map((item) => ({
                    value: item.id,
                    label: item.origin,
                  }))}
                />
              </div>
              <div className="filter_set">
                <h5>Preparation Time</h5>
                <Slider
                  min={0}
                  marks={marks}
                  step={null}
                  defaultValue={time_params}
                  onChange={(e) => inputOnchange(e, "time")}
                />
              </div>
              <div className="filter_set">
                <h5>Main Ingredients</h5>
                <input type="search" />
                <div className="custom_checkbox">
                  <input id="egg" type="checkbox" />
                  <label htmlFor="egg">Eggs</label>
                </div>
                <div className="custom_checkbox">
                  <input id="potato" type="checkbox" />
                  <label htmlFor="potato">Potato</label>
                </div>
                <div className="custom_checkbox">
                  <input id="tofu" type="checkbox" />
                  <label htmlFor="tofu">Tofu</label>
                </div>
                <div className="custom_checkbox">
                  <input id="chicken" type="checkbox" />
                  <label htmlFor="chicken">Chicken</label>
                </div>
              </div>
            </div>
            <div className="result_content">
              <h1>
                Search results ({recipe_count}){" "}
                <label htmlFor="mobile_filter" className="mobile_show">
                  <span className="material-icons">filter_list</span>
                </label>
              </h1>
              <div className="recipe_cards">
                {recipes.map((recipe) => (
                  <a href={"/recipe/" + recipe.id} className="recipe_card">
                    <div
                      className="recipe_image"
                      style={{
                        backgroundImage: `url('${recipe?.cover_image?.file}')`,
                      }}
                    >
                      <span
                        className={
                          recipe.is_liked
                            ? "material-icons active"
                            : "material-icons"
                        }
                      >
                        favorite
                      </span>
                    </div>
                    <div className="recipe_description">
                      <div className="recipe_cat">
                        <span>{recipe?.category?.category}</span>
                        {/* <div className="star_rating">
                          <span className="material-icons">star</span> {recipe.rating}
                        </div> */}
                        <div className="star_rating">
                          <Rating
                            className="rating_system"
                            emptySymbol={
                              <span className="material-icons">
                                star_border
                              </span>
                            }
                            fullSymbol={
                              <span className="material-icons">star</span>
                            }
                            initialRating={recipe.rating || 0}
                            readonly
                          />
                        </div>
                      </div>
                      <b>{recipe.title}</b>
                      {/*<span>Creator: {recipe.created_by.first_name} {recipe.created_by.last_name}</span>*/}
                    </div>
                  </a>
                ))}
                {/*
              <a href="/" className="recipe_card">
                <div
                  className="recipe_image"
                  style={{ backgroundImage: "url('/recipe_2.jpg')" }}
                >
                  <span className="material-icons active">favorite</span>
                </div>
                <div className="recipe_description">
                  <div className="recipe_cat">
                    <span>Breakfast</span>
                    <div className="star_rating">
                      <span className="material-icons">star</span> 4.5
                    </div>
                  </div>
                  <b>Chorizo &amp; mozzarella gnocchi bake</b>
                </div>
              </a>
              <a href="/" className="recipe_card">
                <div
                  className="recipe_image"
                  style={{ backgroundImage: "url('/recipe_1.jpg')" }}
                >
                  <span className="material-icons active">favorite</span>
                </div>
                <div className="recipe_description">
                  <div className="recipe_cat">
                    <span>Breakfast</span>
                    <div className="star_rating">
                      <span className="material-icons">star</span> 4.5
                    </div>
                  </div>
                  <b>Chorizo &amp; mozzarella gnocchi bake</b>
                </div>
              </a>
              <a href="/" className="recipe_card">
                <div
                  className="recipe_image"
                  style={{ backgroundImage: "url('/recipe_3.jpg')" }}
                >
                  <span className="material-icons">favorite_border</span>
                </div>
                <div className="recipe_description">
                  <div className="recipe_cat">
                    <span>Breakfast</span>
                    <div className="star_rating">
                      <span className="material-icons">star</span> 4.5
                    </div>
                  </div>
                  <b>Chorizo &amp; mozzarella gnocchi bake</b>
                </div>
              </a>
              <a href="/" className="recipe_card">
                <div
                  className="recipe_image"
                  style={{ backgroundImage: "url('/recipe_5.jpg')" }}
                >
                  <span className="material-icons">favorite_border</span>
                </div>
                <div className="recipe_description">
                  <div className="recipe_cat">
                    <span>Breakfast</span>
                    <div className="star_rating">
                      <span className="material-icons">star</span> 4.5
                    </div>
                  </div>
                  <b>Chorizo &amp; mozzarella gnocchi bake</b>
                </div>
              </a>
              */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </InfiniteScroll>
  );
}
