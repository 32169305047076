import "./main.scss"
import Router from "./router";
import { Provider } from "react-redux";
import { store } from "./store/store";
import "./antd.scss";

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <Router />
      </div>
    </Provider>
  );
}

export default App;
