import React, { useState, useEffect } from "react";
import {
  axios_get,
  HOME,
  axios_post,
  LIKE_UNLIKE_RECIPE,
  SUBSCRIBE,
  checkLogin,
} from "../../utils/service";
import "./home.scss";
import { get } from "lodash";
// import { navigate } from "@reach/router";
import Rating from "react-rating";

export default function HomeContent() {
  const [topList, setTopList] = useState([]);
  const [recentList, setRecentList] = useState([]);
  const [popularList, setPopularList] = useState([]);
  const [email, setEmail] = useState("");

  useEffect(() => {
    getHome();
  }, []);
  const getHome = () => {
    axios_get(HOME).then((res) => {
      console.log("rees", res);
      setTopList(get(res, "data.data.top_rated", []));
      setRecentList(get(res, "data.data.recently_created", []));
      setPopularList(get(res, "data.data.popular_category", []));
    });
  };
  const toggleLikeRecipe = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    if (checkLogin("to like this recipe")) return;
    axios_post(LIKE_UNLIKE_RECIPE, { recipe: id }).then((res) => {
      getHome();
    });
  };
  const susbcribe = (e) => {
    e.preventDefault();
    if (email) {
      axios_post(SUBSCRIBE, { email: email }, false).then((res) => {
        if (res?.status === 200) {
          setEmail("");
        }
      });
    }
  };
  return (
    <React.Fragment>
      <header>
        <div className="container">
          <div className="header_inner_container">
            <div className="intro">
              <h1>Explore Delicious Recipes</h1>
              <a href="/search">
                <button>Browse Recipes</button>
              </a>
            </div>
            <div>
              <img alt="recipes" src="/recipes.png" />
            </div>
          </div>
        </div>
      </header>

      <section className="related_recipes">
        <div className="container">
          {/* <ul>
            <li>
              <a href="/">Vegan</a>
            </li>
            <li>
              <a href="/">Non-Vegan</a>
            </li>
            <li>
              <a href="/">Vegetarian</a>
            </li>
            <li>
              <a href="/">Breakfast</a>
            </li>
            <li>
              <a href="/">Lunch</a>
            </li>
            <li>
              <a href="/">Dinner</a>
            </li>
            <li>
              <a href="/">Soups</a>
            </li>
            <li>
              <a href="/">Italian</a>
            </li>
            <li>
              <a href="/">Chinease</a>
            </li>
            <li>
              <a href="/">Drinks</a>
            </li>
            <li>
              <a href="/">Souces</a>
            </li>
            <li>
              <a href="/">Indian</a>
            </li>
          </ul> */}
          <h2>Top Rated Recipes</h2>
          <div className="recipe_cards">
            {topList.map((item) => (
              <a
                // onClick={(e) => {
                //   e.preventDefault();
                //   navigate("/recipe/" + item.id);
                // }}
                href={"/recipe/" + item.id}
                key={item.id}
                className="recipe_card"
              >
                <div
                  className="recipe_image"
                  style={{
                    backgroundImage: "url(" + item.cover_image.file + ")",
                  }}
                >
                  {/* <button
                      onClick={(e) => toggleLikeRecipe(e, post.id)}
                      className="post_action like_btn"
                    > */}
                  <span
                    onClick={(e) => toggleLikeRecipe(e, item.id)}
                    className={
                      item.is_liked ? "material-icons active" : "material-icons"
                    }
                  >
                    {item.is_liked ? "favorite" : "favorite_border"}
                  </span>
                  {/* Like
             </button> */}
                </div>
                <div className="recipe_description">
                  <div className="recipe_cat">
                    <span>{item.category.category}</span>
                    <div className="star_rating">
                      {/* <span className="material-icons">star</span> {item.rating} */}
                      <Rating
                        className="rating_system"
                        emptySymbol={
                          <span className="material-icons">star_border</span>
                        }
                        fullSymbol={
                          <span className="material-icons">star</span>
                        }
                        initialRating={item.rating || 0}
                        readonly
                      />
                    </div>
                  </div>
                  <b>{item.title}</b>
                </div>
              </a>
            ))}
          </div>
          <h2>Recently Added Recipes</h2>
          <div className="recipe_cards">
            {recentList.map((item, i) => (
              <a
                // onClick={(e) => {
                //   e.preventDefault();
                //   navigate("/recipe/" + item.id);
                // }}
                href={"/recipe/" + item.id}
                key={item.id}
                className="recipe_card"
              >
                <div
                  className="recipe_image"
                  style={{
                    backgroundImage: "url(" + item.cover_image.file + ")",
                  }}
                >
                  <span
                    onClick={(e) => toggleLikeRecipe(e, item.id)}
                    className={
                      item.is_liked ? "material-icons active" : "material-icons"
                    }
                  >
                    {item.is_liked ? "favorite" : "favorite_border"}
                  </span>
                </div>
                <div className="recipe_description">
                  <div className="recipe_cat">
                    <span>{item.category.category}</span>
                    {/* <div className="star_rating">
                      <span className="material-icons">star</span> {item.rating}
                    </div> */}
                    <div className="star_rating">
                      <Rating
                        className="rating_system"
                        emptySymbol={
                          <span className="material-icons">star_border</span>
                        }
                        fullSymbol={
                          <span className="material-icons">star</span>
                        }
                        initialRating={item.rating || 0}
                        readonly
                      />
                    </div>
                  </div>
                  <b>{item.title}</b>
                </div>
              </a>
            ))}
          </div>
        </div>
      </section>
      <section
        className="subscribe_bg"
        style={{ backgroundImage: "url('/subscribe-bg.jpg')" }}
      >
        <div className="container">
          <div className="subs_container">
            <img alt="food bowl" src="/bowl.png" />
            <div className="subscribe_right">
              <h2>
                Receive recipes in your inbox &amp; become{" "}
                <span>better at cooking</span>
              </h2>
              <form className="subscribe_form">
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                />
                <button onClick={susbcribe}>Subscribe</button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className="cuisine_categories">
        <div className="container">
          <h2>Browse popular categories</h2>
          <div className="category_list">
            {popularList.map((item) => (
              <a href="/#" key={item.id} className="category_item">
                <img
                  alt="breakfast in bowl"
                  src={item.image ? item.image : "/breakfast.png"}
                />
                <strong>{item.category}</strong>
              </a>
            ))}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
