import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Header from "../../components/Header/header";
import { Modal } from "antd";

import Footer from "../../components/Footer/footer";
import "./search_result.scss";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { navigate } from "@reach/router";
import {
  axios_get,
  GET_RECIPE, product_orginal_name,
  PRODUCT_VARIANT_API,
} from "../../utils/service";
import qs from "query-string";
import Rating from "react-rating";
import InfiniteScroll from "react-infinite-scroller";
import { get } from "lodash";
import { addToCart } from "../../utils/service";
import Cart from "../../components/Cart/cart";
// import {UpdateSearchText} from "../../store/appInfo/appInfo";

const marks = {
  15: "15 min",
  30: "30 min",
  45: "45 min",
  60: "1 hour",
  100: ">1 hour",
};
export default function SearchResultContent(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [recipes, setRecipes] = useState([]);
  const [recipe_count, setRecipeCount] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [next, setNext] = useState("");

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const recipeCategories = useSelector((state) =>
    state.appInfo.productCategoryList.map((item) => ({
      ...item,
      id: item.id.toString(),
    }))
  );
  const queryString = require("query-string");
  const query_obj = queryString.parse(window.location.search);

  const [category_params, setCategoryParam] = useState(
    query_obj.category ? query_obj.category.split(",") : []
  );
  const [type_params, setTypeParam] = useState(
    query_obj.type ? query_obj.type.split(",") : []
  );
  const [food_params, setFoodParam] = useState(
    query_obj.food_preference ? query_obj.food_preference.split(",") : []
  );
  const [origin_params, setOriginParam] = useState(
    query_obj.origin ? query_obj.origin : ""
  );
  const [time_params, setTimeParam] = useState(
    query_obj.time ? query_obj.time : 0
  );
  const [ingredients_params, setIngredientParam] = useState([]);
  const [search_params, setSearchParam] = useState(
    query_obj.search ? query_obj.search : ""
  );
  const [search_text, SetSearchText] = useState(
    query_obj.search ? query_obj.search : ""
  );
  const [availableInShopParams, setAvailableInShopParams] = useState(
    query_obj.available_in_shop || false
  );
  const [availableInCateringParams, setAvailableInCateringParams] = useState(
    query_obj.available_in_catering || false
  );

  // const [type_params, setTypeParam] = useState([]);

  useEffect(() => {
    const params = make_params(search_params);
    // getRecipes(params);
    getProducts(params);
    // window.location.search = params;
    // window.history.replaceState({}, '', params);
    if (params) {
      window.history.replaceState({}, "", "?" + params);
    } else {
      var newURL = window.location.href.split("?")[0];
      window.history.replaceState({}, "", newURL);
    }
  }, [
    category_params,
    type_params,
    food_params,
    origin_params,
    time_params,
    ingredients_params,
    search_params,
    availableInShopParams,
    availableInCateringParams,
  ]);
  let cart = useSelector((state) => get(state, "cart.cart", {}));
  const getRecipes = (params, isNext = false) => {
    setNext("");
    const query_params = params ? "?" + params : "";
    axios_get(isNext && next ? next : GET_RECIPE + query_params, false).then(
      (res) => {
        if (res?.data?.data) {
          setRecipes((prev) =>
            isNext
              ? [...prev, ...get(res, "data.data", [])]
              : get(res, "data.data")
          );
          setRecipeCount(res.data.count);
          setNext(get(res, "data.next", ""));
        }
      }
    );
  };
  const getProducts = (params, isNext = false) => {
    setNext("");
    const query_params = params ? "?" + params : "";
    axios_get(
      isNext && next ? next : PRODUCT_VARIANT_API + query_params,
      false
    ).then((res) => {
      console.log("pro res", res);
      if (res?.data?.data) {
        setRecipes((prev) =>
          isNext
            ? [...prev, ...get(res, "data.data", [])]
            : get(res, "data.data")
        );
        setRecipeCount(res.data.count);
        setNext(get(res, "data.next", ""));
      }
    });
  };
  const make_params = (search_text) => {
    let query = {};

    if (Array.isArray(category_params) && category_params.length) {
      query["category"] = category_params.join();
    }
    if (availableInShopParams) {
      query["available_in_shop"] = availableInShopParams;
    }
    if (availableInCateringParams) {
      query["available_in_catering"] = availableInCateringParams;
    }

    if (Array.isArray(type_params) && type_params.length) {
      query["type"] = type_params.join();
    }

    if (Array.isArray(food_params) && food_params.length) {
      query["food_preference"] = food_params.join();
    }

    if (Array.isArray(ingredients_params) && ingredients_params.length) {
      query["ingredients"] = ingredients_params.join();
    }
    if (search_text) {
      query["search"] = search_text;
    }

    if (origin_params) {
      query["origin"] = origin_params;
    }
    if (time_params) {
      query["time"] = time_params;
    }

    const searchString = qs.stringify(query);
    return searchString;
  };

  const get_checkbox_value = (id, type) => {
    if (type === "category") {
      return category_params.includes(id);
    }

    if (type === "type") {
      return type_params.includes(id);
    }

    if (type === "food") {
      return food_params.includes(id);
    }
  };

  const inputOnchange = (e, type) => {
    console.log("eeeee", e.target.checked, type);
    if (type === "category") {
      if (!category_params.includes(e.target.id) && e.target.checked) {
        setCategoryParam([...category_params, e.target.id]);
      } else if (!e.target.checked) {
        setCategoryParam(
          category_params.filter((item) => item !== e.target.id)
        );
      }
    }
    if (type == "available_in_shop") {
      setAvailableInShopParams(e.target.checked);
    }
    if (type == "available_in_catering") {
      setAvailableInCateringParams(e.target.checked);
    }

    if (type === "type") {
      if (!type_params.includes(e.target.id) && e.target.checked) {
        setTypeParam([...type_params, e.target.id]);
      } else if (!e.target.checked) {
        setTypeParam(type_params.filter((item) => item !== e.target.id));
      }
    }

    if (type === "food") {
      if (!food_params.includes(e.target.id) && e.target.checked) {
        setFoodParam([...food_params, e.target.id]);
      } else if (!e.target.checked) {
        setFoodParam(food_params.filter((item) => item !== e.target.id));
      }
    }

    if (type === "ingredients") {
      if (!ingredients_params.includes(e.target.id) && e.target.checked) {
        setIngredientParam([...ingredients_params, e.target.id]);
      } else if (!e.target.checked) {
        setIngredientParam(
          ingredients_params.filter((item) => item !== e.target.id)
        );
      }
    }

    if (type === "time") {
      setTimeParam(e);
    }

    if (type === "origin") {
      setOriginParam(e.value);
    }
  };

  // const SearchingFunction = () => {
  //     dispatch(UpdateSearchText(search_text));
  //     if (window.location.pathname !== '/search'){
  //         navigate("/search?search="+search_text, { replace: true })
  //     }
  // }

  const onclickSearch = (e) => {
    e.preventDefault();
    setSearchParam(search_text);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      // SearchingFunction();
      setSearchParam(search_text);
    }
  };

  const onchangeSearch = (e) => {
    SetSearchText(e.target.value);
    if (e.target.value === "") {
      setSearchParam(e.target.value);
    }
  };

  return (
    <div>
      <Header />
      <InfiniteScroll
        pageStart={0}
        loadMore={() => getRecipes(null, true)}
        hasMore={next ? true : false}
        loader={
          <div className="loader" key={0}>
            Loading ...
          </div>
        }
      >
        <div style={{ backgroundColor: "#f4f4f4" }}>
          <div className="container">
            <div className="search_cont shop_search">
              <div className="search">
                <input
                  type="search"
                  placeholder="Search..."
                  value={search_text}
                  onKeyDown={handleKeyDown}
                  onChange={onchangeSearch}
                />
                <button type="button">
                  <span className="material-icons" onClick={onclickSearch}>
                    search
                  </span>
                </button>
              </div>
              <button className="cart_header" onClick={showModal}>
                <span className="material-icons">shopping_cart</span>
                <span className="cart_count">
                  <span>{get(cart, "cart_products.length", 0)}</span>
                </span>
              </button>
            </div>
            <div className="search_results">
              <input id="mobile_filter" type="checkbox" hidden />
              <label className="mobile_show" htmlFor="mobile_filter"></label>
              <div className="filter">
                <h4>Filters</h4>

                <div className="filter_set">
                  <h5>Category</h5>
                  {recipeCategories.map((item) => (
                    <div key={item.id} className="custom_checkbox">
                      <input
                        id={item.id}
                        type="checkbox"
                        checked={get_checkbox_value(item.id, "category")}
                        onChange={(e) => inputOnchange(e, "category")}
                      />
                      <label htmlFor={item.id}>{item.category}</label>
                    </div>
                  ))}
                </div>
                <div className="filter_set">
                  <h5>Availability</h5>
                  <div key={"inshop"} className="custom_checkbox">
                    <input
                      type="checkbox"
                      id={"inshop"}
                      checked={availableInShopParams}
                      onChange={(e) => inputOnchange(e, "available_in_shop")}
                    />
                    <label htmlFor={"inshop"}>{"Available in Shop"}</label>
                  </div>
                  <div key={"incatering"} className="custom_checkbox">
                    <input
                      type="checkbox"
                      id={"incatering"}
                      checked={availableInCateringParams}
                      onChange={(e) =>
                        inputOnchange(e, "available_in_catering")
                      }
                    />
                    <label htmlFor={"incatering"}>
                      {"Available in Catering"}
                    </label>
                  </div>
                </div>
              </div>
              <div className="result_content">
                <h1>
                  Search results ({recipe_count}){" "}
                  <label htmlFor="mobile_filter" className="mobile_show">
                    <span className="material-icons">filter_list</span>
                  </label>
                </h1>
                <div className="related_products_search">
                  {recipes.map((item) => (
                    <div
                      key={item.id}
                      onClick={() => navigate("/product/" + item.id)}
                      className="product_col card"
                    >
                      <img
                        src={
                          item?.cover_image?.image ||
                          "https://n1.sdlcdn.com/imgs/i/z/e/large/Bentag-Vegetable-Fruit-Clever-Cutter-SDL841893040-1-ea5e3.jpg"
                        }
                      />
                      <div className="product_desc">
                        <h5>{item.product.name} {product_orginal_name(item.product.original_name)}</h5>
                        <span className="offer"></span>
                        <div className="star_rating">
                          <Rating
                            className="rating_system"
                            emptySymbol={
                              <span className="material-icons">star</span>
                            }
                            fullSymbol={
                              <span className="material-icons active">
                                star
                              </span>
                            }
                            initialRating={item.product.rating || 0}
                            readonly
                          />
                        </div>
                        <div className="price_desc">
                          {item.price !== item.price_after_discount ? (
                            <p className="price_strike">
                              <span>${item.price}</span>$
                              {item.price_after_discount}
                            </p>
                          ) : (
                            <p className="price_strike">${item.price}</p>
                          )}
                          {item.is_added_in_shop ? (
                            <button disabled={true}>
                              Go to cart
                              <span className="material-icons">
                                add_shopping_cart
                              </span>
                            </button>
                          ) : (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                addToCart(item.id);
                              }}
                            >
                              Add to cart
                              <span className="material-icons">
                                add_shopping_cart
                              </span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                  {/*
              <a href="/" className="recipe_card">
                <div
                  className="recipe_image"
                  style={{ backgroundImage: "url('/recipe_2.jpg')" }}
                >
                  <span className="material-icons active">favorite</span>
                </div>
                <div className="recipe_description">
                  <div className="recipe_cat">
                    <span>Breakfast</span>
                    <div className="star_rating">
                      <span className="material-icons">star</span> 4.5
                    </div>
                  </div>
                  <b>Chorizo &amp; mozzarella gnocchi bake</b>
                </div>
              </a>
              <a href="/" className="recipe_card">
                <div
                  className="recipe_image"
                  style={{ backgroundImage: "url('/recipe_1.jpg')" }}
                >
                  <span className="material-icons active">favorite</span>
                </div>
                <div className="recipe_description">
                  <div className="recipe_cat">
                    <span>Breakfast</span>
                    <div className="star_rating">
                      <span className="material-icons">star</span> 4.5
                    </div>
                  </div>
                  <b>Chorizo &amp; mozzarella gnocchi bake</b>
                </div>
              </a>
              <a href="/" className="recipe_card">
                <div
                  className="recipe_image"
                  style={{ backgroundImage: "url('/recipe_3.jpg')" }}
                >
                  <span className="material-icons">favorite_border</span>
                </div>
                <div className="recipe_description">
                  <div className="recipe_cat">
                    <span>Breakfast</span>
                    <div className="star_rating">
                      <span className="material-icons">star</span> 4.5
                    </div>
                  </div>
                  <b>Chorizo &amp; mozzarella gnocchi bake</b>
                </div>
              </a>
              <a href="/" className="recipe_card">
                <div
                  className="recipe_image"
                  style={{ backgroundImage: "url('/recipe_5.jpg')" }}
                >
                  <span className="material-icons">favorite_border</span>
                </div>
                <div className="recipe_description">
                  <div className="recipe_cat">
                    <span>Breakfast</span>
                    <div className="star_rating">
                      <span className="material-icons">star</span> 4.5
                    </div>
                  </div>
                  <b>Chorizo &amp; mozzarella gnocchi bake</b>
                </div>
              </a>
              */}
                </div>
              </div>
            </div>
          </div>
          <button onClick={showModal} className="cart_display">
            <span className="material-icons">shopping_cart</span>
            <span className="cart_count">
              <span>{get(cart, "cart_products.length", 0)}</span>
            </span>
          </button>
          <Cart
            handleOk={handleOk}
            handleCancel={handleCancel}
            isModalVisible={isModalVisible}
          />
        </div>
      </InfiniteScroll>
      <Footer />
    </div>
  );
}
