import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cart: {},
  cateringCart: {},
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    updateCart: (state, { payload }) => {
      state.cart = payload;
    },
    updateCateringCart: (state, { payload }) => {
      state.cateringCart = payload;
    },

    // UpdateSearchText : (state, {payload}) =>{
    //   state.search_text = payload;
    // }
  },
});

// Action creators are generated for each case reducer function
export const { updateCart, updateCateringCart } = cartSlice.actions;
export default cartSlice.reducer;
