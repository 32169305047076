import React from "react";

export default function RepeatCatering({ noData, tab, onPress }) {
  if (noData) {
    return (
      <>
        <div className="meal_body catering_nav">
          <div className="empty_day">
            <img src="/food.svg" />
            <p>
              No catering service available for this week <br />
              Add items from menu
            </p>
          </div>
        </div>
        <div className="week_button_nav">
          <button disabled={tab == 1} onClick={() => onPress(tab - 1)}>
            Previous Week
          </button>
          <button disabled={tab == 4} onClick={() => onPress(tab + 1)}>
            Next Week
          </button>
        </div>
      </>
    );
  }
  return (
    <div className="catering_nav">
      <span className="custom_checkbox">
        <input id="repeat_days" type="checkbox" />
        <label htmlFor="repeat_days">Repeat catering days for next week</label>
      </span>
      <div className="week_button_nav">
        <button disabled={tab == 1} onClick={() => onPress(tab - 1)}>
          Previous Week
        </button>
        <button disabled={tab == 4} onClick={() => onPress(tab + 1)}>
          Next Week
        </button>
      </div>
    </div>
  );
}
