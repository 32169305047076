import React, { useState, useEffect } from 'react';
import Header from '../../components/Header/header';
import Footer from '../../components/Footer/footer';
import AdminLayout from '../../components/AdminLayout/admin_layout';
import {
  axios_get,
  ORDER,
  PRODUCT_REVIEW,
  axios_post,
} from '../../utils/service';
import { Modal, Button, Rate } from 'antd';
import { get } from 'lodash';
import './orderDetail.scss';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import CateringSetCard from '../../components/CateringSetCard/cateringSetCard';
import CartRow from '../../components/Cart/cartRow';

export default function OrderDetail(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [order, setOrder] = useState('');
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  useEffect(() => {
    getOrderDetails();
  }, []);
  const getOrderDetails = () => {
    if (props.id) {
      axios_get(ORDER + props.id).then((res) => {
        setLoading(false);
        setOrder(get(res, 'data.data', null)) || null;
      });
    }
  };
  useEffect(() => {
    if (product) {
      getReview(product.product.id);
    }
  }, [product]);

  const getReview = (id) => {
    axios_get(PRODUCT_REVIEW + id)
      .then((res) => {
        setComment(get(res, 'data.data.comment', ''));
        setRating(get(res, 'data.data.rating', 0));
      })
      .catch((err) => {
        setComment('');
        setRating(0);
      });
  };
  let delivery_fee = parseFloat(get(order, 'delivery_fee', 0));

  let sub_total_price =
    parseFloat(get(order, 'cart.total_price', 0)) +
    parseFloat(get(order, 'catering_cart.total_price', 0));
  let discount = get(order, 'credit_points', []).filter(
    (item) => item.status.toLowerCase() == 'debited'
  )[0];
  discount = discount ? parseFloat(discount.amount) : 0;
  let total_price = delivery_fee + sub_total_price - discount;

  const showModal = (product) => {
    setProduct(product);
    setIsModalVisible(true);
  };
  console.log('prodicut', product);

  const handleOk = () => {};

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const submitReview = () => {
    if (!rating) {
      toast.error('Please provide rating');
      return;
    }
    if (props.id) {
      let data = {
        rating,
        comment,
        product: product.product.id,
      };
      axios_post(PRODUCT_REVIEW, data)
        .then((res) => setIsModalVisible(false))
        .catch((err) => setIsModalVisible(false));
    }
  };
  const address = get(order, 'address_json', null) || null;
  const products = get(order, 'cart.cart_products', []) || [];
  const catering_products =
    get(order, 'catering_cart.catering_cart_products', []) || [];

  return (
    <div>
      <Header />
      <AdminLayout>
        {loading ? null : order ? (
          <div className="order_detail">
            <h1>Order Details (Order Id : {order.orderId})</h1>
            <div className="order_details_header">
              <div>
                <h2>Shipping Address</h2>
                <p>{get(address, 'name', '')}</p>
                <p>{`${get(address, 'address_line1', '')}, ${get(
                  address,
                  'address_line2',
                  ''
                )}, ${get(address, 'city', '')}, ${get(
                  address,
                  'state',
                  ''
                )}-${get(address, 'post_code.post_code', '')}, ${get(
                  address,
                  'country',
                  ''
                )}`}</p>
              </div>
              <div>
                <h2>Payment method</h2>
                <p>{get(order, 'payment_type', '')}</p>
              </div>
              <div>
                <h2>Order Summary</h2>
                <table>
                  <tbody>
                    <tr>
                      <td>Items(s) Subtotal</td>
                      <td>${sub_total_price.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Shipping</td>
                      <td>${delivery_fee.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Discount</td>
                      <td>${discount.toFixed(2)}</td>
                    </tr>
                    <tr className="total_line">
                      <td>Total</td>
                      <td>${total_price.toFixed(2)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="items_container">
              <div className="items_header">
                <h2>
                  {get(order, 'cart.cart_products.length', 0) +
                    get(
                      order,
                      'catering_cart.catering_cart_products.length',
                      0
                    )}{' '}
                  Shipments
                </h2>
              </div>
              {/* <div className="order_body">
                <div className="product_displays details">
                  <div
                    className="product_item_list"
                    style={{
                      backgroundImage:
                        "url('https://images-eu.ssl-images-amazon.com/images/I/51gQ5BXlykL._SY90_.jpg')",
                    }}
                  ></div>
                  <div className="product_name">
                    <h2>
                      <a href="/">
                        Craftsman India Online Pottery Clay Pots/Earthen Kadai
                        for Cooking Pre-Seasoned (3.5 L, Black)
                      </a>
                    </h2>
                    <div>
                      <b>$35,100</b>
                    </div>
                  </div>
                </div>
                <div className="order_options">
                  <div>
                    <div className="star_rating">
                      <span className="material-icons active">star</span>
                      <span className="material-icons active">star</span>
                      <span className="material-icons active">star</span>
                      <span className="material-icons">star</span>
                      <span className="material-icons">star</span>
                      <b style={{ marginLeft: 7, fontSize: 13 }}>4.2</b>
                    </div>
                  </div>
                </div>
              </div> */}
              {catering_products.map((item) =>
                item.catering_set ? (
                  <CateringSetCard
                    hideAddToCart
                    set={item.catering_set}
                    showRemove
                    total={item.item_total}
                    date={get(item, 'catering.date', null)}
                    orderDetails
                    feedback
                    onFeedbackClick={showModal}
                  />
                ) : (
                  <div>
                    <CartRow
                      hideMinOrder
                      hideRemove
                      onlyQuantity
                      catering
                      key={item.id}
                      item={item}
                      delivery_day={moment(item.catering.date).format(
                        'Do MMM, ddd'
                      )}
                    />
                    <div className="order_options">
                      <div>
                        <button onClick={() => showModal(item.product_variant)}>
                          Leave feedback
                        </button>
                      </div>
                    </div>
                  </div>
                )
              )}

              {products.map((item) => (
                <div key={item.id} className="order_body">
                  <div className="product_displays details">
                    <div
                      className="product_item_list"
                      style={{
                        backgroundImage: `url('${get(
                          item,
                          'product_variant.cover_image.image'
                        )}')`,
                      }}
                    ></div>
                    <div className="product_name">
                      <h2>
                        <a href={'/product/' + item.product_variant.id}>
                          {`${get(
                            item,
                            'product_variant.product.name',
                            ''
                          )}, ${get(
                            item,
                            'product_variant.product.original_name'
                          )} (Qty - ${get(item, 'quantity')})`}
                        </a>
                      </h2>
                      <div>
                        <b>${item.item_total}</b>
                      </div>
                    </div>
                  </div>
                  <div className="order_options">
                    <div>
                      <button onClick={() => showModal(item.product_variant)}>
                        Leave feedback
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <p>order details not available</p>
        )}
        <Modal
          title="Rate Product"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={submitReview}>
              Submit review
            </Button>,
          ]}
        >
          <p className="product_review_name">
            {get(product, 'product.name', '')}
          </p>
          <Rate value={rating} onChange={(val) => setRating(val)} />
          <input value={comment} onChange={(e) => setComment(e.target.value)} />
        </Modal>
      </AdminLayout>
      <Footer />
    </div>
  );
}
