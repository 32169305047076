export default function AboutUsContent(data) {

  return(
  <div className="static_pages">
      <div className="container">
        <div className="static_page_content">
          <h1>About us</h1>
          <div className='editor_content' dangerouslySetInnerHTML={{ __html: data.aboutData }}></div>
        </div>
      </div>
    </div>
  )
}
