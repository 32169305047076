import React, { useEffect, useState } from "react";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import TermsAndConditionContent from "../../components/TermsAndConditionContent/terms_and_condition_content";
import { axios_get, TERMS_AND_CONDITIONS } from "../../utils/service";
import { useLocation } from "@reach/router";

export default function TermsAndCondition() {
  const location = useLocation();
  const hideHeaderFooter = location.pathname.includes("mobile");
  const [termsData, setTermsData] = useState("");
  useEffect(() => {
    getTerms();
  }, []);
  const getTerms = () => {
    axios_get(TERMS_AND_CONDITIONS).then((res) => {
      setTermsData(res.data.data.term_and_condition);
    });
  };
  return (
    <div>
      {!hideHeaderFooter&&<Header />}
      <TermsAndConditionContent termsData={termsData} />
      {!hideHeaderFooter&&<Footer />}
    </div>
  );
}
