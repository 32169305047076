import React, { useEffect, useState } from "react";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import {axios_post, EMAIL_CHANGE_CONFIRM} from "../../utils/service";
import {navigate} from "@reach/router";


export default function EmailChangeConfirm(props){

    const [otp, SetOTP] = useState('');

    const OnSubmitEmailChange = (e) => {
        e.preventDefault();
        const data = {email: props.email, new_email: props.new_email, token: props.token, otp:otp};
        axios_post(EMAIL_CHANGE_CONFIRM, data, false).then((res) => {
            navigate('/login')
            //todo check 200
      });
    }


    return (
        <div>
            <Header />
                <div  style={{height: '500px'}}>
                    <div style={{textAlign: 'center'}}>
                        <p>Check your otp on new email {props.new_email}</p>
                        <form onSubmit={OnSubmitEmailChange}>
                            <label htmlFor="">OTP</label>
                            <input type="otp" name="otp" onChange={(e)=>{SetOTP(e.target.value)}} required/>
                            <input type="submit" value="Change email"/>
                        </form>
                    </div>
                </div>
            <Footer />
        </div>
    )


}