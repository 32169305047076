import React, { useEffect, useState } from 'react';
import { SRLWrapper } from 'simple-react-lightbox';
import './slideshow.scss';
import './view_recipe.scss';
import Header from '../../components/Header/header';
import Footer from '../../components/Footer/footer';
import Rating from 'react-rating';
import { navigate } from '@reach/router';
import Cookies from 'js-cookie';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { get } from 'lodash';
import { Tabs, Modal } from 'antd';

import {
  axios_get,
  GET_RECIPE_DETAIL,
  axios_post,
  RATING,
  LIKE_UNLIKE_RECIPE,
  RECIPE_COMMENT,
  axios_put,
  FOLLOW_TOGGLE,
  checkLogin,
} from '../../utils/service';
import { useSelector } from 'react-redux';

const { TabPane } = Tabs;
export default function ViewRecipe(props) {
  const [recipe, setRecipe] = useState({ images: [] });
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);
  const [next, setNext] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  let current_user = useSelector((state) =>
    get(state, 'user.current_user', {})
  );
  const showModal = () => {
    setIsModalVisible(true);
  };
  useEffect(() => {
    getRecipeDetails();
    getComments();
  }, []);
  const postRating = (rating) => {
    let data = {
      rating,
      recipe: props.slug,
      id: recipe.current_user_rating ? recipe.current_user_rating.id : null,
    };
    if (recipe.current_user_rating && recipe.current_user_rating.id) {
      axios_put(RATING, data).then((res) => {
        if (res?.status === 200) {
          // getRecipeDetails();
          setRecipe(res.data.data);
        }
      });
    } else {
      axios_post(RATING, data).then((res) => {
        if (res?.status === 200) {
          // getRecipeDetails();
          setRecipe(res.data.data);
        }
      });
    }
  };
  const addComment = (e) => {
    e.preventDefault();
    if (checkLogin('to comment on this recipe')) return;
    let data = {
      recipe: recipe.id,
      is_publish: true,
      comment_by: Cookies.get('user_id'),
      comment: comment,
    };
    axios_post(RECIPE_COMMENT, data).then((res) => {
      if (res?.status === 201) {
        setComment('');
        getComments();
      }
    });
  };
  const getComments = () => {
    axios_get(RECIPE_COMMENT + '?recipe=' + props.slug).then((res) => {
      console.log('gggggg', res);
      if (res?.data?.data) {
        setComments(res.data.data);
        setNext(res.data.next);
      }
    });
  };
  const getPaginatedComments = () => {
    axios_get(next).then((res) => {
      setComments((prev) => [...prev, ...get(res, 'data.data', [])]);
      setNext(get(res, 'data.next', ''));
    });
  };
  const getRecipeDetails = () => {
    axios_get(GET_RECIPE_DETAIL + props.slug + '/').then((res) => {
      console.log("555', ", res);
      if (res?.data?.data) {
        setRecipe(res.data.data);
      }
    });
  };

  const toggleLikeRecipe = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (checkLogin('to like this recipe')) return;
    let id = recipe.id;
    axios_post(LIKE_UNLIKE_RECIPE, { recipe: id }).then((res) => {
      let rec = { ...recipe, is_liked: !recipe.is_liked };
      setRecipe(rec);
    });
  };
  const toggleFollow = (e, id, type) => {
    e.stopPropagation();
    if (checkLogin('to follow')) return;
    let data = {
      follower: id,
    };
    axios_post(FOLLOW_TOGGLE, data).then((res) => {
      console.log('toggle follow', res);
      if (res?.status === 200) {
        let dup_recipe = { ...recipe };
        let created_by = { ...dup_recipe.created_by };
        created_by.is_followed = !created_by.is_followed;
        dup_recipe.created_by = created_by;
        setRecipe(dup_recipe);
      }
    });
  };

  return (
    <div>
      <Header />
      <div
        className="min_height recipe_page"
        style={{ backgroundColor: '#f4f4f4' }}
      >
        <div className="container">
          <div className="recipe_view">
            <h1>{recipe.title}</h1>
            <div style={{ marginBottom: '50px' }}>
              <img
                style={{ width: '100%' }}
                src={get(recipe, 'cover_image.file', '')}
              />
            </div>

            <Tabs defaultActiveKey="1">
              <div className="gallery">
                <SRLWrapper>
                  <div className="library_grid">
                    {recipe.images &&
                      recipe.images.map((item) => (
                        <img alt="" src={item.file} />
                      ))}
                  </div>
                </SRLWrapper>
              </div>
            </Tabs>
            {/* <div className="slide_container">
              <Slide arrows={true} indicators={true}>
                {recipe.images &&
                  recipe.images.map((slideImage, index) => (
                    <div className="each_slide" key={index}>
                      <div
                        className="slide_image_cont"
                        style={{ backgroundImage: `url(${slideImage.file})` }}
                      ></div>
                    </div>
                  ))}
              </Slide>
            </div> */}
            <div className="recipe_info">
              {recipe.category && (
                <a href="/">
                  <img alt="" src={'/breakfast.svg'} />{' '}
                  {recipe?.category?.category}
                </a>
              )}

              {recipe.origin && (
                <a href="/">
                  <img alt="" src="/public.svg" /> {recipe?.origin?.origin}
                </a>
              )}

              {recipe.time && (
                <a href="/">
                  <img alt="" src="/time.svg" /> {recipe.time} minutes
                </a>
              )}

              {recipe.cooking_level && (
                <a href="/">
                  <img style={{ height: 20 }} alt="" src="/easy_level.svg" />{' '}
                  {recipe.cooking_level.level}
                  {/* medium_level.svg and hard_level.svg are also available @prashant828 */}
                </a>
              )}

              {recipe.cost_per_serving && (
                <a href="/">
                  <img src="/cost.svg" />{' '}
                  {`$` + recipe.cost_per_serving + '/ Per serving'}
                </a>
              )}

              {recipe.serving && (
                <a href="/">
                  <img alt="" src="/servings.svg" />{' '}
                  {'Total servings - ' + recipe.serving}
                </a>
              )}
            </div>
            <div
              className="description editor_styling"
              dangerouslySetInnerHTML={{ __html: recipe.description }}
            ></div>
            <div className="nutritional_half recipe_section">
              {recipe.ingredients && (
                <div className="ingredients">
                  <h2>Ingredients</h2>
                  <ul>
                    {recipe.ingredients.map((item) => (
                      <li>
                        {item?.name?.name}
                        {item?.brand?.brand
                          ? ` (${item.brand.brand})`
                          : ''} -{' '}
                        {Number(item?.quantity) + ' ' + item?.unit?.unit}{' '}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <table>
                <thead>
                  <tr>
                    <td colSpan="3">
                      <h3>Nutritional Information</h3>
                    </td>
                  </tr>
                  <tr>
                    <td>Total Servings</td>
                    <td className="small">5</td>
                    <td className="small"></td>
                  </tr>
                  <tr>
                    <td>Serving Size</td>
                    <td className="small">200g</td>
                    <td className="small"></td>
                  </tr>
                  <tr className="borders">
                    <td></td>
                    <td className="small">Average Quantity per serving</td>
                    <td className="small">Average Quantity per 100g</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Energy</td>
                    <td className="small">0 kj</td>
                    <td className="small">0 kj</td>
                  </tr>
                  <tr>
                    <td>Protein</td>
                    <td className="small">0 g</td>
                    <td className="small">0 g</td>
                  </tr>
                  <tr>
                    <td>Fat</td>
                    <td className="small">0 g</td>
                    <td className="small">0 g</td>
                  </tr>
                  <tr>
                    <td>- Saturated</td>
                    <td className="small">0 g</td>
                    <td className="small">0 g</td>
                  </tr>
                  <tr>
                    <td>Carbohydrate</td>
                    <td className="small">0 g</td>
                    <td className="small">0 g</td>
                  </tr>
                  <tr>
                    <td>Sugar</td>
                    <td className="small">0 g</td>
                    <td className="small">0 g</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="recipe_section">
              <h2>Tools</h2>
              <div
                className="editor_styling"
                dangerouslySetInnerHTML={{ __html: recipe.preparation }}
              ></div>
            </div>
            <div className="recipe_section">
              <h2>Instructions</h2>
              <div
                className="editor_styling"
                dangerouslySetInnerHTML={{ __html: recipe.instruction }}
              >
                {/* <video width="320" height="240" controls>
                  <source
                    src="https://www.w3schools.com/html/mov_bbb.mp4"
                    type="video/mp4"
                  />
                  <source
                    src="https://www.w3schools.com/html/mov_bbb.ogg"
                    type="video/ogg"
                  />
                  Your browser does not support the video tag.
                </video> */}
              </div>
            </div>
            <div className="recipe_like">
              <button onClick={(e) => toggleLikeRecipe(e)}>
                <span
                  className={
                    recipe.is_liked ? 'material-icons active' : 'material-icons'
                  }
                >
                  {recipe.is_liked ? 'favorite' : 'favorite_border'}
                </span>
                Like
              </button>
              <button className="rate_recipe_btn">
                {/* <span className="star">
                  <span className="material-icons">star</span>
                  {recipe.rating}
                </span> */}
                <span className="star">
                  <Rating
                    className="rating_system"
                    emptySymbol={
                      <span className="material-icons">star_border</span>
                    }
                    fullSymbol={<span className="material-icons">star</span>}
                    initialRating={recipe.rating || 0}
                    readonly
                  />
                </span>
                {Cookies.get('user_id') ? (
                  <Popup
                    trigger={<label>Rate recipe</label>}
                    position="right center"
                  >
                    <div>
                      <span
                        style={{ color: '#f3d029' }}
                        className="material-icons"
                      >
                        <Rating
                          className="rating_system"
                          emptySymbol={
                            <span className="material-icons">star_border</span>
                          }
                          fullSymbol={
                            <span className="material-icons">star</span>
                          }
                          initialRating={get(
                            recipe,
                            'current_user_rating.rating',
                            0
                          )}
                          onChange={postRating}
                        />
                      </span>
                      <p>Your Ratings</p>
                    </div>
                  </Popup>
                ) : (
                  <p>Login to Rate this Recipe</p>
                )}
              </button>
            </div>
            <div className="author_attribution">
              <div
                onClick={() =>
                  navigate('/user/' + recipe?.created_by?.username)
                }
                className="author_info"
              >
                <div
                  className="author_circle_pic"
                  style={{
                    backgroundImage:
                      'url(' + recipe?.created_by?.profile_pic + ')',
                  }}
                ></div>
                <div className="author_basic_info">
                  <h5>
                    {recipe?.created_by?.first_name +
                      ' ' +
                      recipe?.created_by?.last_name}
                    <span>
                      <a href="/">
                        {recipe?.created_by?.recipe_count + ' recipes'}
                      </a>
                    </span>
                  </h5>
                  <div className="author_desc">
                    @{recipe?.created_by?.username}
                  </div>
                  <div className="author_desc">{recipe?.created_by?.about}</div>
                </div>
              </div>
              <div className="follower_info">
                <button
                  onClick={(e) => toggleFollow(e, recipe?.created_by?.id)}
                  className={
                    recipe?.created_by?.is_followed
                      ? 'follow_usr_active unfollow'
                      : 'follow_usr_active'
                  }
                >
                  <span className="material-icons">
                    {recipe?.created_by?.is_followed ? 'done_all' : 'rss_feed'}
                  </span>
                  {recipe?.created_by?.is_followed ? 'Unfollow' : 'Follow'}
                </button>
                <div className="follower_count">
                  {get(recipe, 'created_by.follower_count', 0)} Followers
                </div>
              </div>
            </div>
            <div className="comments_section">
              <h5>Comments({recipe.comment_count})</h5>
              {comments &&
                comments.map((item) => (
                  <div className="single_comments">
                    <div
                      className="comment_user_circle"
                      style={{
                        backgroundImage:
                          'url(' + item.comment_by.profile_pic + ')',
                      }}
                    ></div>
                    <div className="user_comment">
                      <h6>
                        {item.comment_by &&
                          item.comment_by.first_name +
                            ' ' +
                            item.comment_by.last_name}
                      </h6>
                      <p>{item.comment}</p>
                    </div>
                  </div>
                ))}
              {next && <div onClick={getPaginatedComments}>load more</div>}
              <div className="single_comments">
                <div
                  className="comment_user_circle"
                  style={{
                    backgroundImage: current_user.profile_pic
                      ? `url(${current_user.profile_pic})`
                      : 'url(/user.jpg)',
                  }}
                ></div>

                <div className="user_comment">
                  <h6>Post your comment</h6>
                  <form action="">
                    <textarea
                      disabled={Cookies.get('token') ? false : true}
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      placeholder={
                        Cookies.get('token')
                          ? 'Type your comment here'
                          : 'Login to post a comment'
                      }
                    ></textarea>
                    <button onClick={addComment}>Submit</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
