import React, {useEffect, useState} from "react";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import { Table } from 'antd';
import AdminLayout from "../../components/AdminLayout/admin_layout";
import "./wallet.scss"
import {CreditPoints, axios_get} from "../../utils/service";
import moment from "moment";
import {useSelector} from "react-redux";
import {get} from "lodash";

export default function TermsAndCondition() {

  const [data, SetData] = useState([]);
  let current_user = useSelector((state) => get(state, "user.current_user", {}));


  useEffect(() => {
    getCreditPoint();
    //todo {moment(item.created_on).local().format("DD MMM YYYY")}
   }, []);

  const getCreditPoint = () => {
    axios_get(CreditPoints).then((res) => {
      SetData(res.data.data);
    });
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'created_on',
      key: 'created_on',
      render : text => {

        return moment(text).local().format("DD-MM-YYYY")

      }
    },
    {
      title: 'Point',
      dataIndex: 'point',
      key: 'point',
      render: text => {
        let point = "added"
            if (text[0] === '-') {
              point = 'used';
              text = text.substring(1)
            }
        return <span className={point}>{text}</span>
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    }
  ]

  // const data = [
  //   {
  //     key: '1',
  //     date: 'Sept. 25, 2021',
  //     point: "$32",
  //     status: 'Added',
  //   },
  //   {
  //     key: '2',
  //     date: 'Sept. 25, 2021',
  //     point: "-$32",
  //     status: 'Used',
  //   },
  //   {
  //     key: '3',
  //     date: 'Oct. 25, 2021',
  //     point: "-$32",
  //     status: 'Used',
  //   },
  //   {
  //     key: '4',
  //     date: 'Sept. 25, 2021',
  //     point: "$32",
  //     status: 'Added',
  //   },
  // ];

  return (
    <div>
      <Header />
        <AdminLayout>
          <div className="wallet">
            <h1 className="wallet_head">Bukk Credits <span>Points available: {current_user.total_point}</span></h1>
            <Table columns={columns} dataSource={data} />
          </div>
        </AdminLayout>
      <Footer />
    </div>
  );
}
