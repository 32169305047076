import Cookies from "js-cookie";
import { navigate } from "@reach/router";
import React from "react";
// import { useState } from "react";
import "./header.scss";
import { useSelector } from "react-redux";
import {get} from "lodash";
// import {UpdateSearchText} from "../../store/appInfo/appInfo";
// import {useDispatch, useSelector} from "react-redux";

export default function Header() {
  //   const dispatch = useDispatch();
  // const search_text2 = useSelector((state) => state.appInfo.search_text);
  // const [search_text, SetSearchText] = useState('')
  let current_user = useSelector((state) => get(state, "user.current_user", {}));
  Cookies.set('email', current_user.email||"")
  const onClickLogout = (e) => {
    e.preventDefault();
    Cookies.remove("user_id");
    Cookies.remove("token");
    Cookies.remove("refresh_token");
    Cookies.remove('email');
    navigate("/login");
  };

  // const SearchingFunction = () => {
  //     dispatch(UpdateSearchText(search_text));
  //     if (window.location.pathname !== '/search'){
  //         navigate("/search?search="+search_text, { replace: true })
  //     }
  // }

  // const onclickSearch = (e) => {
  //     e.preventDefault();
  //     SearchingFunction();
  // }
  //
  // const handleKeyDown = (e) => {
  //     if (e.key === 'Enter') {
  //     SearchingFunction();
  //     }
  // }
  //
  //  const  onchangeSearch = (e) => {
  //     SetSearchText(e.target.value);
  //     if (e.target.value===''){
  //         dispatch(UpdateSearchText(e.target.value));
  //     }
  //  }

  return (
    <React.Fragment>
    <nav className="navbar">
        <div className="container">
          <div className="nav_inner_container">
            <a className="logo" href="/"><img src="/logo.png" alt="logo"/></a>
            {Cookies.get("token") ? (
            <a className="wallet_menu_phone" href="/wallet">
              <span className="material-icons">card_giftcard</span>
              <span className="credit">{current_user.total_point}</span>
            </a>
            ):
            null
            }
            <label htmlFor="menu" className="menu_btn"><span className="material-icons">menu</span></label>
            <input id="menu" type="checkbox" hidden/>
            <label htmlFor="menu"/>
            <div className="nav_right">
              <ol>
                  <li className="nav_link"><a href="/" className="current">Home</a></li>
                  <li className="nav_link"><a href="/search">Recipes</a></li>
                  <li className="nav_link"><a href="/social">Social</a></li>
                  <li className="nav_link"><a href="/catering">Catering</a></li>
                  <li className="nav_link active"><a href="/shop">Shop</a></li>
                  {Cookies.get("token") ? (
                  <li className="nav_link">
                    <a className="wallet_menu" href="/wallet">
                      <span className="material-icons">card_giftcard</span>
                      <span className="credit">{current_user.total_point}</span>
                    </a>
                  </li>):null}
                  {/* <li className="nav_link"><a href="/contact-us">Contact Us</a></li> */}
              </ol>
              {/* <div className="search">
                  <input type="search" placeholder="Search..." value={search_text ? search_text : search_text2} onChange={(e)=>{SetSearchText(e.target.value);}}/>
                  <button type="button"><span className="material-icons" onClick={onclickSearch}>search</span></button>
              </div> */}
              <div className="user_login">
                  {Cookies.get("token") ? (
                    <div className="header-right">
                      <div className="user_nav_menu">
                        <div className="logged_in_menu">
                          <div className="user_img" style={{backgroundImage: current_user.profile_pic ? `url(${current_user.profile_pic})`:  "url('/avtar.png')"}}></div>
                          <span className="user_name">{current_user.first_name}</span>
                          <span className="material-icons">expand_more</span>
                        </div>
                        <ul className="dropped_user_menu">
                          <li><a href="/add-edit-recipe">Add Recipe</a></li>
                          <li><a href="/dashboard">My Feed</a></li>
                          <li><a href="/wallet">Bukk Credits</a></li>
                          <li><a href="/order-history">Order History</a></li>
                          <li><a href="/addresses">Addresses</a></li>
                          <li><a href="/my-recipes">My Recipes</a></li>
                          <li><a href="/favorites">Favorites</a></li>
                          <li><a href="/user-settings">My Profile</a></li>
                          <li><a href="/change-password">Change Password</a></li>
                          <li>
                            <button onClick={onClickLogout} className="active">
                              Logout
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className="mobile_logged_in">
                        <ul>
                          <li><a href="/dashboard">My dashboard</a></li>
                          <li>
                            <button onClick={onClickLogout} className="active">
                              Logout
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <React.Fragment>
                      <a className="login_link" href="/login">Login</a>
                      <a href="/signup" className="login_link active">Sign Up</a>
                    </React.Fragment>
                  )}
              </div>
            </div>
          </div>
        </div>
    </nav>
    </React.Fragment>
    );
}
