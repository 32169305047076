import React from "react";
import { useSelector } from "react-redux";
import "./footer.scss";
export default function Footer() {
  const contactDetails = useSelector((state) => state.appInfo.contact);
  const recipeCategories = useSelector(
    (state) => state.appInfo.recipeCategories
  );
  const recipeTypes = useSelector((state) => state.appInfo.recipeTypes);
  const productCategoryList = useSelector(
    (state) => state.appInfo.productCategoryList
  );
  return (
    <div className="footer">
      <div className="container">
        <div className="footer_container">
          <div className="footer_col">
            <img className="brand_foot" alt="logo" src="/logo.png" />
            <ul className="footer_branding">
              <li>
                <img alt="mail logo" src="/mail.svg" />{" "}
                {contactDetails.email || "contact@bukk.com.au"}
              </li>
              <li>
                <img alt="phone icon" src="/phone.svg" /> +61{" "}
                {contactDetails.phone_number}
              </li>
            </ul>
          </div>
          <div className="footer_col">
            <h6>Cuisine</h6>
            <ul>
              {recipeTypes.map((type) => (
                <li key={type.id}>
                  <a href={"/search?type=" + type.id}>{type.type}</a>
                </li>
              ))}
            </ul>
          </div>
          <div className="footer_col">
            <h6>Meals</h6>
            <ul>
              {recipeCategories.map((cat) => (
                <li key={cat.id}>
                  <a href={"/search?category=" + cat.id}>{cat.category}</a>
                </li>
              ))}
            </ul>
          </div>
          <div className="footer_col">
            <h6>Shop</h6>
            <ul>
              {productCategoryList.map((cat) => (
                <li key={cat.id}>
                  <a href={"/shop/search?category=" + cat.id}>{cat.category}</a>
                </li>
              ))}
            </ul>
          </div>
          <div className="footer_col">
            <h6>Pages</h6>
            <ul>
              <li>
                <a href="/about-us">About us</a>
              </li>
              <li>
                <a href="/contact-us">Contact us</a>
              </li>
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              <li>
                <a href="/terms-and-condition">Term and conditions</a>
              </li>
            </ul>
          </div>
          <div className="footer_col">
            <h6>Get in touch</h6>
            {contactDetails.instagram ? (
              <a
                className="foot_social"
                target="_blank"
                rel="noopener noreferrer"
                href={contactDetails.instagram}
              >
                <img alt="instagram" src="/insta.png" />
              </a>
            ) : null}
            {contactDetails.facebook ? (
              <a
                className="foot_social"
                target="_blank"
                rel="noopener noreferrer"
                href={contactDetails.facebook}
              >
                <img alt="facebook logo" src="/fb.png" />
              </a>
            ) : null}
            {contactDetails.youtube ? (
              <a
                className="foot_social"
                target="_blank"
                rel="noopener noreferrer"
                href={contactDetails.youtube}
              >
                <img alt="youtube logo" src="/yt.png" />
              </a>
            ) : null}
            {contactDetails.twitter ? (
              <a
                className="foot_social"
                target="_blank"
                rel="noopener noreferrer"
                href={contactDetails.twitter}
              >
                <img alt="twitter icon" src="/twitter.png" />
              </a>
            ) : null}
            {contactDetails.whatsapp ? (
              <a
                className="foot_social"
                target="_blank"
                rel="noopener noreferrer"
                href={"https://wa.me/" + contactDetails.whatsapp}
              >
                <img alt="whatsapp icon" src="/whatsapp.png" />
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
