import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/header';
import Footer from '../../components/Footer/footer';
import './product.scss';
import Slider from 'react-slick';
import { get, includes, update } from 'lodash';
import { toast } from 'react-toastify';

import {
  axios_get,
  calculate_delivery_date,
  POST_CODE,
  product_orginal_name,
  PRODUCT_VARIANT_API,
  RELATED_PRODUCT,
  updateCartItem,
  PRODUCT_REVIEW,
} from '../../utils/service';
import { addToCart } from '../../utils/service';
import Cart from '../../components/Cart/cart';
import { useSelector } from 'react-redux';
import Rating from 'react-rating';
import { navigate } from '@reach/router';
toast.configure();
let timer = null;
export default function Product(props) {
  const [product, setProduct] = useState('');
  const [postcode, setPostCode] = useState('');
  const [delivery_date, SetDelivery_date] = useState(calculate_delivery_date());
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [next, setNext] = useState('');
  const [reviews, setReviews] = useState([]);
  const [count, setCount] = useState(0);
  let cart = useSelector((state) => get(state, 'cart.cart', {}));
  let inCart = get(cart, 'cart_products', []).filter(
    (p) => p.product_variant.id == product.id
  )[0];

  useEffect(() => {
    getProductDetails();
    getRelatedProducts();
  }, []);
  useEffect(() => {
    if (product) {
      getReviews();
    }
  }, [product]);

  const getReviews = () => {
    axios_get(PRODUCT_REVIEW + '?product=' + product.product.id).then((res) => {
      setReviews(get(res, 'data.data', []));
      setCount(get(res, 'data.count', 0));
      setNext(get(res, 'data.next', ''));
    });
  };
  const getPaginatedReviews = () => {
    axios_get(next).then((res) => {
      setReviews((prev) => [...prev, ...get(res, 'data.data', [])]);
      setNext(get(res, 'data.next', ''));
    });
  };

  const checkPostCodeClick = () => {
    if (postcode) {
      getDeliveryDate(postcode);
    }
  };

  const getDeliveryDate = (postcode) => {
    axios_get(POST_CODE + postcode + '/').then((res) => {
      let delivery_in_days = get(res, 'data.data.delivery_in_days', 2);
      if (delivery_in_days) {
        SetDelivery_date(calculate_delivery_date(delivery_in_days));
      }
    });
  };

  const getRelatedProducts = () => {
    axios_get(RELATED_PRODUCT + props.id + '/').then((res) => {
      setRelatedProducts(get(res, 'data.data', []));
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const getProductDetails = () => {
    axios_get(PRODUCT_VARIANT_API + props.id + '/').then((res) => {
      setProduct(get(res, 'data.data', ''));

      const address = get(res, 'data.data.address', '');
      if (address) {
        setPostCode(get(address, 'post_code.post_code', ''));
        const delivery_in_days = get(address, 'post_code.delivery_in_days', 2);
        SetDelivery_date(calculate_delivery_date(delivery_in_days));
      }
    });
  };
  const settings = {
    customPaging: function (i) {
      return (
        <a>
          <img
            src={get(product, 'product_variant_images[' + i + '].image', [])}
          />
        </a>
      );
    },
    dots: true,
    arrows: false,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const relatedSetting = (products) => ({
    dots: false,
    infinite: false,
    speed: 500,
    arrows: true,
    slidesToShow: products.length < 5 ? products.length : 5,
    slidesToScroll: 1,
  });

  function relatedProductsList() {
    return relatedProducts.map((item, index) => (
      <div
        key={index}
        onClick={() => navigate('/product/' + item.id)}
        className="product_col card"
      >
        <img src={get(item, 'cover_image.image', '') || ''} />
        <div className="product_desc">
          <h5>
            {item.product.name}{' '}
            {product_orginal_name(item.product.original_name)}
          </h5>
          <span className="offer"></span>
          <div className="star_rating">
            <Rating
              className="rating_system"
              emptySymbol={<span className="material-icons">star_border</span>}
              fullSymbol={<span className="material-icons">star</span>}
              initialRating={item.product.rating || 0}
              readonly
            />
          </div>
          <div className="price_desc">
            <p className="price_strike">
              {item.price != item.price_after_discount && (
                <span>${item.price}</span>
              )}
              ${item.price_after_discount}
            </p>
            {item.is_added_in_shop ? (
              <button disabled={true}>
                Go to cart
                <span className="material-icons">add_shopping_cart</span>
              </button>
            ) : (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  addToCart(item.id);
                }}
              >
                Add to cart
                <span className="material-icons">add_shopping_cart</span>
              </button>
            )}
          </div>
        </div>
      </div>
    ));
  }

  return (
    <div>
      <Header />
      <div className="product min_height">
        <div
          className="container"
          style={{ backgroundColor: '#fff', padding: 25 }}
        >
          <div className="product_left">
            <div className="slicer_product_container">
              <Slider {...settings}>
                {get(product, 'product_variant_images', []).map((item) => (
                  <div>
                    <img src={item.image} />
                  </div>
                ))}
              </Slider>
            </div>
            <div className="action_buy">
              {inCart ? (
                <button onClick={showModal}>Go to Cart</button>
              ) : (
                <button
                  className="add_to_cart"
                  onClick={() => addToCart(product.id)}
                >
                  <span className="material-icons">shopping_cart</span>ADD TO
                  CART
                </button>
              )}

              <button className="instant_buy">
                <span className="material-icons">flash_on</span>BUY NOW
              </button>
            </div>
          </div>
          <div className="product_right">
            <h1>
              {product?.product?.name}{' '}
              {product_orginal_name(product?.product?.original_name)}
            </h1>
            <div className="concise_rating_cont">
              <span className="concise_rating">
                {product?.product?.rating}{' '}
                <span className="material-icons">star</span>
              </span>
              <span className="rating_count">
                {product?.product?.rating_sum || 0} Ratings
              </span>
            </div>
            {product.price !== product.price_after_discount ? (
              <div className="product_pricing">
                <span className="discounted_price">
                  ${product.price_after_discount}
                </span>
                <span className="actual_price">${product.price}</span>
                <span className="offer_text">
                  ${product.discount_percent}% OFF
                </span>
              </div>
            ) : (
              <div className="product_pricing">
                <span className="discounted_price">${product.price}</span>
              </div>
            )}
            <div>(Min order: {product.minimum_order})</div>
            <div>
              <table className="product_table">
                <tbody>
                  <tr>
                    <td className="head">Delivery</td>
                    <td className="postcode">
                      <input
                        placeholder="Enter postcode"
                        type="number"
                        value={postcode}
                        onChange={(e) => setPostCode(e.target.value)}
                      />
                      <button onClick={checkPostCodeClick}>CHECK</button>
                      <div className="delivery_time">
                        <span>Delivery by</span> {delivery_date}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="head">Description</td>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: product?.product?.description,
                      }}
                    ></td>
                  </tr>
                  <tr>
                    <td className="head">Specification</td>
                    <td
                      dangerouslySetInnerHTML={{
                        __html: product?.product?.specification,
                      }}
                    ></td>
                  </tr>
                  {get(product, 'attribute', []).map((item) => (
                    <tr>
                      <td className="head">{item.attribute.label}</td>
                      <td>{item.value}</td>
                    </tr>
                  ))}
                  {get(product, 'product.product_specification', []).map(
                    (item) => (
                      <tr>
                        <td className="head">
                          {item.technical_specification.specification_name}
                        </td>
                        <td
                          dangerouslySetInnerHTML={{
                            __html: item.value,
                          }}
                        ></td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {relatedProducts && relatedProducts.length > 0 ? (
          <div className="container">
            <div className="related_products">
              <h2>Related Products</h2>
              <Slider
                className={relatedProducts.length < 5 ? 'limited_products' : ''}
                {...relatedSetting(relatedProductsList())}
              >
                {relatedProductsList()}
              </Slider>
            </div>
          </div>
        ) : null}
        <div>
          <div className="container" style={{ flex: '1' }}>
            <div className="comments_section" style={{ width: '100%' }}>
              <h5>Comments({count})</h5>
              {reviews.map((item) => (
                <div className="single_comments">
                  <div
                    className="comment_user_circle"
                    style={{ backgroundImage: 'url(/user.jpg)' }}
                  ></div>
                  <div className="user_comment">
                    <h6>
                      {get(item, 'rating_by.first_name', '')}{' '}
                      {get(item, 'rating_by.last_name', '')}{' '}
                      <span className="product_rating">
                        <span className="material-icons">star</span>
                        <span className="rating_value">
                          {parseFloat(item.rating).toFixed(2)}
                        </span>
                      </span>
                    </h6>
                    <p>{item.comment}</p>
                  </div>
                </div>
              ))}

              {next && <div onClick={getPaginatedReviews}>Load more...</div>}
            </div>
          </div>
        </div>
        <button onClick={showModal} className="cart_display">
          <span className="material-icons">shopping_cart</span>
          <span className="cart_count">
            <span>{get(cart, 'cart_products.length', 0)}</span>
          </span>
        </button>
        <Cart
          handleOk={handleOk}
          handleCancel={handleCancel}
          isModalVisible={isModalVisible}
        />
      </div>
      <Footer />
    </div>
  );
}
