export default function TermsAndConditionContent(data) {

  return(
  <div className="static_pages">
      <div className="container">
        <div className="static_page_content">
          <h1>Terms &amp; Conditions</h1>
          <div className='editor_content' dangerouslySetInnerHTML={{ __html: data.termsData }}></div>
        </div>
      </div>
    </div>
  )
  
}
