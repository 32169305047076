import { configureStore } from "@reduxjs/toolkit";
import appInfoReducer from "./appInfo/appInfo";
import cartReducer from "./cart/cart";
import user_storeReducer from "./user_store/user_store";

export const store = configureStore({
  reducer: {
    appInfo: appInfoReducer,
    cart: cartReducer,
    user: user_storeReducer
  },
});
