import React, { useEffect, useState } from "react";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import AdminLayout from "../../components/AdminLayout/admin_layout";
import {
  axios_get,
  GET_RECIPE_OWN,
  axios_post,
  LIKE_UNLIKE_RECIPE,
  checkLogin,
  GET_RECIPE,
  axios_delete,
} from "../../utils/service";
import "./my_recipe.scss";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroller";
import { get } from "lodash";
import { navigate } from "@reach/router";
import Rating from "react-rating";
export default function MyRecipes() {
  const [posts, setPosts] = useState([]);
  const [type, setType] = useState("");
  const [next, setNext] = useState("");
  useEffect(() => {
    getOwnRecipes("");
  }, []);
  const getOwnRecipes = (_type) => {
    setNext("");
    setType(_type);
    if (_type !== type) {
      setPosts([]);
    }
    let url = _type ? GET_RECIPE_OWN + "?status=" + _type : GET_RECIPE_OWN;
    axios_get(next ? next : url, true).then((res) => {
      setPosts((prev) => [...prev, ...get(res, "data.data", [])]);
      setNext(get(res, "data.next", ""));
    });
  };
  const toggleLikeRecipe = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    if (checkLogin("to like this recipe")) return;
    axios_post(LIKE_UNLIKE_RECIPE, { recipe: id }).then((res) => {
      let arr = posts.map((item) => {
        if (item.id === id) {
          item.is_liked = !item.is_liked;
        }
        return item;
      });
      console.log("lskdlsk", arr);
      setPosts(arr);
      console.log("res", res);
    });
  };
  const deleteRecipe = (e, id) => {
    console.log("ff");
    e.preventDefault();
    e.stopPropagation();
    if (checkLogin("to like this recipe")) return;
    axios_delete(GET_RECIPE + id).then((res) => {
      getOwnRecipes("");
    });
  };
  return (
    <div>
      <Header />
      <InfiniteScroll
        pageStart={0}
        loadMore={() => getOwnRecipes("")}
        hasMore={next ? true : false}
        loader={
          <div className="loader" key={0}>
            Loading ...
          </div>
        }
      >
        <AdminLayout>
          <div className="my_recipes">
            <div className="recipe_filter">
              <input
                id="recipe_all"
                type="radio"
                name="recipe_status"
                value="all"
                checked={type ? false : true}
                hidden
              />
              <label onClick={() => getOwnRecipes("")} htmlFor="recipe_all">
                All
              </label>
              <input
                id="recipe_approved"
                type="radio"
                name="recipe_status"
                value="approved"
                hidden
                checked={type === "APPROVE"}
              />
              <label
                onClick={() => getOwnRecipes("APPROVE")}
                htmlFor="recipe_approved"
              >
                Approved
              </label>
              <input
                id="recipe_pending"
                type="radio"
                name="recipe_status"
                value="pending"
                hidden
                checked={type === "PENDING"}
              />
              <label
                onClick={() => getOwnRecipes("PENDING")}
                htmlFor="recipe_pending"
              >
                Pending
              </label>
              <input
                id="recipe_drafts"
                type="radio"
                name="recipe_status"
                value="drafts"
                hidden
                checked={type === "DRAFT"}
              />
              <label
                onClick={() => getOwnRecipes("DRAFT")}
                htmlFor="recipe_drafts"
              >
                Drafts
              </label>
              <span className="colored_selector"></span>
            </div>
            <a href="/add-edit-recipe">
              <button className="add_recipe_btn">
                <span className="material-icons">add</span>{" "}
                <span className="btn_txt">Add recipe</span>
              </button>
            </a>
            {posts.map((post) => (
              <div
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/recipe/" + post.id);
                }}
                key={post.id}
                className="feed_list_item"
              >
                <div className="feed_image">
                  <img src={post.cover_image.file} />
                </div>
                <div className="feed_footer">
                  <div className="post_ratings">
                    <span className="cat">{post.category.category}</span>
                    {/* <span className="star">
                    <span className="material-icons">star</span>
                    {post.rating}
                  </span> */}
                    <span className="star">
                      <Rating
                        className="rating_system"
                        emptySymbol={
                          <span className="material-icons">star_border</span>
                        }
                        fullSymbol={
                          <span className="material-icons">star</span>
                        }
                        initialRating={post.rating || 0}
                        readonly
                      />
                    </span>
                  </div>
                  <h2>{post.title}</h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: post.description }}
                  ></div>
                  <div className="post_action_container status">
                    <div className="post_inner_cont">
                      <button
                        onClick={(e) => toggleLikeRecipe(e, post.id)}
                        className="post_action like_btn"
                      >
                        <span
                          className={
                            post.is_liked
                              ? "material-icons active"
                              : "material-icons"
                          }
                        >
                          {post.is_liked ? "favorite" : "favorite_border"}
                        </span>
                        Like
                      </button>
                      <button className="post_action comments_btn">
                        <span className="material-icons">
                          chat
                          <span className="count">{post.comment_count}</span>
                        </span>
                        Comments
                      </button>
                    </div>
                    <div className="author_post_actions">
                      <button
                        onClick={(e) => deleteRecipe(e, post.id)}
                        className="post_edit"
                      >
                        <span className="material-icons">delete</span> Delete
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate("/add-edit-recipe/" + post.id);
                        }}
                        className="post_edit"
                      >
                        <span className="material-icons">edit</span> Edit Post
                      </button>
                      <span className="post_status pending">{post.status}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </AdminLayout>
      </InfiniteScroll>
      <Footer />
    </div>
  );
}
