import React from "react";
import moment from "moment";
import { get } from "lodash";
import CateringSetCard from "../CateringSetCard/cateringSetCard";

export default function CateringRow({ day, showDrawer, addToCateringCart }) {
  return (
    <div className="week_day">
      <div className="week_head">
        <b>{moment(day.date).format("dddd, DD MMMM")}</b>
        {!day.catering_close && (
          <button onClick={showDrawer}>
            <span className="material-icons">add_circle</span> Add from menu
          </button>
        )}
      </div>
      {!day.catering_close && get(day, "catering_set", []).length ? (
        get(day, "catering_set", []).map((set) => (
          <CateringSetCard
            day={day}
            set={set}
            addToCateringCart={addToCateringCart}
          />
        ))
      ) : (
        <div className="meal_body">
          <div className="empty_day">
            <img src="/food.svg" />
            <p>
              No catering service available for this day <br />
              {!day.catering_close && "Add items from menu"}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
