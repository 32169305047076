import React, { useState, useEffect } from "react";
import { Checkbox } from "antd";
import AsyncSelect from "react-select/async";
import { get } from "lodash";
import {
  axios_get,
  axios_post,
  POST_CODE,
  ADDRESS,
  axios_put,
} from "../../utils/service";
import "./edit_address.scss";
import { navigate } from "@reach/router";
import Cookies from "js-cookie";
import {useSelector} from "react-redux";

export default function Form(props) {
  const [name, setName] = useState("");
  const [address_line1, setAddress1] = useState("");
  const [address_line2, setAddress2] = useState("");
  const [mobile_number, setMobile] = useState("");
  const [email, setEmail] = useState(Cookies.get('email') ? Cookies.get('email') : "");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("Australia");
  const [delivery_instruction, setInstruction] = useState("");
  const [is_default, setDefault] = useState(false);
  const [address_type, setAddressType] = useState("Home");
  const [post_code, setPostCode] = useState(35);
  const [inputValue, setInputValue] = useState("");
  const [zipCodes, setZipCodes] = useState([]);
  const getAddressDetail = () => {
    if (props.id) {
      axios_get(ADDRESS + props.id + "/").then((res) => {
        console.log("lflsjlfjsf", res);
        let data = get(res, "data.data", {}) || {};
        setAddress1(data.address_line1);
        setAddress2(data.address_line2);
        setName(data.name);
        setMobile(data.mobile_number);
        setCity(data.city);
        setState(data.state);
        setCountry(data.country);
        setEmail(data.email);
        setInstruction(data.delivery_instruction);
        setDefault(data.is_default);
        setAddressType(data.address_type);
        setPostCode({
          value: data.post_code.id,
          label: data.post_code.post_code,
        });
      });
    }
  };
  const getZipCodes = (str) => {
    return axios_get(POST_CODE + "?search=" + (str || "")).then((res) => {
      let options = get(res, "data.data", []) || [];
      options = options.map((item) => ({
        value: item.id,
        label: item.post_code,
      }));
      console.log("options", options);
      setZipCodes(options);
      return options;
    });
  };
  const submitAddress = (e) => {
    e.preventDefault();

    if (!post_code || !post_code.value) {
      alert("Post Code required");
      return;
    }
    let data = {
      name,
      address_line1,
      address_line2,
      mobile_number,
      city,
      state,
      country,
      email,
      delivery_instruction,
      is_default,
      address_type,
      post_code: post_code.value,
    };
    if (props.id) {
      axios_put(ADDRESS + props.id + "/", data, true).then((res) => {
        console.log("put res", res);
        window.location.replace("/addresses");
      });
    } else {
      return axios_post(ADDRESS, data, true).then((res) => {
        if (props.checkout) {
          props.onSubmit(res);
          return;
        }
        if (res.data) {
          window.location.replace("/addresses");
        }
      });
    }
  };
  const loadOptions = async (inputValue) => {
    return await getZipCodes(inputValue);
  };

  useEffect(() => {
    getZipCodes();
    if (props.id) {
      getAddressDetail();
    }
  }, []);
  const handleInputChange = (newValue) => {
    console.log("####", newValue);
    setPostCode(newValue);
  };
  return (
    <div>
      <div className="edit_address">
        {props.title && <h1>{props.title}</h1>}
        <form className="add_delivery_address">
          <div className="half">
            <div className="form_control">
              <label>Name</label>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="form_control">
              <label>Mobile Number</label>
              <input
                value={mobile_number}
                onChange={(e) => setMobile(e.target.value)}
                type="number"
                required
              />
            </div>
          </div>
          <div className="form_control">
            <label>Email</label>
            <input
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form_control">
            <label>Address line 1</label>
            <textarea
              value={address_line1}
              onChange={(e) => setAddress1(e.target.value)}
              required
            ></textarea>
          </div>
          <div className="form_control">
            <label>Address line 2</label>
            <textarea
              required
              value={address_line2}
              onChange={(e) => setAddress2(e.target.value)}
            ></textarea>
          </div>
          <div className="half">
            <div className="form_control">
              <label>Post Code</label>
              <AsyncSelect
                required
                value={post_code}
                loadOptions={loadOptions}
                defaultOptions={zipCodes}
                onChange={handleInputChange}
              />
            </div>
            <div className="form_control">
              <label>City</label>
              <input
                value={city}
                onChange={(e) => setCity(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="half">
            <div className="form_control">
              <label>State</label>
              <input
                value={state}
                onChange={(e) => setState(e.target.value)}
                required
              />
            </div>
            <div className="form_control">
              <label>Country</label>
              <select
                value={country}
                onChange={(e) => setAddressType(e.target.value)}
              >
                <option value={"Australia"}>Australia</option>
              </select>
            </div>
          </div>
          <div className="form_control">
            <label>Address Type</label>
            <select
              value={address_type}
              onChange={(e) => setAddressType(e.target.value)}
            >
              <option value="Home">Home</option>
              <option value="Office">Office</option>
            </select>
          </div>
          <div className="form_control">
            <label>Delivery Instructions</label>
            <textarea
              value={delivery_instruction}
              onChange={(e) => setInstruction(e.target.value)}
            ></textarea>
          </div>
          <Checkbox
            value={is_default}
            checked={is_default}
            onChange={() => setDefault((prev) => !prev)}
          >
            Set as default address
          </Checkbox>
          <br />
          <br />
          <br />
          <div className="form_control">
            <button onClick={submitAddress} className="active">
              Save Address
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
