import React from 'react';
import Header from '../../components/Header/header';
import Footer from '../../components/Footer/footer';
import { useState, useEffect } from 'react';
import { get } from 'lodash';
import { axios_get, GET_USER } from '../../utils/service';
import { navigate } from '@reach/router';
import './social.scss';
import { Pagination } from 'antd';
import { configureStore } from '@reduxjs/toolkit';
import InfiniteScroll from 'react-infinite-scroller';

export default function Social() {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState([]);
  const [next, setNext] = useState('');
  const [count, setCount] = useState(1);
  useEffect(() => {
    getUsers();
  }, [search]);
  const getUsers = (isNext) => {
    let url =
      isNext && next
        ? next
        : search
        ? GET_USER + '?search=' + search
        : GET_USER;

    axios_get(url).then((res) => {
      setUsers((prev) =>
        isNext ? [...prev, ...get(res, 'data.data', [])] : get(res, 'data.data')
      );
      setCount(get(res, 'data.count', 0));
      setNext(get(res, 'data.next', ''));
    });
  };

  return (
    <div>
      <Header />
      <div className="social">
        <div className="container">
          <div className="search_cont">
            <div className="search">
              <input
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                type="search"
                placeholder="Search users ..."
              />
              <button type="button">
                <span className="material-icons">search</span>
              </button>
            </div>
          </div>
          <InfiniteScroll
            pageStart={0}
            loadMore={() => next && getUsers(true)}
            hasMore={next ? true : false}
            loader={
              <div className="loader" key={0}>
                Loading ...
              </div>
            }
          >
            <div className="users_listing">
              {users.map((item) => (
                <div
                  onClick={() => navigate('/user/' + item.username)}
                  className="user_card"
                >
                  <div
                    className="user_img_card"
                    style={{
                      backgroundImage: item.profile_pic
                        ? `url(${item.profile_pic})`
                        : "url('https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG.png')",
                    }}
                  ></div>
                  <div className="user_detail">
                    <h2>{item.first_name + ' ' + item.last_name}</h2>
                    {item.username && (
                      <div className="usr_card_line">{'@' + item.username}</div>
                    )}

                    <div className="usr_card_line">
                      <span className="material-icons">rss_feed</span>
                      {item.follower_count || 0} Followers
                    </div>
                    <div className="usr_card_line">
                      <span className="material-icons">restaurant</span>
                      {item.recipe_count || 0} recipes
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </InfiniteScroll>
        </div>
      </div>
      <Footer />
    </div>
  );
}
