import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import {useState} from "react";
import {axios_post, VERIFY_FORGOT_API_URL} from "../../utils/service";

export default function ForgotPasswordVerify(props){

   const [Data, SetData] = useState({token: props.token, password:"", confirm_password:""});

    const onChangeInput = (e)=>{
        const {name, value} = e.target;

        SetData(prevState =>({
            ...prevState,
            [name]:value
        }))
    };

     const OnSubmitPassword = (e) => {
        e.preventDefault();
        const res = axios_post(VERIFY_FORGOT_API_URL, Data, false);
    }

    return <div>
        <Header />
        <form onSubmit={OnSubmitPassword}>
            <label htmlFor="">New Password</label>
            <input type="password" name="password" required onChange={onChangeInput}/>
            <label htmlFor="">Confirm password</label>
            <input type="password" name="confirm_password" required onChange={onChangeInput}/>
            <input type="submit" className="like" value="Change password" />
        </form>
        <Footer />
    </div>
}