import React, { useEffect, useState } from "react";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import {
  axios_get,
  axios_put,
  GET_RECIPE,
  GET_USER,
  LIKE_UNLIKE_RECIPE,
  axios_post,
  FOLLOWER_LIST,
  FOLLOW_TOGGLE,
  FOLLOWING_LIST,
  checkLogin,
  GET_USER_USERNAME,
} from "../../utils/service";
import { Tabs } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import Cookies from "js-cookie";
import "./profile.scss";
import { navigate } from "@reach/router";
import { get } from "lodash";
import Rating from "react-rating";
const { TabPane } = Tabs;

export default function PublicProfile(props) {
  const [posts, setPosts] = useState([]);
  const [user, setUser] = useState({});
  const [followers, setFollowers] = useState([]);
  const [followings, setFollowings] = useState([]);
  const [postCount, setPostCount] = useState("");
  const [followerCount, setFollowerCount] = useState("");
  const [followingCount, setFollowingCount] = useState("");
  const [recipeNext, setRecipeNext] = useState("");
  const [followerNext, setFollowerNext] = useState("");
  const [followingNext, setFollowingNext] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  useEffect(() => {
    getUser();
  }, []);
  const getRecipes = (id) => {
    setRecipeNext("");
    axios_get(recipeNext ? recipeNext : GET_RECIPE + "?created_by=" + id).then(
      (res) => {
        setPosts((prev) => [...prev, ...get(res, "data.data", [])]);
        setPostCount(get(res, "data.count", 0));
        setRecipeNext(get(res, "data.next", ""));
      }
    );
  };
  const getUser = () => {
    axios_get(GET_USER_USERNAME + props.username).then((res) => {
      if (res?.data?.data) {
        setUser(res.data.data);
        getFollowers(res.data.data.id);
        getFollowings(res.data.data.id);
        getRecipes(res.data.data.id);
      }
    });
  };
  const toggleLikeRecipe = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    if (checkLogin("to like this recipe")) return;
    axios_post(LIKE_UNLIKE_RECIPE, { recipe: id }).then((res) => {
      let arr = posts.map((item) => {
        if (item.id === id) {
          item.is_liked = !item.is_liked;
        }
        return item;
      });
      setPosts(arr);
    });
  };
  const getFollowers = (id) => {
    setFollowerNext("");
    axios_get(followerNext ? followerNext : FOLLOWER_LIST + "?id=" + id).then(
      (res) => {
        console.log("followerS", res);
        if (res?.data) {
          setFollowers((prev) => [...prev, ...get(res, "data.data", [])]);
          setFollowerCount(get(res, "data.count", 0));
          setFollowerNext(get(res, "data.next", ""));
        }
      }
    );
  };
  const toggleFollow = (e, id, type) => {
    e.stopPropagation();
    if (checkLogin("to follow")) return;
    let data = {
      follower: id,
    };
    axios_post(FOLLOW_TOGGLE, data).then((res) => {
      console.log("toggle follow", res);
      if (res?.status === 200) {
        if (type === "follower") {
          let arr = [...followers].map((item) => {
            if (id === item.id) {
              return { ...item, is_followed: !item.is_followed };
            } else {
              return item;
            }
          });
          setFollowers(arr);
        }
        if (type === "following") {
          let arr = [...followings].map((item) => {
            if (id === item.id) {
              return { ...item, is_followed: !item.is_followed };
            } else {
              return item;
            }
          });
          setFollowings(arr);
        } else {
          setUser((prev) => ({ ...prev, is_followed: !prev.is_followed }));
        }
      }
    });
  };
  const getFollowings = (id) => {
    setFollowingNext("");
    axios_get(
      followingNext ? followingNext : FOLLOWING_LIST + "?id=" + id
    ).then((res) => {
      setFollowings((prev) => [...prev, ...get(res, "data.data", [])]);
      setFollowingCount(get(res, "data.count", 0));
      setFollowingNext(get(res, "data.next", ""));
    });
  };
  const handleScroll = () => {
    if (activeTab == 1) {
      getRecipes();
    }
    if (activeTab == 2) {
      getFollowers();
    }
    if (activeTab == 3) {
      getFollowings();
    }
  };
  const hasMoreResolver = () => {
    if (activeTab == 1 && recipeNext) return true;
    if (activeTab == 2 && followerNext) return true;
    if (activeTab == 3 && followingNext) return true;
    else return false;
  };
  return (
    <div>
      <InfiniteScroll
        pageStart={0}
        loadMore={handleScroll}
        hasMore={hasMoreResolver()}
        loader={
          <div className="loader" key={0}>
            Loading ...
          </div>
        }
      >
        <Header />
        <div className="min_height">
          <div className="container">
            <div className="public_profile_layout">
              <div className="user_bio">
                <div
                  className="public_profile_pic"
                  style={{ backgroundImage: "url(" + user.profile_pic + ")" }}
                ></div>
                <h1>{user.first_name + " " + user.last_name}</h1>
                <span className="usr_name">@{user.username}</span>
                {Cookies.get("user_id") !== user.id && (
                  <button
                    onClick={(e) => toggleFollow(e, user.id)}
                    className={
                      user.is_followed
                        ? "follow_usr_active unfollow"
                        : "follow_usr_active"
                    }
                  >
                    <span className="material-icons">
                      {user.is_followed ? "done_all" : "rss_feed"}
                    </span>
                    {user.is_followed ? "Unfollow" : "Follow"}
                  </button>
                )}

                <div className="user_short_desc">
                  {user.about}
                  {/* <button className="more">More ...</button> */}
                </div>
                <div className="social_user">
                  {user.twitter && (
                    <a>
                      <img src="/1.svg" />
                    </a>
                  )}
                  {user.youtube && (
                    <a>
                      <img src="/2.svg" />
                    </a>
                  )}
                  {user.instagram && (
                    <a>
                      <img src="/3.svg" />
                    </a>
                  )}
                  {user.facebook && (
                    <a>
                      <img src="/4.svg" />
                    </a>
                  )}
                </div>
              </div>
              <div className="public_user_tabs">
                <Tabs defaultActiveKey="1" onChange={(e) => setActiveTab(e)}>
                  <TabPane tab={`Recipes (${postCount})`} key="1">
                    <div className="recipe_cards">
                      {posts.map((item) => (
                        <a
                          onClick={(e) => {
                            e.preventDefault();

                            navigate("/recipe/" + item.id);
                          }}
                          className="recipe_card"
                        >
                          <div
                            className="recipe_image"
                            style={{
                              backgroundImage:
                                "url(" + item.cover_image.file + ")",
                            }}
                          >
                            <span
                              onClick={(e) => toggleLikeRecipe(e, item.id)}
                              className={
                                item.is_liked
                                  ? "material-icons active"
                                  : "material-icons"
                              }
                            >
                              {item.is_liked ? "favorite" : "favorite_border"}
                            </span>
                          </div>
                          <div className="recipe_description">
                            <div className="recipe_cat">
                              <span>{item?.category?.category}</span>
                              {/* <div className="star_rating">
                              <span className="material-icons">star</span>{" "}
                              {item.rating}
                            </div> */}
                              <div className="star_rating">
                                <Rating
                                  className="rating_system"
                                  emptySymbol={
                                    <span className="material-icons">
                                      star_border
                                    </span>
                                  }
                                  fullSymbol={
                                    <span className="material-icons">star</span>
                                  }
                                  initialRating={item.rating || 0}
                                  readonly
                                />
                              </div>
                            </div>
                            <b>{item.title}</b>
                          </div>
                        </a>
                      ))}
                    </div>
                  </TabPane>
                  <TabPane tab={`Followers (${followerCount})`} key="2">
                    {followers.map((item) => (
                      <div
                        onClick={() => navigate("/user/" + item.username)}
                        className="follow_usr"
                      >
                        <div className="follow_user_info">
                          <div
                            className="follow_usr_image"
                            style={{
                              backgroundImage: "url(" + item.profile_pic + ")",
                            }}
                          ></div>
                          <div>
                            <h5>{item.first_name + " " + item.last_name}</h5>
                            <span>@masterchef</span>
                          </div>
                        </div>
                        <button
                          onClick={(e) => toggleFollow(e, item.id, "follower")}
                          className={
                            item.is_followed
                              ? "follow_usr_active unfollow"
                              : "follow_usr_active"
                          }
                        >
                          <span className="material-icons">
                            {item.is_followed ? "done_all" : "rss_feed"}
                          </span>
                          {item.is_followed ? "Unfollow" : "Follow"}
                        </button>
                      </div>
                    ))}
                  </TabPane>
                  <TabPane tab={`Following (${followingCount})`} key="3">
                    {followings.map((item) => (
                      <div
                        onClick={() => navigate("/user/" + item.username)}
                        className="follow_usr"
                      >
                        <div className="follow_user_info">
                          <div
                            className="follow_usr_image"
                            style={{
                              backgroundImage: "url(" + item.profile_pic + ")",
                            }}
                          ></div>
                          <div>
                            <h5>{item.first_name + " " + item.last_name}</h5>
                            <span>@masterchef</span>
                          </div>
                        </div>
                        <button
                          onClick={(e) => toggleFollow(e, item.id, "following")}
                          className={
                            item.is_followed
                              ? "follow_usr_active unfollow"
                              : "follow_usr_active"
                          }
                        >
                          <span className="material-icons">
                            {item.is_followed ? "done_all" : "rss_feed"}
                          </span>
                          {item.is_followed ? "Unfollow" : "Follow"}
                        </button>
                      </div>
                    ))}
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </InfiniteScroll>
      <Footer />
    </div>
  );
}
